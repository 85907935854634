import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Tabs } from 'antd';

import { displayFullNameOrEmail } from 'utils/userUtils';
import { ClientForm } from './ClientForm';
import { ClientData } from './types';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  onClose: () => void;
  client?: ClientData;
};

export const ClientDrawer = ({ visible, onClose, client }: Props) => {
  const { t } = useTranslation();
  return (
    <Drawer
      title={
        client
          ? displayFullNameOrEmail(client)
          : t('clientsManagment.newClient')
      }
      width="max(35vw, 480px)"
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane key="1" tab={t('clientsManagment.details')}>
          <ClientForm client={client} closeDrawer={onClose} />
        </TabPane>
        <TabPane key="2" tab={t('clientsManagment.functionalities')}>
          Functionalities
        </TabPane>
        <TabPane key="3" tab={t('clientsManagment.invoices')}>
          Invoices
        </TabPane>
        <TabPane key="4" tab={t('clientsManagment.subscriptions')}>
          Subscriptions
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
