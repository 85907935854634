import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Descriptions, Typography } from 'antd';

// import { useGetStripeCustomerPaymentMethodsQuery } from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AccountLayout } from './Layout';
import { PaymentDrawer, PaymentDrawerProps } from '../payment/PaymentDrawer';

const { Title } = Typography;

export const AccountPayment = () => {
  const { t } = useTranslation();
  const [drawerProps, setDrawerProps] = useState<
    Pick<PaymentDrawerProps, 'visible'>
  >({
    visible: false,
  });
  // const { stripe_customer_id: stripeCustomerId } = useLoggedClient();
  // const { data: dataCard } = useGetStripeCustomerPaymentMethodsQuery({
  //   variables: {
  //     customer: stripeCustomerId || '',
  //     type: 'card',
  //   },
  // });
  const dataIban = null;
  const dataMandate = null;

  return (
    <>
      {/* <PaymentDrawer
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <AccountLayout>
        {dataCard ? (
          <>
            <Title level={4}>{t('account.bankCard')}</Title>
            {dataCard.get_stripe_customer_payment_methods.map(
              (paymentMethod: any) => (
                <Descriptions layout="vertical">
                  <Descriptions.Item label={t('account.cardNumber')}>
                    **** **** **** {paymentMethod.card.last4}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('account.expire')}>
                    {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <Button
                      type="primary"
                      onClick={() => setDrawerProps({ visible: true })}
                    >
                      {t('account.changeCard')}
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              ),
            )}
          </>
        ) : null}
        {dataIban ? <Title level={4}>{t('account.iban')}</Title> : null}
        {dataMandate ? <Title level={4}>{t('account.mandate')}</Title> : null}
      </AccountLayout> */}
    </>
  );
};
