import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select } from 'antd';

const { Option } = Select;

export interface Props {
  visible: boolean;
  availableLanguage: string[];
  onClose: () => void;
  onSaveDuplicate: (lng: string) => void;
}

export const TemplateModalDuplicate = ({
  visible,
  availableLanguage,
  onClose,
  onSaveDuplicate,
}: Props) => {
  const { t } = useTranslation();
  const [slectedLng, setSelectedLng] = useState(availableLanguage[0]);

  return (
    <Modal
      title={t('templates.duplicateTemplate')}
      visible={visible}
      onCancel={onClose}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onOk={() => onSaveDuplicate(slectedLng)}
    >
      <Select
        onChange={value => setSelectedLng(value.toString())}
        value={availableLanguage[0]}
        style={{ width: 120 }}
      >
        {availableLanguage.map(lng => (
          <Option key={lng} value={lng}>
            {t(`languages.${lng}` as any)}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};
