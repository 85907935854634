import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { authService } from './authService';

export const LogoutButton = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('auth.logout')}>
      <WhiteButton
        shape="circle"
        icon={<PoweroffOutlined />}
        onClick={() => authService.logout()}
        type="link"
      />
    </Tooltip>
  );
};

const WhiteButton = styled(Button)`
  color: white !important;
`;
