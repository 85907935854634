import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Drawer, DrawerProps, Form, Input, InputNumber, Row } from 'antd';
import styled from 'styled-components';

import {
  useInsertQuizzQuestionMutation,
  useUpdateQuizzQuestionMutation,
  AssetFragment,
  QuizzFragment,
  QuizzQuestionsFragment,
  QuizzQuestionsFragmentDoc,
  QuizzQuestionAnswersFragment,
} from '@shared/api';

import { AppForm, AssetInput, SubmitButton } from 'components';
import { QuizzAnswer } from './QuizzAnswer';

const { TextArea } = Input;
export interface QuizzDrawerProps extends DrawerProps {
  quizz?: QuizzFragment;
  quizzQuestions?: QuizzQuestionsFragment;
  onClose: () => void;
}

export const QuizzDrawer = ({
  onClose,
  quizz,
  quizzQuestions,
  ...rest
}: QuizzDrawerProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [form] = Form.useForm();
  const [insertQuizzQuestionMutation] = useInsertQuizzQuestionMutation({
    onCompleted: () => onClose(),
    update: (cache, { data }) => {
      if (quizz && data && data.insert_quizz_questions_one) {
        cache.modify({
          id: cache.identify(quizz),
          fields: {
            quizz_questions: refs => {
              const newQuizzQuestionRef = cache.writeFragment({
                data: data.insert_quizz_questions_one,
                fragment: QuizzQuestionsFragmentDoc,
                fragmentName: 'QuizzQuestions',
              });
              return [...refs, newQuizzQuestionRef];
            },
          },
        });
      }
    },
  });
  const [updateQuizzQuestionMutation] = useUpdateQuizzQuestionMutation({
    onCompleted: () => onClose(),
  });

  const onFinish = async ({
    asset,
    ...values
  }: QuizzQuestionsFragment & { asset: AssetFragment | undefined }) => {
    setLoading(true);
    if (quizzQuestions) {
      const {
        quizz_questions_answers: quizzQuestionsAnswers,
        ...updates
      } = values;
      updateQuizzQuestionMutation({
        variables: {
          id: quizzQuestions.id,
          updates: {
            ...updates,
            asset_id: asset?.id,
            total_points: totalPoints,
          },
          quizzQuestionsAnswers: quizzQuestionsAnswers.map(
            (answer: QuizzQuestionAnswersFragment) => ({
              ...answer,
              quizz_question_id: quizzQuestions.id,
              __typename: undefined,
            }),
          ),
        },
      });
    } else {
      insertQuizzQuestionMutation({
        variables: {
          object: {
            ...values,
            asset_id: asset?.id,
            quizz_id: quizz?.id,
            total_points: totalPoints,
            quizz_questions_answers: { data: values.quizz_questions_answers },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (rest.visible) {
      form.resetFields();
    }
  }, [quizz]);

  useEffect(() => {
    if (quizzQuestions) {
      setTotalPoints(quizzQuestions.total_points);
    }
  }, [quizzQuestions]);

  return (
    <Drawer
      width={600}
      title={t('quizz.quizzTitle')}
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={{ ...quizzQuestions }}
        preserve={false}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        onValuesChange={() => {
          setTotalPoints(
            form.getFieldsValue().quizz_questions_answers
              ? form
                  .getFieldsValue()
                  .quizz_questions_answers.reduce(
                    (
                      acc: number,
                      { points }: QuizzQuestionAnswersFragment,
                    ): number => (points > 0 ? acc + points : acc),
                    0,
                  )
              : 0,
          );
        }}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="question"
            label={t('quizz.question')}
            rules={[{ required: true }]}
          >
            <TextArea
              rows={2}
              showCount
              maxLength={120}
              style={{ color: 'white' }}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="time"
                  label={t('quizz.form.timeDelay')}
                  rules={[{ required: true }]}
                  initialValue={30}
                >
                  <InputNumber style={{ width: '100%' }} min={10} max={120} />
                </Form.Item>
              </Col>
              <Col span={12} />
            </Row>
          </Form.Item>
          <Form.Item name="asset" label={t('quizz.form.asset')}>
            <AssetInput withCrop cropAspect={16 / 9} />
          </Form.Item>
          <QuizzAnswer totalPoints={totalPoints} />
        </FormContainer>
        <SubmitButton
          size="large"
          loading={loading}
          style={{ marginTop: '0.5rem' }}
        />
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;
