import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import {
  SurveyFragment,
  SurveyQuestionsFragment,
  useDeleteSurveyQuestionMutation,
} from '@shared/api';

import { Loader, DeleteAction } from 'components';
import { SurveyDrawer, SurveyDrawerProps } from './SurveyDrawer';

interface SurveyProps {
  survey: SurveyFragment;
  loading: boolean;
}

const Survey = ({ survey, loading = false }: SurveyProps) => {
  const { t } = useTranslation();
  const [deleteSurveyQuestionMutation] = useDeleteSurveyQuestionMutation({
    update: (cache, { data: deleteData }) => {
      if (deleteData && deleteData.delete_surveys_questions_by_pk) {
        cache.modify({
          id: cache.identify(survey),
          fields: {
            surveys_questions: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  deleteData.delete_surveys_questions_by_pk?.id !==
                  readField('id', ref),
              ),
          },
        });
      }
    },
  });
  const [drawerProps, setDrawerProps] = useState<
    Pick<SurveyDrawerProps, 'visible' | 'survey' | 'surveyQuestions'>
  >({
    visible: false,
  });

  const columns: ColumnsType<SurveyQuestionsFragment> = [
    {
      title: t('table.questions'),
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: surveyQuestions => (
        <DeleteAction
          onDelete={() => {
            deleteSurveyQuestionMutation({
              variables: { id: surveyQuestions.id },
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <SurveyDrawer
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <Row justify="end" gutter={16}>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            onClick={() => setDrawerProps({ visible: true, survey })}
          >
            {loading ? <Loader /> : t('common.add')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            dataSource={survey?.surveys_questions || []}
            loading={loading}
            rowKey="id"
            onRow={(surveyQuestions: SurveyQuestionsFragment) => ({
              onClick: () =>
                setDrawerProps({ visible: true, survey, surveyQuestions }),
            })}
            rowClassName="cursor-pointer"
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export { Survey };
