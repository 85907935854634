import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row, Tabs } from 'antd';
import { Column } from '@ant-design/charts';
import styled from 'styled-components';

import { DateRangePicker } from 'components';
import { useStatistic } from 'context/StatisticContext';
import {
  getLastWeekDays,
  getLastMonthDays,
  getLastYearDays,
  getCurrentDayHours,
  getRangeMergedList,
  getRangeDays,
  getRankedList,
} from 'utils/statisticUtils';

const { TabPane } = Tabs;

export const ParticipationsCard = () => {
  const { t } = useTranslation();
  const {
    participations,
    participationsLast24hours,
    gains,
    gainsLast24hours,
  } = useStatistic();

  const [currentTab, setCurrentTab] = useState<'participations' | 'gains'>(
    'participations',
  );

  const [rangeDays, setRangeDays] = useState<{
    type: 'today' | 'week' | 'month' | 'year' | 'range';
    days: string[];
    data: any;
  }>({
    type: 'month',
    days: getLastMonthDays(),
    data: [],
  });

  const [config, setConfig] = useState<any>({
    data: [],
    xField: 'date',
    yField: 'total_count',
    color: '#D73716',
    slider: {
      start: 0,
      end: 1,
    },
  });

  const getData = () =>
    currentTab === 'participations' ? participations : gains;

  const getDataLast24hours = () =>
    currentTab === 'participations'
      ? participationsLast24hours
      : gainsLast24hours;

  useEffect(() => {
    setRangeDays({
      type: 'month',
      days: getLastMonthDays(),
      data: participations,
    });
  }, [participations]);

  useEffect(() => {
    setRangeDays({
      ...rangeDays,
      data: currentTab === 'participations' ? participations : gains,
    });
  }, [currentTab]);

  useEffect(() => {
    setConfig({
      ...config,
      data: getRangeMergedList(rangeDays.days, rangeDays.data),
    });
  }, [participations, gains, rangeDays, currentTab]);

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Container>
        <Tabs
          onChange={(activeKey: string) => {
            setCurrentTab(activeKey as 'participations' | 'gains');
          }}
          tabBarExtraContent={
            <ExtraWrap>
              <Extra>
                <Button
                  type="link"
                  disabled={rangeDays.type === 'today'}
                  onClick={() =>
                    setRangeDays({
                      type: 'today',
                      days: getCurrentDayHours(),
                      data: getDataLast24hours(),
                    })
                  }
                >
                  {t('dashboard.last24Hours')}
                </Button>
                <Button
                  type="link"
                  disabled={rangeDays.type === 'week'}
                  onClick={() =>
                    setRangeDays({
                      type: 'week',
                      days: getLastWeekDays(),
                      data: getData(),
                    })
                  }
                >
                  {t('dashboard.allWeek')}
                </Button>
                <Button
                  type="link"
                  disabled={rangeDays.type === 'month'}
                  onClick={() =>
                    setRangeDays({
                      type: 'month',
                      days: getLastMonthDays(),
                      data: getData(),
                    })
                  }
                >
                  {t('dashboard.allMonth')}
                </Button>
                <Button
                  type="link"
                  disabled={rangeDays.type === 'year'}
                  onClick={() =>
                    setRangeDays({
                      type: 'year',
                      days: getLastYearDays(),
                      data: getData(),
                    })
                  }
                >
                  {t('dashboard.allYear')}
                </Button>
              </Extra>
              <DateRangePicker
                onChange={time =>
                  time &&
                  time.length > 0 &&
                  setRangeDays({
                    type: 'range',
                    days: getRangeDays(time[0], time[1]),
                    data: getData(),
                  })
                }
                style={{ width: 256 }}
              />
            </ExtraWrap>
          }
          size="large"
          tabBarStyle={{ marginBottom: 24 }}
        >
          <TabPane tab={t('dashboard.participations')} key="participations">
            <Row>
              <Col xl={16} lg={12} md={12} sm={24} xs={24}>
                <div>
                  <Column {...config} />
                </div>
              </Col>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Rank>
                  <h4>{t('dashboard.participationsRanking')}</h4>
                  <RankingList>
                    {participations
                      ? getRankedList(participations).map((item: any, i) => (
                          <RankingListItem key={item.name}>
                            <RankingItemNumber>{i + 1}</RankingItemNumber>
                            <RankingItemTitle title={item.name}>
                              {item.name}
                            </RankingItemTitle>
                            <span>{item.total_count}</span>
                          </RankingListItem>
                        ))
                      : null}
                  </RankingList>
                </Rank>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('dashboard.gains')} key="gains">
            <Row>
              <Col xl={16} lg={12} md={12} sm={24} xs={24}>
                <div>
                  <Column {...config} />
                </div>
              </Col>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Rank>
                  <h4>{t('dashboard.gainsRanking')}</h4>
                  <RankingList>
                    {gains
                      ? getRankedList(gains).map((item: any, i) => (
                          <RankingListItem key={item.name}>
                            <RankingItemNumber>{i + 1}</RankingItemNumber>
                            <RankingItemTitle title={item.name}>
                              {item.name}
                            </RankingItemTitle>
                            <RankingItemCount>
                              {item.total_count}
                            </RankingItemCount>
                          </RankingListItem>
                        ))
                      : null}
                  </RankingList>
                </Rank>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Container>
    </Card>
  );
};

const Container = styled.div`
  padding: 24px;
`;

const ExtraWrap = styled.div``;

const Extra = styled.div`
  display: inline-block;
  margin-right: 24px;
  a {
    margin-left: 24px;
    color: black;
    &:hover {
      color: gray;
    }
    &.currentDate {
      color: gray;
    }
  }
`;

const Rank = styled.div`
  padding: 0 32px 32px 72px;
`;

const RankingList = styled.ul`
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
`;

const RankingListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 16px;
  zoom: 1;
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
`;

const RankingItemNumber = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background-color: #314659;
  border-radius: 20px;
  color: white;
`;

const RankingItemCount = styled.span`
  color: black;
  font-size: 14px;
  line-height: 22px;
`;

const RankingItemTitle = styled.span`
  flex: 1;
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  font-size: 14px;
  line-height: 22px;
`;
