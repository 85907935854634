import { TFunction } from 'react-i18next';
import { AssetFragment, Assets, TrackerFragment } from '@shared/api';
import { addSuffix } from './fileUtils';

export type LibraryContentType = 'asset' | 'tracker';
export type LibraryContent = AssetFragment | TrackerFragment;

export const getAssetUrl = (asset: Assets, qrcodeInside = false) =>
  `${process.env.REACT_APP_STATIC_FILES_ENDPOINT}${
    asset.client_id ? `/${asset.client_id}` : ''
  }${qrcodeInside ? addSuffix(asset.path, '_qrcode_inside') : asset.path}`;

export const displayAssetType = (asset: Assets, t: TFunction<'translation'>) =>
  t(`ar.assetsTypes.${asset.type}` as any); // eslint-disable-line @typescript-eslint/no-explicit-any

export const isTracker = (
  content: LibraryContent,
): content is TrackerFragment => 'image' in content;
