import React, { useLayoutEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import debounce from 'lodash/debounce';

import { AppPage } from './AppPage';

import './AppPageTable.less';

export type ActionProps<RecordType> = Array<{
  text: string;
  onClick: (records: RecordType[]) => void;
}>;

interface Props<RecordType> extends TableProps<RecordType> {
  pageTitle: string;
  onAdd: () => void;
  actions?: ActionProps<RecordType>;
}

export const AppPageTable = <RecordType extends Record<string, unknown>>({
  pageTitle,
  onAdd,
  actions = [],
  ...props
}: Props<RecordType>) => {
  const [pageSize, setPageSize] = useState(10);
  const [selectedRows, setSelectedRows] = useState<[React.Key[], RecordType[]]>(
    [[], []],
  );

  // We resize number of items per page in the table to fit client's screen
  const { showHeader = true, dataSource } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!tableRef.current?.parentElement) return;
    const { paddingTop, paddingBottom } = getComputedStyle(
      tableRef.current.parentElement,
    );

    const rowElement = tableRef.current
      .getElementsByClassName('ant-table-row')
      .item(0);
    if (!rowElement) return;
    const paginationElement = tableRef.current
      .getElementsByClassName('ant-table-pagination')
      .item(0);
    const rowHeight = rowElement.clientHeight;
    const paginationHeight = paginationElement
      ? paginationElement.clientHeight +
        parseFloat(getComputedStyle(paginationElement).marginTop)
      : 0;

    const setNumberRows = () => {
      if (!tableRef.current?.parentElement) return;
      const availableHeight =
        tableRef.current.parentElement.offsetHeight -
        parseFloat(paddingTop) -
        parseFloat(paddingBottom);
      const numberRowsAvailable = Math.floor(
        (availableHeight - paginationHeight) / rowHeight,
      );
      setPageSize(showHeader ? numberRowsAvailable - 1 : numberRowsAvailable);
    };
    setNumberRows();

    const debounceSetNumberRows = debounce(setNumberRows, 300);
    window.addEventListener('resize', debounceSetNumberRows);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', debounceSetNumberRows);
  }, [showHeader, dataSource]); // dataSource needed cause rowHeight is not defined when data is loading

  return (
    <AppPage
      title={pageTitle}
      numberRowsSelected={selectedRows[0].length}
      onAdd={onAdd}
      actions={actions.map(action => ({
        ...action,
        onClick: () => action.onClick(selectedRows[1]),
      }))}
    >
      <div ref={tableRef} className="full-height-table">
        <Table
          rowSelection={{
            selectedRowKeys: selectedRows[0],
            onChange: (keys, rows) => setSelectedRows([keys, rows]),
          }}
          scroll={{ x: true }}
          size="middle"
          pagination={{ size: 'small', pageSize, showSizeChanger: false }}
          {...props}
        />
      </div>
    </AppPage>
  );
};
