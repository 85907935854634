import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Layout,
  Tooltip,
  Typography,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import { useUpdateAssetMutation, useUpdateTrackerMutation } from '@shared/api';

import { displayAssetType, isTracker, LibraryContent } from 'utils/assetUtils';
import { AppForm } from '../AppForm';
import { Loader } from '../Loader';
import { TitleValue } from '../TitleValue';
import './LibrarySider.less';

const { Panel } = Collapse;
const { Sider } = Layout;
const { Text } = Typography;

interface Props {
  content?: LibraryContent;
  onSelect: (content: LibraryContent) => void;
}

export const LibrarySider = ({ content, onSelect }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [debouncing, setDebouncing] = useState(false);

  const [updateAsset, { loading: assetLoading }] = useUpdateAssetMutation();
  const [
    updateTracker,
    { loading: trackerLoading },
  ] = useUpdateTrackerMutation();

  const loading = debouncing || assetLoading || trackerLoading;

  useEffect(() => {
    form.resetFields();
  }, [content]);

  const onContentChange = useMemo(
    () =>
      debounce((fields: Required<FormProps>['fields']) => {
        if (!content) return;
        const updates = fields.reduce((acc, field) => {
          acc[field.name as string] = field.value;
          return acc;
        }, {} as any);
        const variables = {
          id: content.id,
          updates,
        };

        if (isTracker(content))
          updateTracker({ variables })
            .then(() => setDebouncing(false))
            .catch(() => setDebouncing(false));
        else
          updateAsset({ variables })
            .then(() => setDebouncing(false))
            .catch(() => setDebouncing(false));
      }, 1000),
    [content],
  );

  const initialValues =
    content && !isTracker(content)
      ? {
          ...content,
          scaleX: content.scaleX || 1,
          scaleY: content.scaleY || 1,
          scaleZ: content.scaleZ || 1,
        }
      : content;

  return (
    <StyledSider width={300}>
      <Container>
        {content ? (
          <>
            <AppForm
              form={form}
              onFieldsChange={fields => {
                setDebouncing(true);
                onContentChange(fields);
              }}
              initialValues={initialValues}
            >
              <Collapse defaultActiveKey={['1']}>
                <Panel header={t('library.sider.general')} key="1">
                  <Form.Item
                    name="name"
                    label={t('common.name')}
                    initialValue={content.name}
                    rules={
                      isTracker(content) ? undefined : [{ required: true }]
                    }
                  >
                    <Input />
                  </Form.Item>

                  {isTracker(content) && (
                    <TitleValue
                      title={t('ar.assetsTypes.image')}
                      value={content.image.name}
                      last
                    />
                  )}
                </Panel>
                <Panel header={t('library.sider.meta')} key="2">
                  <TitleValue
                    title={t('ar.type')}
                    value={
                      isTracker(content)
                        ? t('ar.tracker')
                        : displayAssetType(content, t)
                    }
                    last
                  />
                </Panel>
                {(isTracker(content) ||
                  ['object3D', 'portal'].includes(content.type)) && (
                  <Panel header={t('library.sider.advanced')} key="3">
                    {isTracker(content) ? (
                      <>
                        <Form.Item
                          name="width"
                          label={t('ar.form.realWidth')}
                          rules={[{ required: true }]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                          name="height"
                          label={t('ar.form.realHeight')}
                          rules={[{ required: true }]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Text>
                          {t('ar.form.scales')}{' '}
                          <Tooltip title={t('ar.form.scalesHelp')}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                          {' :'}
                        </Text>

                        <Form.Item
                          name="scaleX"
                          label={t('ar.form.scaleX')}
                          rules={[{ required: true }]}
                          style={{ marginTop: '0.5rem' }}
                        >
                          <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                          name="scaleY"
                          label={t('ar.form.scaleY')}
                          rules={[{ required: true }]}
                        >
                          <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                          name="scaleZ"
                          label={t('ar.form.scaleZ')}
                          rules={[{ required: true }]}
                        >
                          <InputNumber min={0} />
                        </Form.Item>
                      </>
                    )}
                  </Panel>
                )}
              </Collapse>
            </AppForm>
            <SelectButton
              type="primary"
              icon={loading ? <Loader fontSize={14} /> : <CheckOutlined />}
              disabled={loading}
              onClick={() => onSelect(content)}
            >
              {t('common.select')}
            </SelectButton>
          </>
        ) : (
          <NoAssetText>{t('library.sider.noPhotoSelected')}</NoAssetText>
        )}
      </Container>
    </StyledSider>
  );
};

const StyledSider = styled(Sider)`
  box-shadow: 0 -30px white, -3px 0 20px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 -30px white, -3px 0 20px rgba(0, 0, 0, 0.15);
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
`;

const SelectButton = styled(Button)`
  align-self: flex-end;
  margin: 1rem;

  span {
    margin-left: 0.5rem;
  }
`;

const NoAssetText = styled(Text)`
  margin-top: 2rem;
  text-align: center;
`;
