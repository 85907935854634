import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

const GainWinners = () => {
  const columns: ColumnsType<{ name: string; status: boolean; date: Date }> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  return <Table size="small" columns={columns} dataSource={[]} />;
};

export { GainWinners };
