import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import {
  PredictionFragment,
  PredictionEventFragment,
  useDeletePredictionEventMutation,
} from '@shared/api';

import { Loader, DeleteAction } from 'components';
import { PredictionDrawer, PredictionDrawerProps } from './PredictionDrawer';

interface PredictionProps {
  prediction?: PredictionFragment;
  loading: boolean;
}

const Prediction = ({ prediction, loading = false }: PredictionProps) => {
  const { t } = useTranslation();
  const [deletePredictionEventMutation] = useDeletePredictionEventMutation({
    update: (cache, { data: deleteData }) => {
      if (
        prediction &&
        deleteData &&
        deleteData.delete_predictions_events_by_pk
      ) {
        cache.modify({
          id: cache.identify(prediction),
          fields: {
            predictions_events: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  deleteData.delete_predictions_events_by_pk?.id !==
                  readField('id', ref),
              ),
          },
        });
      }
    },
  });
  const [drawerProps, setDrawerProps] = useState<
    Pick<PredictionDrawerProps, 'visible' | 'prediction' | 'predictionEvents'>
  >({
    visible: false,
  });

  const columns: ColumnsType<PredictionEventFragment> = [
    {
      title: t('table.selections'),
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: predictionEvents => (
        <DeleteAction
          onDelete={() => {
            deletePredictionEventMutation({
              variables: { id: predictionEvents.id },
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <PredictionDrawer
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <Row justify="end" gutter={16}>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            onClick={() => setDrawerProps({ visible: true, prediction })}
          >
            {loading ? <Loader /> : t('common.add')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            dataSource={prediction?.predictions_events || []}
            loading={loading}
            onRow={(predictionEvents: PredictionEventFragment) => ({
              onClick: () =>
                setDrawerProps({
                  visible: true,
                  prediction,
                  predictionEvents,
                }),
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export { Prediction };
