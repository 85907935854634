import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Row, Typography } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import styled from 'styled-components';

import {
  ArTrackers,
  Assets,
  AssetsTypesEnum,
  TrackerFragment,
} from '@shared/api';

import { filesApi } from 'api/filesApi';
import { isTracker, LibraryContentType } from 'utils/assetUtils';
import { removeExtension } from 'utils/fileUtils';
import { LibraryButton } from '../library';
import { Upload } from '../upload';
import { AssetDisplay } from './AssetDisplay';

interface Props {
  type?: LibraryContentType;
  assetType?: AssetsTypesEnum;
  value?: Assets | ArTrackers;
  withCrop?: boolean;
  cropAspect?: number;
  onChange?: (
    file: Assets | ArTrackers | Pick<ArTrackers, 'image'> | undefined,
  ) => void;
}

export const AssetInput = ({
  type = 'asset',
  assetType = AssetsTypesEnum.Image,
  withCrop = false,
  cropAspect = 1 / 1,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState<string>();
  const [oldState, setOldState] = useState<{
    fileUrl?: string;
    file: Assets | ArTrackers;
  }>();
  const [assetLoading, setAssetLoading] = useState(false);
  const [dowloadLoading, setDowloadLoading] = useState(false);

  useEffect(() => {
    if (!value) setFileUrl(undefined);
  }, [value]);

  return (
    <>
      {value && (!('qrcode_inside' in value) || value.image) ? (
        <AssetDisplay
          loading={assetLoading}
          fileUrl={fileUrl}
          file={value}
          onChange={onChange}
          onUpdateClick={() => {
            setOldState({ fileUrl, file: value });
            if (onChange) onChange(undefined);
          }}
        />
      ) : (
        <Row>
          <Col span="12">
            <LibraryButton
              type={type}
              assetType={assetType}
              onSelect={content => {
                if (onChange) {
                  if (type === 'tracker' && !isTracker(content))
                    onChange({ ...value, image: content });
                  else onChange(content);
                }
              }}
            />
          </Col>
          <Col span="12">
            <Upload
              alone={false}
              type={assetType}
              qrcodeInside={value?.qrcode_inside}
              withCrop={withCrop}
              cropAspect={cropAspect}
              onChange={state => {
                if (state.fileUrl && !value?.qrcode_inside) setFileUrl(fileUrl);
                if (state.asset && onChange) {
                  if (type === 'asset' && onChange) onChange(state.asset);
                  else onChange({ ...value, image: state.asset });
                }
              }}
            />
          </Col>
          {oldState && (
            <BackButton>
              <Button
                type="text"
                icon={<RollbackOutlined />}
                onClick={() => {
                  setFileUrl(oldState.fileUrl);
                  if (onChange) onChange(oldState.file);
                }}
                size="small"
                className="white-text"
              />
            </BackButton>
          )}
        </Row>
      )}
      {type === 'tracker' && (
        <Row
          align="middle"
          gutter={8}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <Col>
            <Checkbox
              checked={value && (value as TrackerFragment).qrcode_inside}
              onChange={ev => {
                if (
                  ev.target.checked &&
                  value &&
                  'image' in value &&
                  value.image
                ) {
                  setAssetLoading(true);
                  filesApi
                    .insertQRCodeInsideTracker(value.image.id)
                    .finally(() => setAssetLoading(false));
                }
                if (onChange)
                  onChange({
                    ...(value as TrackerFragment),
                    qrcode_inside: ev.target.checked,
                  });
              }}
            />
          </Col>
          <Col flex="1">
            <Typography.Text>{t('ar.qrcodeInside')}</Typography.Text>
          </Col>
          {value && 'image' in value && value.image && (
            <Col style={{ alignSelf: 'flex-end' }}>
              <Button
                type="link"
                onClick={() => {
                  setDowloadLoading(true);
                  QRCode.toDataURL(value.image.id, {
                    scale: 16,
                  }).then(qrCode => {
                    saveAs(
                      qrCode,
                      `${removeExtension(value.image.name)}_qrcode.png`,
                    );
                    setDowloadLoading(false);
                  });
                }}
                loading={dowloadLoading}
                style={{ paddingRight: 0 }}
              >
                {t('ar.downloadQrcode')}
              </Button>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

const BackButton = styled.div`
  position: absolute;
  top: -24px;
  right: -5px;
`;
