import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';

import { supportedLngs } from 'i18n/config';

const { Option } = Select;

export const LangSwitcher = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const handleChange = (lng: string) => {
    const path = history.location.pathname.split('/');
    path[1] = lng;
    history.push(path.join('/'));
  };

  return (
    <Row align="middle" gutter={10}>
      <Col>{t('language')}</Col>
      <Col>
        <Select value={i18n.language} onChange={handleChange}>
          {supportedLngs
            .sort((a, b) => a.localeCompare(b))
            .map(lng => (
              <Option key={lng} value={lng}>
                <div className="demo-option-label-item">
                  {t(`languages.${lng}` as any)}{' '}
                </div>
              </Option>
            ))}
        </Select>
      </Col>
    </Row>
  );
};
