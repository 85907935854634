import { Drawer, DrawerProps, Form, Input } from 'antd';
import { AppForm, AssetInput, SubmitButton } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  AssetFragment,
  SurveyFragment,
  SurveyQuestionAnswersFragment,
  SurveyQuestionsFragment,
  SurveyQuestionsFragmentDoc,
  useInsertSurveyQuestionMutation,
  useUpdateSurveyQuestionMutation,
} from '@shared/api';

import { SurveyAnswer } from './SurveyAnswer';

const { TextArea } = Input;
export interface SurveyDrawerProps extends DrawerProps {
  survey?: SurveyFragment;
  surveyQuestions?: SurveyQuestionsFragment;
  onClose: () => void;
}

export const SurveyDrawer = ({
  onClose,
  survey,
  surveyQuestions,
  ...rest
}: SurveyDrawerProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [insertSurveyQuestionMutation] = useInsertSurveyQuestionMutation({
    onCompleted: () => onClose(),
    update: (cache, { data }) => {
      if (survey && data && data.insert_surveys_questions_one) {
        cache.modify({
          id: cache.identify(survey),
          fields: {
            surveys_questions: refs => {
              const newSurveyQuestionRef = cache.writeFragment({
                data: data.insert_surveys_questions_one,
                fragment: SurveyQuestionsFragmentDoc,
                fragmentName: 'SurveyQuestions',
              });
              return [...refs, newSurveyQuestionRef];
            },
          },
        });
      }
    },
  });
  const [updateSurveyQuestionMutation] = useUpdateSurveyQuestionMutation({
    onCompleted: () => onClose(),
  });

  const onFinish = async ({
    asset,
    ...values
  }: SurveyQuestionsFragment & { asset: AssetFragment | undefined }) => {
    setLoading(true);
    if (surveyQuestions) {
      const {
        surveys_questions_answers: surveysQuestionsAnswers,
        ...updates
      } = values;
      updateSurveyQuestionMutation({
        variables: {
          id: surveyQuestions.id,
          updates: {
            ...updates,
            asset_id: asset?.id,
          },
          surveysQuestionsAnswers: surveysQuestionsAnswers.map(
            (answer: SurveyQuestionAnswersFragment) => ({
              ...answer,
              survey_question_id: surveyQuestions.id,
              __typename: undefined,
            }),
          ),
        },
      });
    } else {
      insertSurveyQuestionMutation({
        variables: {
          object: {
            ...values,
            asset_id: asset?.id,
            survey_id: survey?.id,
            surveys_questions_answers: {
              data: values.surveys_questions_answers,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (rest.visible) {
      form.resetFields();
    }
  }, [survey]);

  return (
    <Drawer
      width={600}
      title={t('survey.surveyTitle')}
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={{ ...surveyQuestions }}
        preserve={false}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="question"
            label={t('survey.question')}
            rules={[{ required: true }]}
          >
            <TextArea
              rows={2}
              showCount
              maxLength={120}
              style={{ color: 'white' }}
            />
          </Form.Item>
          <Form.Item name="asset" label={t('survey.form.asset')}>
            <AssetInput withCrop cropAspect={16 / 9} />
          </Form.Item>
          <SurveyAnswer />
        </FormContainer>
        <SubmitButton
          size="large"
          loading={loading}
          style={{ marginTop: '0.5rem' }}
        />
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;
