import React from 'react';
import { TinyArea } from '@ant-design/charts';

interface MiniAreaProps {
  color: string;
  data: any;
}

export const MiniArea = ({ color, data }: MiniAreaProps) => {
  const config = {
    height: 60,
    autoFit: true,
    data,
    xField: 'date',
    yField: 'total_count',
    smooth: true,
    areaStyle: {
      fill: color,
      fillOpacity: 0.5,
      stroke: 'white',
      lineWidth: 1,
      cursor: 'pointer',
    },
    line: {
      color,
    },
  };
  return <TinyArea {...config} />;
};
