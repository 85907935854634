import {
  addDays,
  addHours,
  format,
  startOfWeek,
  startOfMonth,
  startOfYear,
  eachDayOfInterval,
} from 'date-fns';

export const getCurrentDayHours = () => {
  const firstDOW = new Date();

  return Array.from(Array(24)).map((e, i) =>
    format(addHours(firstDOW, i), 'HH'),
  );
};

export const getLastWeekDays = () => {
  const firstDOW = startOfWeek(new Date());

  return Array.from(Array(7)).map((e, i) =>
    format(addDays(firstDOW, i), 'yyyy-MM-dd'),
  );
};

export const getLastMonthDays = () => {
  const firstDOW = startOfMonth(new Date());

  return Array.from(Array(31)).map((e, i) =>
    format(addDays(firstDOW, i), 'yyyy-MM-dd'),
  );
};

export const getLastYearDays = () => {
  const firstDOW = startOfYear(new Date());

  return Array.from(Array(365)).map((e, i) =>
    format(addDays(firstDOW, i), 'yyyy-MM-dd'),
  );
};

export const getRangeDays = (fromDate: any, toDate: any) => {
  const rangeDate = eachDayOfInterval({ start: fromDate, end: toDate });
  return rangeDate.map((e, i) => format(e, 'yyyy-MM-dd'));
};

export const getRangeMergedList = (rangeDays: any, datas: any = []) =>
  rangeDays.map((day: any) => {
    const existingDate = datas.filter(
      (data: any) => data.date.toString() === day,
    );
    if (existingDate.length > 0) {
      return existingDate.reduce(
        (result: any, { date, total_count: totalCount }: any) => ({
          date: date.toString(),
          total_count:
            (result.total_count ? result.total_count : 0) + totalCount,
        }),
        {},
      );
    }
    return { date: day, total_count: 0 };
  });

export const getRankedList = (datas: any[] = []) =>
  Object.values(
    datas.reduce((result, val) => {
      // eslint-disable-next-line no-param-reassign
      result[val.game_id] = {
        ...val,
        total_count:
          (result[val.game_id] ? result[val.game_id].total_count : 0) +
          val.total_count,
      };
      return result;
    }, {}),
  ).sort((a: any, b: any) => (a.total_count < b.total_count ? 1 : -1));
