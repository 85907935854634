import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import styled from 'styled-components';

import { useGetGamesMinimalInfosQuery } from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { VoteStatistics } from './VoteStatistics';

export const Statistics = () => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const { data, loading } = useGetGamesMinimalInfosQuery({
    variables: { clientId: role === 'admin' ? clientId : undefined },
  });
  const [selectedGame, setSelectedGame] = useState<
    | { id: string; typeName: string; dateStart: string; dateEnd: string }
    | undefined
  >(undefined);

  const sortedData = data
    ? [...data.games].sort((g1, g2) => {
        if (g1.type.name === g2.type.name) return 0;
        if (g1.type.name < g2.type.name) return -1;
        return 1;
      })
    : [];

  const groupedData = sortedData.reduce((acc, game) => {
    if (!acc[game.type.name]) {
      acc[game.type.name] = [];
    }
    acc[game.type.name].push({
      id: game.id,
      name: game.name,
    });
    return acc;
  }, {} as { [typeName: string]: Array<{ id: string; name: string }> });

  return (
    <Container>
      <PageHeader>
        <PageTitle>{t('statistics.menuTitle')}</PageTitle>
      </PageHeader>

      <ActionsLayout>
        <ActionsLeftContainer>
          <Select
            onChange={(value, option) =>
              setSelectedGame({
                id: value as string,
                typeName: (option as any).typename,
                dateStart: (option as any).datestart,
                dateEnd: (option as any).dateend,
              })
            }
            value={selectedGame?.id}
            loading={loading}
            style={{ width: 300 }}
          >
            {Object.entries(groupedData).map(([typeName, games]) => (
              <Select.OptGroup key={typeName} label={typeName}>
                {games.map(({ id, name }) => (
                  <Select.Option key={id} value={id} typename={typeName}>
                    {name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </ActionsLeftContainer>
      </ActionsLayout>

      <PageContent>
        {!selectedGame && t('statistics.selectGame')}
        {selectedGame &&
          selectedGame.typeName !== 'vote' &&
          t('statistics.statisticsIncoming')}
        {selectedGame && selectedGame.typeName === 'vote' && (
          <VoteStatistics gameId={selectedGame.id} />
        )}
      </PageContent>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;
`;
const PageHeader = styled.div`
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  color: #4a4a4a;
`;

const PageTitle = styled.div`
  flex: 1;
  font-size: 2rem;
  font-weight: bold;
`;

const ActionsLayout = styled.div`
  margin: 0.25rem 0 1rem;
  padding: 24px;
  background-color: white;
  display: flex;
  align-items: center;
`;
const ActionsLeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
`;

const PageContent = styled.div`
  padding: 24px;
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
