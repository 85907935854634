import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Switch,
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Typography,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

const { Text } = Typography;

interface QuizzAnswerProps {
  totalPoints: number;
}

export const QuizzAnswer = ({ totalPoints }: QuizzAnswerProps) => {
  const { t } = useTranslation();

  return (
    <>
      {/* HEADtotalPointsER */}
      <StyledRow align="middle" gutter={16}>
        <Col span={15}>
          <Text>{t('quizz.answer.answerTitle')}</Text>
        </Col>
        <Col span={3}>
          <Text>{t('quizz.answer.correct')}</Text>
        </Col>
        <Col span={4}>
          <Text>{t('quizz.answer.points')}</Text>
        </Col>
        <Col span={2} />
      </StyledRow>
      {/* ANSWERS */}
      <Form.List
        name="quizz_questions_answers"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error(t('quizz.form.moreAnswers')));
              }
              return null;
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(field => (
              <StyledRow key={field.key} align="middle" gutter={16}>
                <Col span={15}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'answer']}
                    fieldKey={[field.fieldKey, 'answer']}
                    rules={[
                      {
                        required: true,
                        message: t('quizz.form.missingAnswer'),
                      },
                    ]}
                  >
                    <Input placeholder={t('quizz.form.answerPlaceholder')} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'is_correct']}
                    fieldKey={[field.fieldKey, 'is_correct']}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'points']}
                    fieldKey={[field.fieldKey, 'points']}
                  >
                    <InputNumber style={{ width: 'auto' }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    type="text"
                    onClick={() => remove(field.name)}
                    style={{ marginBottom: '10px' }}
                    icon={<MinusCircleOutlined />}
                  />
                </Col>
              </StyledRow>
            ))}
            {/* FOOTER */}
            <StyledRow gutter={16}>
              <Col span={15}>
                {fields.length < 4 && (
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({ answer: '', is_correct: false, points: 0 })
                    }
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                  >
                    {t('quizz.form.addAnswer')}
                  </Button>
                )}
                <Form.Item>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </Col>
              <Col span={3} />
              <Col span={4}>
                <Text>{totalPoints}</Text>
              </Col>
              <Col span={2} />
            </StyledRow>
          </>
        )}
      </Form.List>
    </>
  );
};

const StyledRow = styled(Row)`
  margin: 12px 0;
`;
