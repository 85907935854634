import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Row, Col, Typography, Radio } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { AssetInput } from 'components';

const { Text } = Typography;

export const PredictionEventOutcomes = ({
  handleSetIsWinnerChange,
}: {
  handleSetIsWinnerChange: (index: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* HEADER */}
      <StyledRow align="middle" gutter={16}>
        <Col span={24}>
          <Text>{t('prediction.outcome.outcomeTitle')}</Text>
        </Col>
      </StyledRow>
      {/* ANSWERS */}
      <Form.List
        name="predictions_events_outcomes"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 2) {
                return Promise.reject(
                  new Error(t('prediction.form.moreOutcomes')),
                );
              }
              return null;
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <StyledRow key={field.key} align="middle" gutter={16}>
                <Col span={12}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'asset']}
                    fieldKey={[field.fieldKey, 'asset']}
                    label={t('prediction.form.asset')}
                  >
                    <AssetInput withCrop cropAspect={16 / 9} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                    type="text"
                    onClick={() => remove(field.name)}
                    style={{ marginLeft: '90%' }}
                    icon={<MinusCircleOutlined />}
                  />
                  <Form.Item
                    {...field}
                    name={[field.name, 'label']}
                    fieldKey={[field.fieldKey, 'label']}
                    label={t('prediction.form.label')}
                  >
                    <Input
                      placeholder={t('prediction.form.labelPlaceholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'is_winner']}
                    fieldKey={[field.fieldKey, 'is_winner']}
                    valuePropName="checked"
                  >
                    <Radio onChange={() => handleSetIsWinnerChange(index)}>
                      {t('prediction.form.isWinner')}
                    </Radio>
                  </Form.Item>
                </Col>
              </StyledRow>
            ))}
            {/* FOOTER */}
            <StyledRow gutter={16}>
              <Col span={24}>
                {fields.length < 2 && (
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                  >
                    {t('prediction.form.addOutcome')}
                  </Button>
                )}

                <Form.Item>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </Col>
            </StyledRow>
          </>
        )}
      </Form.List>
    </>
  );
};

const StyledRow = styled(Row)`
  margin: 12px 0;
`;
