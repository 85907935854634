import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerProps, Form, Input, InputNumber, Row, Col } from 'antd';
import styled from 'styled-components';

import {
  useInsertVoteSelectionMutation,
  useUpdateVoteSelectionMutation,
  VoteFragment,
  VoteSelectionFragment,
  VoteSelectionChoiceFragment,
  VoteSelectionFragmentDoc,
} from '@shared/api';

import { AppForm, SubmitButton } from 'components';
import { VoteSelectionChoices } from './VoteSelectionChoices';

const { TextArea } = Input;

export interface VoteDrawerProps extends DrawerProps {
  vote?: VoteFragment;
  voteSelections?: VoteSelectionFragment;
  onClose: () => void;
}

export const VoteDrawer = ({
  onClose,
  vote,
  voteSelections,
  ...rest
}: VoteDrawerProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [insertVoteSelectionMutation] = useInsertVoteSelectionMutation({
    onCompleted: () => onClose(),
    update: (cache, { data }) => {
      if (vote && data && data.insert_votes_selections_one) {
        cache.modify({
          id: cache.identify(vote),
          fields: {
            votes_selections: refs => {
              const newVoteSelectionRef = cache.writeFragment({
                data: data.insert_votes_selections_one,
                fragment: VoteSelectionFragmentDoc,
                fragmentName: 'VoteSelection',
              });
              return [...refs, newVoteSelectionRef];
            },
          },
        });
      }
    },
  });
  const [updateVoteSelectionMutation] = useUpdateVoteSelectionMutation({
    onCompleted: () => onClose(),
  });

  const onFinish = async ({ ...values }: VoteSelectionFragment) => {
    setLoading(true);
    if (voteSelections) {
      const {
        votes_selections_choices: voteSelectionChoices,
        ...updates
      } = values;
      updateVoteSelectionMutation({
        variables: {
          id: voteSelections.id,
          updates: {
            ...updates,
          },
          voteSelectionsChoices: voteSelectionChoices.map(
            (choice: VoteSelectionChoiceFragment) => {
              const { asset, ...choiceData } = choice;
              return {
                ...choiceData,
                asset_id: asset.id,
                vote_selection_id: voteSelections.id,
                __typename: undefined,
              };
            },
          ),
        },
      });
    } else {
      insertVoteSelectionMutation({
        variables: {
          object: {
            ...values,
            vote_id: vote?.id,
            votes_selections_choices: {
              data: values.votes_selections_choices.map(choice => {
                const { asset, ...choiceData } = choice;
                return {
                  ...choiceData,
                  asset_id: asset.id,
                };
              }),
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (rest.visible) {
      form.resetFields();
    }
  }, [vote]);

  return (
    <Drawer
      width={600}
      title={t('vote.voteTitle')}
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={{ ...voteSelections }}
        preserve={false}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="selection"
            label={t('vote.selection')}
            rules={[{ required: true }]}
          >
            <TextArea
              rows={2}
              showCount
              maxLength={120}
              style={{ color: 'white' }}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="required_choices"
                  label={t('vote.form.requiredChoices')}
                  rules={[{ required: true }]}
                  initialValue={1}
                >
                  <InputNumber style={{ width: '100%' }} min={1} max={100} />
                </Form.Item>
              </Col>
              <Col span={12} />
            </Row>
          </Form.Item>
          <VoteSelectionChoices />
        </FormContainer>
        <SubmitButton
          size="large"
          loading={loading}
          style={{ marginTop: '0.5rem' }}
        />
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;
