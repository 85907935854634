import { AnimationFragment, GameFragment } from '@shared/api';

export const isAr = (game: GameFragment) => game.type.acronym === 'AR';

export const getAnimationName = (animation?: AnimationFragment) =>
  animation?.name || animation?.tracker.name || animation?.tracker.image.name;

export const getAnimationType = (animation?: AnimationFragment) => {
  if (
    !animation ||
    !animation.assets.length ||
    ['image360', 'video360'].includes(animation.assets[0].asset.type)
  )
    return 'portal';
  return animation.assets[0].asset.type;
};

export const getInsidePortalType = (animation?: AnimationFragment) => {
  if (!animation || !animation.assets.length) return 'image360';
  return animation.assets[0].asset.type;
};
