import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Select, Row, Col, Input } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useUpdateTemplateMutation, TemplateFragment } from '@shared/api';

import { supportedLngs } from 'i18n/config';
import { TemplateBuilder } from '../TemplateBuilder';
import { TemplateModalDuplicate } from './Duplicate';

const { Option } = Select;

export interface Props {
  template?: TemplateFragment;
  onClose: () => void;
  visible: boolean;
}

export const TemplateModalDesign = ({ template, onClose, visible }: Props) => {
  const { i18n, t } = useTranslation();
  const [updateTemplateMutation] = useUpdateTemplateMutation();
  const [duplicate, setDuplicate] = useState({
    visible: false,
    isDuplicate: false,
  });
  const [name, setName] = useState({
    toUpdate: false,
    value: template?.name,
  });

  const [selectedLng, setSelectedLng] = useState(i18n.language);
  const [designHtml, setDesignHtml] = useState({
    design: template?.design || {},
    html: template?.html || {},
  });

  const saveDesignHtml = (data: any) => {
    setDesignHtml(data);
  };

  const saveName = () => {
    if (template) {
      updateTemplateMutation({
        variables: {
          templateId: template.id,
          updates: {
            name: name.value,
          },
        },
      });
    }
  };

  const updateDesignHtml = () => {
    if (template) {
      updateTemplateMutation({
        variables: {
          templateId: template.id,
          updates: {
            design: {
              ...designHtml.design,
              [selectedLng]: designHtml.design[selectedLng],
            },
            html: {
              ...designHtml.html,
              [selectedLng]: designHtml.html[selectedLng],
            },
          },
        },
      });
    }
  };

  return (
    <StyledModal
      visible={visible}
      onOk={updateDesignHtml}
      onCancel={onClose}
      width="100%"
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      title={[
        <StyledModalHeaderContainer>
          <div className="ant-modal-title" id="rcDialogTitle3">
            {name.toUpdate ? (
              <StyledNameForm>
                <Input
                  defaultValue={name.value}
                  onChange={event =>
                    setName({ ...name, value: event.target.value })
                  }
                />
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    saveName();
                    setName({ ...name, toUpdate: false });
                  }}
                >
                  {t('common.confirm')}
                </Button>
              </StyledNameForm>
            ) : (
              <>
                {name.value}
                <StyledEditOutlined
                  onClick={() => setName({ ...name, toUpdate: true })}
                />
              </>
            )}
          </div>
          <Row align="middle" gutter={10}>
            <Col>{t('language')}</Col>
            <Col>
              <Select
                value={selectedLng}
                onChange={value => setSelectedLng(value.toString())}
                style={{ width: 120 }}
              >
                {supportedLngs
                  .sort((a, b) => a.localeCompare(b))
                  .map(lng => (
                    <Option key={lng} value={lng}>
                      {t(`languages.${lng}` as any)}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col>
              {Object.keys(designHtml?.design).filter(
                lng => lng !== selectedLng,
              ).length > 0 &&
              !Object.keys(designHtml?.design).includes(selectedLng) ? (
                <>
                  <Button
                    type="link"
                    onClick={() =>
                      setDuplicate({ ...duplicate, visible: true })
                    }
                  >
                    {t('templates.form.duplicate')}
                  </Button>
                  <TemplateModalDuplicate
                    availableLanguage={Object.keys(template?.design).filter(
                      lng => lng !== selectedLng,
                    )}
                    visible={duplicate.visible}
                    onClose={() =>
                      setDuplicate({ ...duplicate, visible: false })
                    }
                    onSaveDuplicate={(lng: string) => {
                      saveDesignHtml({
                        design: {
                          ...designHtml.design,
                          [selectedLng]: designHtml.design[lng],
                        },
                        html: {
                          ...designHtml.html,
                          [selectedLng]: designHtml.html[lng],
                        },
                      });
                      setDuplicate({ visible: false, isDuplicate: true });
                    }}
                  />
                </>
              ) : null}
            </Col>
          </Row>
        </StyledModalHeaderContainer>,
      ]}
    >
      <TemplateBuilder
        key={`${selectedLng}_${duplicate.isDuplicate.toString()}`}
        designHtml={designHtml}
        selectedLng={selectedLng}
        saveDesignHtml={saveDesignHtml}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: unset;
  max-height: unset;
  margin: 0;
  top: 0;
  padding-bottom: 0;
  height: 100vh;
  & .ant-modal-content {
    height: 100vh;
  }
  & .ant-modal-body {
    height: calc(100% - 110px);
    padding: 0;
  }
`;

const StyledNameForm = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const StyledEditOutlined = styled(EditOutlined)`
  margin: 0 24px;
  cursor: pointer;
`;

const StyledModalHeaderContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-right: 70px;
`;
