import React, { useState } from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';

import { ErrorAlert } from './ErrorAlert';

export const AppForm = <Values,>({
  children,
  onFinish,
  ...rest
}: FormProps<Values>) => {
  const [submitErrors, setSubmitErrors] = useState<unknown>();

  const onFinishErrorCatched = async (values: Values) => {
    if (!onFinish) return;
    setSubmitErrors(undefined);
    try {
      await onFinish(values);
    } catch (e) {
      setSubmitErrors(e);
    }
  };

  return (
    <Form
      layout="vertical"
      style={{ width: '100%' }}
      {...rest}
      onFinish={onFinishErrorCatched}
    >
      {submitErrors && (
        <ErrorAlert
          error={submitErrors}
          onClose={() => setSubmitErrors(undefined)}
        />
      )}
      {children}
    </Form>
  );
};
