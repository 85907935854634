import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Switch, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SwapOutlined } from '@ant-design/icons';

import {
  useGetClientsQuery,
  useUpdateClientIsActiveMutation,
} from '@shared/api';

import { AppPageTable, ActionProps } from 'components/AppPageTable';
import { authService, useLoggedClient } from 'features/auth';
import {
  displayFullNameOrEmail,
  displayAdress,
  getRoleColor,
} from 'utils/userUtils';
import { ClientDrawer, Props as DrawerProps } from './ClientDrawer';
import { ClientData } from './types';

export const Clients = () => {
  const { i18n, t } = useTranslation();
  const { id: clientId } = useLoggedClient();
  const { data, loading } = useGetClientsQuery();
  const [updateClientIsActive] = useUpdateClientIsActiveMutation();

  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'client'>
  >({
    visible: false,
  });

  const clients = data?.clients.slice().sort((c1, c2) => {
    if (c1.role === c2.role) return 0;
    if (c1.role === 'admin') return -1;
    if (c2.role === 'admin') return 1;
    if (c1.role === 'reseller') return -1;
    return 1;
  });

  const columns: ColumnsType<ClientData> = [
    {
      title: t('table.name'),
      key: 'fullName',
      render: (_, record) => displayFullNameOrEmail(record),
    },
    {
      title: t('table.city'),
      key: 'address',
      render: (_, record) =>
        displayAdress(record.address, {
          showAddress: false,
          showZipcode: false,
        }),
    },
    {
      title: t('table.type'),
      dataIndex: 'role',
      render: (r, record) => (
        <Tag color={getRoleColor(r)}>
          {t(
            `clientsManagment.roles.${record.role}` as 'clientsManagment.roles.admin',
          )}
        </Tag>
      ),
    },
    {
      title: t('table.registration'),
      dataIndex: 'created_at',
      render: createdAt => {
        const date = new Date(createdAt);
        return date.toLocaleDateString(i18n.language);
      },
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      render: (isActive, { id }) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateClientIsActive({
              variables: { id, isActive: !isActive },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_clients_by_pk: {
                  id,
                  is_active: !isActive,
                  __typename: 'clients',
                },
              },
            });
          }}
        />
      ),
      width: '4rem',
    },
    {
      title: '',
      key: 'action',
      width: '4rem',
      align: 'center',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: client =>
        client.id !== clientId && (
          <Popconfirm
            placement="bottomRight"
            title={t('auth.switchAccount')}
            cancelText={t('common.cancel')}
            onConfirm={() => authService.loginAsClient({ clientId: client.id })}
          >
            <SwapOutlined />
          </Popconfirm>
        ),
    },
  ];

  const actions: ActionProps<ClientData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t('clientsManagment.menuTitle')}
        onAdd={() => setDrawerProps({ visible: true, client: undefined })}
        actions={actions}
        columns={columns}
        dataSource={clients}
        loading={loading}
        rowKey="id"
        onRow={client => ({
          onClick: () => setDrawerProps({ visible: true, client }),
        })}
        rowClassName="cursor-pointer"
      />
      <ClientDrawer
        visible={drawerProps.visible}
        client={drawerProps.client}
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};
