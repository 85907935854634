import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  AnimationFragment,
  GameFragment,
  GoodieFragment,
  useDeleteArM2mAnimationsMutation,
  useDeleteGoodiesM2mAnimationsMutation,
} from '@shared/api';

import { getAnimationName, getAnimationType } from 'utils/arUtils';
import { getAssetUrl } from 'utils/assetUtils';
import {
  AnimationDrawer,
  Props as AnimationDrawerProps,
} from './AnimationDrawer';
import { DeleteAction } from '../actions/DeleteAction';

interface ArProps {
  type: 'ar';
  data: GameFragment & { animations: { animation: AnimationFragment }[] };
  loading: boolean;
}
interface GoodieProps {
  type: 'goodie';
  data: GoodieFragment & { animations: { animation: AnimationFragment }[] };
  loading: boolean;
}

type Props = ArProps | GoodieProps;

const useDeleteAnimationsMutation = (type: Props['type']) => {
  const [deleteArM2mAnimations] = useDeleteArM2mAnimationsMutation();
  const [deleteGoodieM2mAnimations] = useDeleteGoodiesM2mAnimationsMutation();

  const deleteAnimations = (id: string, animationId: string) => {
    if (type === 'ar')
      return deleteArM2mAnimations({
        variables: {
          ar_id: id,
          animation_id: animationId,
        },
        update: (cache, { data }) => {
          if (data && data.delete_ar_m2m_animations_by_pk) {
            cache.modify({
              id: `ar:${id}`,
              fields: {
                animations: (existingRefs, { readField }) =>
                  existingRefs.filter(
                    (ref: any) =>
                      readField('id', ref.animation) !== animationId,
                  ),
              },
            });
          }
        },
        optimisticResponse: {
          __typename: 'mutation_root',
          delete_ar_m2m_animations_by_pk: {
            __typename: 'ar_m2m_animations',
            ar_id: id,
            animation_id: animationId,
          },
        },
      });

    return deleteGoodieM2mAnimations({
      variables: {
        goodie_id: id,
        animation_id: animationId,
      },
      update: (cache, { data }) => {
        if (data && data.delete_goodies_m2m_animations_by_pk) {
          cache.modify({
            id: `goodies:${id}`,
            fields: {
              animations: (existingRefs, { readField }) =>
                existingRefs.filter(
                  (ref: any) => readField('id', ref.animation) !== animationId,
                ),
            },
          });
        }
      },
      optimisticResponse: {
        __typename: 'mutation_root',
        delete_goodies_m2m_animations_by_pk: {
          __typename: 'goodies_m2m_animations',
          goodie_id: id,
          animation_id: animationId,
        },
      },
    });
  };

  return [deleteAnimations];
};

export const AnimationsTab = ({ type, data, loading = false }: Props) => {
  const { t } = useTranslation();
  const [deleteAnimations] = useDeleteAnimationsMutation(type);

  const [drawerProps, setDrawerProps] = useState<
    Pick<AnimationDrawerProps, 'visible' | 'gameOrGoodieId' | 'animation'>
  >({
    visible: false,
  });

  const animations = data.animations.map(a => a.animation);

  const columns: ColumnsType<AnimationFragment> = [
    {
      title: t('ar.tracker'),
      dataIndex: ['tracker'],
      render: tracker => (
        <Image
          src={getAssetUrl(tracker.image, tracker.qrcode_inside)}
          onClick={ev => ev.stopPropagation()}
        />
      ),
      align: 'center',
    },
    {
      title: t('common.name'),
      key: 'name',
      render: (_, animation) => getAnimationName(animation),
    },
    {
      title: t('ar.type'),
      key: 'type',
      render: (_, animation) => getAnimationType(animation),
    },
    {
      title: '',
      key: 'actions',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: animation => (
        <DeleteAction
          onDelete={
            () => deleteAnimations(data.id, animation.id) // eslint-disable-line react/prop-types
          }
        />
      ),
    },
  ];
  return (
    <>
      <AnimationDrawer
        type={type}
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <AddButtonContainer>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            setDrawerProps({
              visible: true,
              animation: undefined,
              gameOrGoodieId: data.id,
            })
          }
        >
          {t('common.add')}
        </Button>
      </AddButtonContainer>
      <Table
        columns={columns}
        dataSource={animations}
        loading={loading}
        rowKey="id"
        onRow={animation => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              animation,
              gameOrGoodieId: data.id,
            }),
        })}
        rowClassName="cursor-pointer"
        pagination={false}
      />
    </>
  );
};

const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;
