import React from 'react';
import styled from 'styled-components';

import { UploadListItem } from './UploadListItem';

interface Props {
  assetId?: string;
  fileNames?: string[];
  isResource?: boolean;
  onDelete: (fileName: string) => void;
}
export const UploadList = ({
  assetId,
  fileNames,
  isResource,
  onDelete,
}: Props) => {
  if (!fileNames) return null;
  return (
    <Container>
      {fileNames.map(fileName => (
        <UploadListItem
          key={fileName}
          assetId={assetId}
          fileName={fileName}
          isResource={isResource}
          onDelete={() => onDelete(fileName)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin: 12px 0;
`;
