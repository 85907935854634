import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { GetGameWinnersQuery, useGetGameWinnersQuery } from '@shared/api';

import { displayFullNameOrId } from 'utils/userUtils';

interface Props {
  gameId: string;
}
const GameWinners = ({ gameId }: Props) => {
  const { i18n, t } = useTranslation();
  const { data, loading } = useGetGameWinnersQuery({
    variables: { game_id: gameId },
  });

  const dataSource = data?.games_played || [];

  const columns: ColumnsType<GetGameWinnersQuery['games_played'][0]> = [
    {
      title: t('common.winner'),
      dataIndex: 'user',
      render: user => displayFullNameOrId(user),
    },
    {
      title: t('games.gain'),
      dataIndex: ['gain', 'name'],
    },
    {
      title: t('common.event'),
      dataIndex: ['event', 'name'],
    },
    {
      title: t('table.date'),
      dataIndex: 'played_at',
      render: playedAt => {
        const date = new Date(playedAt);
        return date.toLocaleDateString(i18n.language);
      },
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
    />
  );
};

export { GameWinners };
