import React, { useState } from 'react';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import {
  useGetTemplatesByClientQuery,
  useUpdateTemplateMutation,
  useArchiveTemplateMutation,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { TemplateModalName, Props as ModalNameProps } from './Modal/Name';
import { TemplateModalDesign, Props as ModalDesignProps } from './Modal/Design';

import { TemplateData } from './types';

const Templates = () => {
  const { id } = useLoggedClient();
  const { t } = useTranslation();
  const [modalNameProps, setModalNameProps] = useState<
    Pick<ModalNameProps, 'visible'>
  >({
    visible: false,
  });
  const [modalDesignProps, setModalDesignProps] = useState<
    Pick<ModalDesignProps, 'visible' | 'template'>
  >({
    visible: false,
  });
  const { data, loading } = useGetTemplatesByClientQuery({
    variables: { clientId: id },
  });
  const [updateTemplateMutation] = useUpdateTemplateMutation();
  const [archiveTemplateMutation] = useArchiveTemplateMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_templates_by_pk) {
        cache.modify({
          fields: {
            templates(existingTemplatesRefs, { readField }) {
              return existingTemplatesRefs.filter(
                (templateRef: any) =>
                  archiveData.update_templates_by_pk?.id !==
                  readField('id', templateRef),
              );
            },
          },
        });
      }
    },
  });

  const columns: ColumnsType<TemplateData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: t('table.language'),
      width: '30%',
      render: template =>
        template.design
          ? Object.keys(template.design)
              .map(lng => t(`languages.${lng}` as any))
              .join(', ')
          : '',
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      key: 'is_active',
      render: (isActive, template) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateTemplateMutation({
              variables: {
                templateId: template.id,
                updates: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_templates_by_pk: {
                  ...template,
                  is_active: !isActive,
                  __typename: 'templates',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: template => (
        <DeleteAction
          onDelete={() => {
            archiveTemplateMutation({
              variables: { templateId: template.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_templates_by_pk: {
                  ...template,
                  is_archive: true,
                  __typename: 'templates',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<TemplateData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t('templates.menuTitle')}
        onAdd={() => setModalNameProps({ visible: true })}
        actions={actions}
        columns={columns}
        dataSource={data?.templates}
        loading={loading}
        onRow={template => ({
          onClick: () =>
            setModalDesignProps({
              visible: true,
              template,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      {modalDesignProps.template ? (
        <TemplateModalDesign
          visible={modalDesignProps.visible}
          template={modalDesignProps.template}
          onClose={() => setModalDesignProps({ visible: false })}
        />
      ) : (
        <TemplateModalName
          visible={modalNameProps.visible}
          onCreate={() => setModalNameProps({ visible: true })}
          onClose={() => setModalNameProps({ visible: false })}
        />
      )}
    </>
  );
};

export { Templates };
