import React from 'react';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';

import { LocationFragment } from '@shared/api';

import { DeleteAction } from 'components';

export const getLocationsTableColumns = (
  t: TFunction<'translation'>,
  onDelete?: (location: LocationFragment) => void,
) => {
  const columns: ColumnsType<LocationFragment> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
    },
    {
      title: t('table.address'),
      dataIndex: 'address',
    },
    {
      title: t('table.radius'),
      dataIndex: 'radius',
    },
  ];
  if (onDelete)
    columns.push({
      title: '',
      key: 'actions',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: location => <DeleteAction onDelete={() => onDelete(location)} />,
    });
  return columns;
};
