import { authService } from 'features/auth';

const FILES_ENDPOINT = `${
  process.env.REACT_APP_AUTH_SERVER_ENDPOINT || 'http://localhost:3002'
}/files`;

export const filesApi = {
  insertQRCodeInsideTracker: (id: string) =>
    authService.fetchJson<void>(`${FILES_ENDPOINT}/insert-qrcode`, 'POST', {
      assetId: id,
    }),
};
