import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
  onDelete: () => void;
}
export const DeleteAction = ({ onDelete }: Props) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('common.confirmDelete')}
      cancelText={t('common.cancel')}
      onConfirm={onDelete}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
};
