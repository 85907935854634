import React from 'react';
import { Image } from 'antd';

import { AssetFragment } from '@shared/api';

import { getAssetUrl } from 'utils/assetUtils';

interface Props {
  asset: AssetFragment;
  maxHeight?: string;
  maxWidth?: string;
  fileUrl?: string;
  qrcodeInside?: boolean;
}

export const Asset = ({
  asset,
  maxHeight,
  maxWidth,
  fileUrl,
  qrcodeInside = false,
}: Props) => {
  const src = fileUrl || getAssetUrl(asset, qrcodeInside);
  const style = { maxHeight, maxWidth };

  if (['video', 'video360'].includes(asset.type))
    return <video src={src} controls style={style} />;

  return <Image src={src} style={style} />;
};
