import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, Row, Switch } from 'antd';
import { CloseOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  PrivateDeckFragmentDoc,
  useInsertPrivateDeckMutation,
  useUpdatePrivateDeckMutation,
} from '@shared/api';

import { AppForm, Loader, DateRangePicker } from 'components';
import { useLoggedClient } from 'features/auth';
import { PrivateDeckData } from '../types';

type FormValues = {
  name: string;
  description: string;
  code: string;
  // eslint-disable-next-line camelcase
  is_active: boolean;
};

interface PrivatesDecksFormDetailsProps {
  privateDeck?: PrivateDeckData;
  onCreate: (privateDeck: PrivateDeckData) => void;
}

export const PrivatesDecksFormSetup = ({
  privateDeck,
  onCreate,
}: PrivatesDecksFormDetailsProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const [insertPrivateDeckMutation] = useInsertPrivateDeckMutation({
    onCompleted: data => {
      if (data && data.insert_privates_decks_one) {
        onCreate(data.insert_privates_decks_one);
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_privates_decks_one) {
        cache.modify({
          fields: {
            privates_decks: privatesDecksRefs => {
              const newPrivateDeckRef = cache.writeFragment({
                data: data.insert_privates_decks_one,
                fragment: PrivateDeckFragmentDoc,
                fragmentName: 'PrivateDeck',
              });
              return [...privatesDecksRefs, newPrivateDeckRef];
            },
          },
        });
      }
    },
  });
  const [updatePrivateDeckMutation] = useUpdatePrivateDeckMutation();

  const onSubmit = async ({ ...values }: FormValues) => {
    setLoading(true);
    if (privateDeck) {
      await updatePrivateDeckMutation({
        variables: {
          privateDeckId: privateDeck.id,
          privateDeck: values,
        },
      });
    } else {
      await insertPrivateDeckMutation({
        variables: {
          privateDeck: {
            ...values,
            client_id: role === 'admin' ? clientId : undefined,
          },
        },
      });
    }
  };

  const initialValues = {
    name: privateDeck?.name || '',
    description: privateDeck?.description || '',
    code: privateDeck?.code || '',
    is_active: privateDeck ? privateDeck.is_active : true,
  };

  useEffect(() => {
    form.resetFields();
  }, [privateDeck]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('privatesDecks.form.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('privatesDecks.form.name')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('privatesDecks.form.dateStart')}
            name="rangeDate"
            rules={[{ required: true }]}
          >
            <DateRangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('privatesDecks.form.description')}
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('privatesDecks.form.code')}
            name="code"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('privatesDecks.form.code')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('privatesDecks.form.isActive')}
            name="is_active"
            valuePropName="checked"
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
