import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  DrawerProps,
  Form,
  Select,
  Input,
  Row,
  Typography,
} from 'antd';
import styled from 'styled-components';

import {
  useInsertEstimateM2mEntityMutation,
  useUpdateEstimateM2mEntityMutation,
  useGetGamesCommunicationsTypesQuery,
  GameTypeFragment,
  CommunicationTypeFragment,
} from '@shared/api';

import { useBillingPriceCalculator } from 'hooks';
import { AppForm, SubmitButton } from 'components';
import { SliderWithInputNumber } from 'components/slider';
import { getPriceByStep, getDiscount } from 'utils/getPriceByStep';
import { getDifferenceInDays } from 'utils/getDifferenceInDays';

const { Paragraph } = Typography;

export interface EstimateFormEntityDrawerProps extends DrawerProps {
  estimate?: any;
  estimateM2mEntity?: any;
  onClose: () => void;
}

export const EstimateFormEntityDrawer = ({
  onClose,
  estimate,
  estimateM2mEntity,
  ...rest
}: EstimateFormEntityDrawerProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [totalPrice, setPrice] = useBillingPriceCalculator({
    radius: 0,
    trackers: 0,
    entityPrice: 0,
  });

  const [form] = Form.useForm();
  const [insertEstimateM2mEntity] = useInsertEstimateM2mEntityMutation();
  const [updateEstimateM2mEntity] = useUpdateEstimateM2mEntityMutation();
  const totalDays = getDifferenceInDays(
    estimate?.date_start,
    estimate?.date_end,
  );

  const { data } = useGetGamesCommunicationsTypesQuery();

  const onFinish = async (values: any) => {
    setLoading(true);
    if (estimateM2mEntity) {
      updateEstimateM2mEntity({
        variables: {
          estimateM2mEntityId: estimateM2mEntity.id,
          estimateM2mEntity: {
            ...values,
          },
        },
      });
    } else {
      insertEstimateM2mEntity({
        variables: {
          estimateM2mEntity: {
            ...values,
            estimate_id: estimate.id,
          },
        },
        update: (cache, { data: dataEstimateM2mEntity }) => {
          if (
            dataEstimateM2mEntity &&
            dataEstimateM2mEntity.insert_estimates_m2m_entities_one
          ) {
            cache.modify({
              id: `estimates:${estimate.id}`,
              fields: {
                estimates_m2m_entities: estimatesM2mEntitiesRefs => [
                  ...estimatesM2mEntitiesRefs,
                  dataEstimateM2mEntity.insert_estimates_m2m_entities_one,
                ],
              },
            });
          }
        },
      });
    }
    onClose();
  };

  const getCurrentEntityType: any = (entityId: string) =>
    [
      ...(data?.games_types || []),
      ...(data?.communications_types || []),
    ].filter(
      ({ id }: GameTypeFragment | CommunicationTypeFragment) => id === entityId,
    )[0] || {};

  useEffect(() => {
    form.resetFields();
    if (estimateM2mEntity) {
      const currentEntityType = getCurrentEntityType(
        estimateM2mEntity?.entity_id,
      );
      setPrice({
        radius:
          getPriceByStep(
            estimateM2mEntity?.radius,
            currentEntityType.radius_price_range,
          ) || 0 * totalDays,
        trackers:
          getPriceByStep(
            estimateM2mEntity?.trackers,
            currentEntityType.trackers_price_range,
          ) || 0 * totalDays,
        entityPrice:
          getCurrentEntityType(estimateM2mEntity?.entity_id).price * totalDays,
      });
      setSelectedEntityId(estimateM2mEntity?.entity_id);
    }
  }, [estimateM2mEntity]);

  const initialValues = {
    entity_id: estimateM2mEntity?.entity_id || '',
    name: estimateM2mEntity?.name || '',
    radius: estimateM2mEntity?.radius || 200,
    trackers: estimateM2mEntity?.trackers || 2,
  };
  return (
    <Drawer
      width={600}
      title={t('estimates.form.createEntity')}
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={estimateM2mEntity}
        preserve={false}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="entity_id"
            label={t('estimates.form.entities.type')}
            rules={[{ required: true }]}
          >
            <Select
              loading={loading}
              onChange={(value: string) => {
                const currentEntityType = getCurrentEntityType(value);
                setPrice({
                  entityPrice: currentEntityType.price * totalDays,
                });
                setSelectedEntityId(value);
              }}
              style={{ width: '100%' }}
            >
              <Select.OptGroup key="games_types" label="Animations">
                {data?.games_types.map(({ id, name }: GameTypeFragment) => (
                  <Select.Option key={id} value={id}>
                    {t(
                      `${name}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                    )}
                  </Select.Option>
                ))}
              </Select.OptGroup>
              <Select.OptGroup
                key="communications_types"
                label="Communications"
              >
                {data?.communications_types.map(
                  ({ id, name }: CommunicationTypeFragment) => (
                    <Select.Option key={id} value={id}>
                      {t(
                        `${name}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                      )}
                    </Select.Option>
                  ),
                )}
              </Select.OptGroup>
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label={t('estimates.form.entities.name')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {!!getCurrentEntityType(selectedEntityId).radius_price_range && (
            <>
              <Form.Item
                name="radius"
                label={t('estimates.form.entities.radius')}
              >
                <SliderWithInputNumber
                  min={50}
                  max={
                    getCurrentEntityType(selectedEntityId).acronym === 'IF'
                      ? 1000000
                      : 20000
                  }
                  step={50}
                  defaultValue={initialValues.radius}
                  disabled={!selectedEntityId}
                  onChange={(value: number) => {
                    setPrice({
                      radius:
                        getPriceByStep(
                          value,
                          getCurrentEntityType(selectedEntityId)
                            .radius_price_range,
                        ) * totalDays,
                    });
                  }}
                />
              </Form.Item>
              <Paragraph disabled>
                {t('estimates.form.entities.radiusHelp')}
              </Paragraph>
            </>
          )}
          {getCurrentEntityType(selectedEntityId)?.acronym === 'AR' ? (
            <Form.Item
              name="trackers"
              label={t('estimates.form.entities.trackers')}
            >
              <SliderWithInputNumber
                min={1}
                max={50}
                defaultValue={initialValues.trackers}
                disabled={!selectedEntityId}
                onChange={(value: number) => {
                  setPrice({
                    trackers:
                      getPriceByStep(
                        value,
                        getCurrentEntityType(selectedEntityId)
                          .trackers_price_range,
                      ) * totalDays,
                  });
                }}
              />
            </Form.Item>
          ) : null}
          <Total>
            <TotalTiltle>{t('gameBilling.totalPrice')}</TotalTiltle>
            <TotalPrice>
              {Math.round(totalPrice * (1 - getDiscount(totalDays)))}
              <TotalPriceSup>
                {t('gameBilling.totalPriceSupDays', { days: totalDays })}
                <TotalPriceDay>
                  {t('gameBilling.orPriceByDay')}{' '}
                  {Math.round(
                    (totalPrice / totalDays) * (1 - getDiscount(totalDays)),
                  )}
                  <sup>{t('gameBilling.totalPriceSupDay')}</sup>
                </TotalPriceDay>
              </TotalPriceSup>
            </TotalPrice>
            <TotalAction>
              <SubmitButton
                size="large"
                loading={loading}
                style={{ marginTop: '0.5rem' }}
              />
            </TotalAction>
          </Total>
        </FormContainer>
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;

const Total = styled(Row)`
  position: absolute;
  bottom: 0;
  width: 90%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  height: 40%;
  padding: 24px;
  align-items: center;
`;

const TotalTiltle = styled.div`
  flex: 1;
  color: white;
  font-size: 18px;
  text-align: center;
`;

const TotalPrice = styled.div`
  display: flex;
  color: white;
  font-size: 52px;
  position: relative;
`;

const TotalPriceSup = styled.div`
  font-size: 27px;
  margin-left: 10px;
`;

const TotalPriceDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
`;

const TotalPriceDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  font-size: 12px;
  font-weight: bold;
  background-color: #fff;
  border-radius: 10px;
  color: #d73716;
  margin-left: 10px;
`;

const TotalAction = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 0;
`;

const TotalConditions = styled.div`
  flex: 1;
  color: lightGray;
  width: 100%;
  font-size: 12px;
`;
