import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { useStatistic } from 'context/StatisticContext';
import { ChartCard, Field, MiniArea } from 'components';

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  style: { marginBottom: 24 },
};

export const IntroduceRow = () => {
  const { t } = useTranslation();
  const { participations, gains } = useStatistic();

  return (
    <Row gutter={24}>
      <Col {...topColResponsiveProps}>
        <ChartCard
          bordered={false}
          loading={false}
          title={t('dashboard.participations')}
          total={participations.reduce(
            (acc: any, item: any) => acc + item.total_count,
            0,
          )}
          footer={
            <Field
              label={t('dashboard.dailyParticipations')}
              value={
                participations.length > 0
                  ? Math.round(
                      (participations.reduce(
                        (acc: any, item: any) => acc + item.total_count,
                        0,
                      ) /
                        participations.length) *
                        100,
                    ) / 100
                  : 0
              }
            />
          }
          contentHeight={48}
        >
          <MiniArea
            color="#D73716"
            data={participations.map((d: any) => d.total_count)}
          />
        </ChartCard>
      </Col>
      <Col {...topColResponsiveProps}>
        <ChartCard
          bordered={false}
          loading={false}
          title={t('dashboard.gains')}
          total={gains.reduce(
            (acc: any, item: any) => acc + item.total_count,
            0,
          )}
          footer={
            <Field
              label={t('dashboard.dailyGains')}
              value={
                gains.length > 0
                  ? Math.round(
                      (gains.reduce(
                        (acc: any, item: any) => acc + item.total_count,
                        0,
                      ) /
                        participations.length) *
                        100,
                    ) / 100
                  : 0
              }
            />
          }
          contentHeight={48}
        >
          <MiniArea
            color="#D73716"
            data={gains.map((d: any) => d.total_count)}
          />
        </ChartCard>
      </Col>
    </Row>
  );
};
