import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Geography } from '@shared/api';

import {
  AppFormAutocomplete,
  FormValues as AutocompleteFormValues,
} from './AppFormAutocomplete';

const { Paragraph } = Typography;

const FRANCE_CENTER: LatLng = { lat: 46.227638, lng: 2.213749 };
type googleMapProp =
  | {
      mapApiLoaded: false;
    }
  | {
      mapApiLoaded: true;
      mapInstance: google.maps.Map;
      mapApi: typeof google.maps;
      mapCircle: google.maps.Circle;
    };

export type FormValues = AutocompleteFormValues & {
  radius?: number;
};

interface Props {
  form: FormInstance<FormValues>;
  hasRadius?: boolean;
}

export const AppFormMap = ({ form, hasRadius = true }: Props) => {
  const { t } = useTranslation();
  const [googleMap, setGoogleMap] = useState<googleMapProp>({
    mapApiLoaded: false,
  });

  const apiHasLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
    const { geography, radius } = form.getFieldsValue();
    setGoogleMap({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
      mapCircle: new maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.3,
        editable: false,
        map,
        center: geography && {
          lat: geography.coordinates[1],
          lng: geography.coordinates[0],
        },
        radius,
      }),
    });
  };

  const handlePlace = (geography: Geography) => {
    if (googleMap.mapApiLoaded) {
      const [lng, lat] = geography.coordinates;
      googleMap.mapCircle.setCenter({ lat, lng });
    }
  };

  const handleChangeRadius = (
    value: string | number | null | undefined,
  ): void => {
    if (googleMap.mapApiLoaded) googleMap.mapCircle.setRadius(value as number);
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <AppFormAutocomplete
          form={form}
          handlePlace={handlePlace}
          mapApiReady={googleMap.mapApiLoaded}
        />

        {hasRadius && (
          <>
            <Form.Item
              label={t('games.form.radius')}
              name="radius"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={50}
                max={100000}
                step={50}
                style={{ width: '100%' }}
                placeholder={t('games.form.radius')}
                onChange={handleChangeRadius}
              />
            </Form.Item>
            <Paragraph disabled>{t('games.form.radiusHelp')}</Paragraph>
          </>
        )}

        <Form.Item<FormValues>
          shouldUpdate={(prevValues, curValues) =>
            prevValues.tz !== curValues.tz
          }
          noStyle
        >
          {({ getFieldsValue }) => {
            const { tz } = getFieldsValue();
            if (!tz) return null;
            return (
              <Form.Item<FormValues> label={t('common.tz')}>
                <Input
                  style={{ width: '100%' }}
                  placeholder={t('games.form.radius')}
                  value={tz}
                  disabled
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <MapContainer span={12}>
        <Form.Item<FormValues>
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.geography !== curValues.geography
          }
        >
          {({ getFieldsValue }) => {
            const { geography } = getFieldsValue();
            return (
              <GoogleMapReact
                defaultCenter={FRANCE_CENTER}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_API_KEY!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  libraries: ['places', 'geometry'],
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  apiHasLoaded(map, maps);
                }}
                zoom={geography ? 14 : 4}
                center={
                  geography && {
                    lat: geography.coordinates[1],
                    lng: geography.coordinates[0],
                  }
                }
              >
                {geography && (
                  <Marker
                    lat={geography.coordinates[1]}
                    lng={geography.coordinates[0]}
                  />
                )}
              </GoogleMapReact>
            );
          }}
        </Form.Item>
      </MapContainer>
    </Row>
  );
};

const MapContainer = styled(Col)`
  min-height: 200px;
`;

const Marker = styled.div<LatLng>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
`;
