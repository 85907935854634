import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import {
  VoteFragment,
  VoteSelectionFragment,
  useDeleteVoteSelectionMutation,
} from '@shared/api';

import { Loader, DeleteAction } from 'components';
import { VoteDrawer, VoteDrawerProps } from './VoteDrawer';

interface VoteProps {
  vote?: VoteFragment;
  loading: boolean;
}

const Vote = ({ vote, loading = false }: VoteProps) => {
  const { t } = useTranslation();
  const [deleteVoteSelectionMutation] = useDeleteVoteSelectionMutation({
    update: (cache, { data: deleteData }) => {
      if (vote && deleteData && deleteData.delete_votes_selections_by_pk) {
        cache.modify({
          id: cache.identify(vote),
          fields: {
            votes_selections: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  deleteData.delete_votes_selections_by_pk?.id !==
                  readField('id', ref),
              ),
          },
        });
      }
    },
  });
  const [drawerProps, setDrawerProps] = useState<
    Pick<VoteDrawerProps, 'visible' | 'vote' | 'voteSelections'>
  >({
    visible: false,
  });

  const columns: ColumnsType<VoteSelectionFragment> = [
    {
      title: t('table.selections'),
      dataIndex: 'selection',
      key: 'selection',
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: voteSelections => (
        <DeleteAction
          onDelete={() => {
            deleteVoteSelectionMutation({
              variables: { id: voteSelections.id },
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <VoteDrawer
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <Row justify="end" gutter={16}>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            onClick={() => setDrawerProps({ visible: true, vote })}
          >
            {loading ? <Loader /> : t('common.add')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            dataSource={vote?.votes_selections || []}
            loading={loading}
            onRow={(voteSelections: VoteSelectionFragment) => ({
              onClick: () =>
                setDrawerProps({ visible: true, vote, voteSelections }),
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export { Vote };
