import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { IncludedLangSwitch } from 'components';
import { Login, Signup, ForgottenPassword, ResetPassword } from './forms';

export const AuthRoutes = () => (
  <IncludedLangSwitch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/signup">
      <Signup />
    </Route>
    <Route path="/forgotten-password">
      <ForgottenPassword />
    </Route>
    <Route path="/reset-password">
      <ResetPassword />
    </Route>
    <Redirect to="/login" />
  </IncludedLangSwitch>
);
