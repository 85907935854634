import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  useGetGoodiesQuery,
  useUpdateGoodieMutation,
  useArchiveGoodieMutation,
} from '@shared/api';

import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { useLoggedClient } from 'features/auth';
import { GoodieDrawer, Props as DrawerProps } from './GoodieDrawer';
import { GoodieData } from './types';

export const Goodies = () => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'goodie'>
  >({
    visible: false,
  });
  const { data, loading } = useGetGoodiesQuery({
    variables:
      role === 'admin'
        ? { where: { client_id: { _eq: clientId } } }
        : undefined,
  });

  const [updateGoodie] = useUpdateGoodieMutation();
  const [archiveGoodie] = useArchiveGoodieMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_goodies_by_pk) {
        cache.modify({
          fields: {
            goodies: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  archiveData.update_goodies_by_pk?.id !== readField('id', ref),
              ),
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (data && drawerProps.goodie) {
      setDrawerProps({
        visible: drawerProps.visible,
        goodie: data.goodies.find(
          goodie => goodie.id === drawerProps.goodie?.id,
        ),
      });
    }
  }, [data]);

  const columns: ColumnsType<GoodieData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      width: '25%',
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      width: '25%',
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      key: 'is_active',
      render: (isActive, goodie) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateGoodie({
              variables: {
                goodieId: goodie.id,
                goodie: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_goodies_by_pk: {
                  ...goodie,
                  is_active: !isActive,
                  __typename: 'goodies',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: goodie => (
        <DeleteAction
          onDelete={() => {
            archiveGoodie({
              variables: { goodieId: goodie.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_goodies_by_pk: {
                  ...goodie,
                  is_archive: true,
                  __typename: 'goodies',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<GoodieData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t(`goodies.menuTitle`)}
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.goodies}
        loading={loading}
        rowKey="id"
        onRow={goodie => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              goodie,
            }),
        })}
        rowClassName="cursor-pointer"
      />

      <GoodieDrawer
        visible={drawerProps.visible}
        goodie={drawerProps.goodie}
        onAdd={goodie =>
          setDrawerProps({
            visible: true,
            goodie,
          })
        }
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};
