import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  Assets,
  ClientDisplayFragmentDoc,
  ClientsRolesEnum,
  ClientsSetInput,
  useInsertClientMutation,
  useUpdateClientMutation,
  useUpsertAddressMutation,
  AssetsTypesEnum,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AppForm, Loader, Upload } from 'components';
import { getDirtyValues } from 'utils/formUtils';
import { ClientData } from './types';

const { Option } = Select;

type FormValues = {
  email: string;
  phone?: string | null;
  name: string;
  // eslint-disable-next-line camelcase
  first_name?: string | null;
  address: {
    address?: string | null;
    zipcode?: string | null;
    city?: string | null;
    country?: string | null;
  };
  discount?: number | null;
  role?: ClientsRolesEnum;
  picture?: Assets;
};

type ClientUpdateInput = ClientsSetInput & { address?: FormValues['address'] };

interface Props {
  client?: ClientData;
  closeDrawer?: () => void;
}

export const ClientForm = ({ client, closeDrawer }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const { t } = useTranslation();
  const { role } = useLoggedClient();
  const [insertClient] = useInsertClientMutation({
    onCompleted: () => (closeDrawer ? closeDrawer() : undefined),
    update: (cache, { data }) => {
      if (data && data.insert_clients_one) {
        cache.modify({
          fields: {
            clients: clientsRefs => {
              const newClientRef = cache.writeFragment({
                data: data.insert_clients_one,
                fragment: ClientDisplayFragmentDoc,
                fragmentName: 'ClientDisplay',
              });
              return [...clientsRefs, newClientRef];
            },
          },
        });
      }
    },
  });
  const [updateClient] = useUpdateClientMutation();
  const [upsertAddress] = useUpsertAddressMutation({
    update: client?.address_id
      ? (cache, { data }) => {
          if (data && data.insert_addresses_one) {
            cache.modify({
              id: `clients:${client.id}`,
              fields: {
                address: () => data.insert_addresses_one,
              },
            });
          }
        }
      : undefined,
  });

  const [loading, setLoading] = useState(false);

  const initialValues = {
    company: client?.company || '',
    vat_id: client?.vat_id || '',
    email: client?.email || '',
    phone: client?.phone || '',
    name: client?.name || '',
    first_name: client?.first_name || '',
    address: {
      address: client?.address?.address || '',
      zipcode: client?.address?.zipcode || '',
      city: client?.address?.city || '',
      country: client?.address?.country || '',
    },
    discount: client?.discount,
    role: client?.role,
    picture: client?.picture,
  };

  useEffect(() => {
    form.resetFields();
  }, [client]);

  const onFinish = async ({ picture, ...values }: FormValues) => {
    setLoading(true);
    const dirtyValues = getDirtyValues(values, initialValues);
    if (!client) {
      await insertClient({
        variables: {
          client: {
            ...dirtyValues,
            address: dirtyValues.address
              ? {
                  data: { ...dirtyValues.address },
                }
              : undefined,
            password: Math.random().toString(36).slice(2, 10), // TODO: improve by calling an api endpoint which will create random password and send it by mail
          },
        },
      });
    } else {
      const clientUpdateInput = dirtyValues as ClientUpdateInput;
      if (clientUpdateInput.address) {
        const address = {
          id: client.address_id || undefined,
          ...clientUpdateInput.address,
        };
        const response = await upsertAddress({
          variables: { address },
        });
        delete clientUpdateInput.address;
        if (!client.address_id)
          clientUpdateInput.address_id =
            response.data?.insert_addresses_one?.id;
      }
      if (picture) {
        clientUpdateInput.picture_id = picture.id;
      }
      if (clientUpdateInput) {
        await updateClient({
          variables: {
            clientId: client.id,
            client: clientUpdateInput,
          },
        });
      }
    }
  };

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onFinish(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col span="12">
          <Form.Item name="company" label={t('clientsManagment.company')}>
            <Input placeholder={t('clientsManagment.company')} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item name="vat_id" label={t('clientsManagment.vatId')}>
            <Input placeholder={t('clientsManagment.vatId')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span="12">
          <Form.Item
            name="email"
            label={t('user.email')}
            rules={[{ type: 'email', required: true }]}
          >
            <Input placeholder={t('user.email')} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item name="phone" label={t('user.phone')}>
            <Input placeholder={t('user.phone')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span="12">
          <Form.Item
            name="name"
            label={t('user.name')}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('user.name')} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item name="first_name" label={t('user.firstname')}>
            <Input placeholder={t('user.firstname')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={['address', 'address']} label={t('address.address')}>
        <Input placeholder={t('address.address')} />
      </Form.Item>

      <Row gutter={16}>
        <Col span="12">
          <Form.Item name={['address', 'zipcode']} label={t('address.zipcode')}>
            <Input placeholder={t('address.zipcode')} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item name={['address', 'city']} label={t('address.city')}>
            <Input placeholder={t('address.city')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={['address', 'country']} label={t('address.country')}>
        <Input placeholder={t('address.country')} />
      </Form.Item>

      {role === 'admin' && (
        <Row gutter={16}>
          <Col span="12">
            <Form.Item name="role" label={t('clientsManagment.role')}>
              <Select placeholder={t('clientsManagment.roles.client')}>
                <Option value="admin">
                  {t('clientsManagment.roles.admin')}
                </Option>
                <Option value="reseller">
                  {t('clientsManagment.roles.reseller')}
                </Option>
                <Option value="client">
                  {t('clientsManagment.roles.client')}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="discount"
              label={`${t('clientsManagment.discount')} %`}
            >
              <InputNumber placeholder="0" min={0} max={100} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span="12">
          <Form.Item name="picture" label={t('clientsManagment.picture')}>
            <Upload alone withCrop type={AssetsTypesEnum.Image} />
          </Form.Item>
        </Col>
      </Row>

      <StyledDivider />

      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};

const StyledDivider = styled(Divider)`
  border-top: 1px solid rgb(217, 217, 217);
  margin: 1rem 0;
`;
