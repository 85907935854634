import React, { useMemo, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { Column } from '@ant-design/charts';
import { addDays, set } from 'date-fns';
import styled from 'styled-components';

import {
  useGetVoteSelectionsQuery,
  useSubscribeVotesPlayedSubscription,
} from '@shared/api';

import { DateSlider, Loader } from 'components';
import { useTranslation } from 'react-i18next';

interface Props {
  gameId: string;
}
export const VoteStatistics = ({ gameId }: Props) => {
  const { t } = useTranslation();
  const [rangeDate, setRangeDate] = useState([new Date(0), new Date()]);

  const {
    data: VSData,
    error: VSError,
    loading: VSLoading,
  } = useGetVoteSelectionsQuery({
    variables: { gameId },
  });
  const {
    data: VPData,
    error: VPError,
    loading: VPLoading,
  } = useSubscribeVotesPlayedSubscription({
    variables: { gameId },
  });

  const votesPlayedRange = VPData?.votes_played.reduce(
    (acc, cur) => {
      const date = new Date(cur.played_at);
      if (date < acc[0]) acc[0] = date;
      if (date > acc[1]) acc[1] = date;
      return acc;
    },
    [new Date(), new Date(0)],
  );

  const filteredVotesPlayed = useMemo(
    () =>
      VPData?.votes_played.filter(item => {
        const playedAt = new Date(item.played_at);
        return playedAt >= rangeDate[0] && playedAt <= rangeDate[1];
      }),
    [VPData, rangeDate],
  );

  const chartData = useMemo(() => {
    const voteChoicesCount =
      filteredVotesPlayed?.reduce((acc, cur) => {
        cur.results.forEach((r: { id: string }) => {
          if (acc[r.id]) acc[r.id] += 1;
          else acc[r.id] = 1;
        });
        return acc;
      }, {} as { [id: string]: number }) || {};
    const data =
      VSData?.votes_selections.reduce((acc, cur) => {
        cur.votes_selections_choices.forEach(choice => {
          acc.push({
            selection: cur.selection,
            label: choice.label,
            count: voteChoicesCount[choice.id] || 0,
          });
        });
        return acc;
      }, [] as Array<{ selection: string; label: string; count: number }>) ||
      [];
    return data.sort((d1, d2) => {
      if (d1.selection === d2.selection) return d2.count - d1.count;
      return 0;
    });
  }, [VSData, filteredVotesPlayed]);

  const error = VSError || VPError;
  if (error) return <>{error.message}</>;

  const loading = VSLoading || VPLoading;
  if (loading) return <Loader fontSize={40} className="primary-color" />;

  const uniqueUsers = new Set(filteredVotesPlayed?.map(item => item.user_id));

  return (
    <Container>
      <Row justify="space-around">
        <InformationCol className="layout-background">
          <Typography.Text strong>
            {t('statistics.numberOfParticipations', {
              count: filteredVotesPlayed?.length || 0,
            })}
          </Typography.Text>
        </InformationCol>
        <InformationCol className="layout-background">
          <Typography.Text strong>
            {t('statistics.numberOfUsers', {
              count: uniqueUsers.size,
            })}
          </Typography.Text>
        </InformationCol>
      </Row>

      <Column
        data={chartData}
        xField="selection"
        yField="count"
        seriesField="label"
        isGroup
        yAxis={{ title: { text: t('statistics.numberOfVotes') } }}
        label={{
          position: 'middle',
          layout: [
            { type: 'interval-adjust-position' },
            { type: 'interval-hide-overlap' },
            { type: 'adjust-color' },
          ],
          content: item => (item.count ? item.label : undefined),
          rotate: -Math.PI / 2,
        }}
        legend={false}
        style={{ flex: 1 }}
      />

      {votesPlayedRange && (
        <DateSlider
          dateStart={votesPlayedRange[0]}
          dateEnd={votesPlayedRange[1]}
          range
          dots
          onAfterChange={newRange => {
            if (
              newRange[0].getTime() !== rangeDate[0].getTime() ||
              newRange[1].getTime() !== rangeDate[1].getTime()
            )
              setRangeDate(newRange);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const InformationCol = styled(Col)`
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
`;
