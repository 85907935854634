import {
  InfoFragmentDoc,
  PhotoFragmentDoc,
  SurveyFragmentDoc,
  useInsertInfoMutation,
  useInsertPhotoMutation,
  useInsertSurveyMutation,
  CommunicationFragmentDoc,
  CommunicationsInsertInput,
} from '@shared/api';

interface CommunicationInsertInput {
  communicationTypeName: string;
  communication: CommunicationsInsertInput;
}

interface Props {
  onAdd: (communication: any) => void;
}

export const useInsertCommunicationByType = ({ onAdd }: Props) => {
  // INSERT INFOS
  const [insertInfo] = useInsertInfoMutation({
    onCompleted: data => {
      if (data.insert_infos_one) {
        onAdd({ ...data.insert_infos_one, __typename: 'communications' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_infos_one) {
        cache.modify({
          fields: {
            infos: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_infos_one,
                fragment: InfoFragmentDoc,
                fragmentName: 'Infos',
              });
              return [newRef, ...refs];
            },
            communications: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_infos_one,
                  animations: undefined,
                  __typename: 'communications',
                },
                fragment: CommunicationFragmentDoc,
                fragmentName: 'Communication',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });
  // INSERT PHOTO
  const [insertPhoto] = useInsertPhotoMutation({
    onCompleted: data => {
      if (data.insert_photos_one) {
        onAdd({ ...data.insert_photos_one, __typename: 'communications' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_photos_one) {
        cache.modify({
          fields: {
            photos: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_photos_one,
                fragment: PhotoFragmentDoc,
                fragmentName: 'Photos',
              });
              return [newRef, ...refs];
            },
            communications: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_photos_one,
                  animations: undefined,
                  __typename: 'communications',
                },
                fragment: CommunicationFragmentDoc,
                fragmentName: 'Communication',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });
  // INSERT SURVEY
  const [insertSurvey] = useInsertSurveyMutation({
    onCompleted: data => {
      if (data.insert_surveys_one) {
        onAdd({ ...data.insert_surveys_one, __typename: 'communications' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_surveys_one) {
        cache.modify({
          fields: {
            surveys: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_surveys_one,
                fragment: SurveyFragmentDoc,
                fragmentName: 'Surveys',
              });
              return [newRef, ...refs];
            },
            communications: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_surveys_one,
                  animations: undefined,
                  __typename: 'communications',
                },
                fragment: CommunicationFragmentDoc,
                fragmentName: 'Communication',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });

  const insertCommunication = async (input: CommunicationInsertInput) => {
    if (input.communicationTypeName === 'info') {
      await insertInfo({ variables: { info: input.communication } });
    } else if (input.communicationTypeName === 'photo') {
      await insertPhoto({ variables: { photo: input.communication } });
    } else if (input.communicationTypeName === 'survey') {
      await insertSurvey({ variables: { survey: input.communication } });
    }
  };

  return [insertCommunication];
};
