/**
 * Be careful, return is a string typed as string[] for hasura type
 */
export const stringifyArrayForHasura = (array: string[]) => {
  let result = '{';
  array.forEach((elem, index) => {
    result += `"${elem}"`;
    if (index < array.length - 1) result += ',';
  });
  result += '}';
  return (result as unknown) as string[];
};
