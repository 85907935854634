import React, { useState } from 'react';
import { Col, InputNumber, Row, Slider } from 'antd';

interface SliderWithInputNumberProps {
  min: number;
  max: number;
  step?: number;
  defaultValue: number;
  title?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
}

export const SliderWithInputNumber = ({
  min,
  max,
  step = 1,
  title,
  defaultValue,
  disabled = false,
  onChange,
}: SliderWithInputNumberProps) => {
  const [inputValue, setInputValue] = useState<number>(defaultValue);

  const onInputChange = (value: any): void => {
    setInputValue(value);
    onChange(value);
  };

  return (
    <>
      {!!title && <Row>{title}</Row>}
      <Row>
        <Col span={20}>
          <Slider
            min={min}
            max={max}
            step={step}
            onChange={onInputChange}
            value={inputValue}
            disabled={disabled}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={min}
            max={max}
            step={step}
            style={{ margin: '0 16px' }}
            value={inputValue}
            onChange={onInputChange}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  );
};
