import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Form, Input } from 'antd';

import { useInsertTemplateMutation, TemplateFragmentDoc } from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { AppForm } from 'components';

import { TemplateData } from '../types';

type FormValues = {
  name: string;
};

export interface Props {
  onClose: () => void;
  onCreate: (template: TemplateData) => void;
  visible: boolean;
}

export const TemplateModalName = ({ onCreate, onClose, visible }: Props) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const [insertTemplateMutation] = useInsertTemplateMutation({
    onCompleted: data => {
      if (data && data.insert_templates_one) {
        onCreate(data.insert_templates_one);
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_templates_one) {
        cache.modify({
          fields: {
            templates: templatesRefs => {
              const newTemplateRef = cache.writeFragment({
                data: data.insert_templates_one,
                fragment: TemplateFragmentDoc,
                fragmentName: 'Template',
              });
              return [...templatesRefs, newTemplateRef];
            },
          },
        });
      }
    },
  });

  const onOk = () => {
    form.submit();
  };

  const onSubmit = async (values: FormValues) => {
    await insertTemplateMutation({
      variables: {
        template: {
          ...values,
          client_id: role === 'admin' ? clientId : undefined,
        },
      },
    });
    onClose();
  };

  const initialValues = {
    name: '',
  };

  return (
    <Modal
      title={t('templates.duplicateTemplate')}
      visible={visible}
      onCancel={onClose}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onOk={onOk}
    >
      <AppForm
        form={form}
        initialValues={initialValues}
        onFinish={values => onSubmit(values)}
      >
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item
              label={t('templates.form.name')}
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder={t('templates.form.name')} />
            </Form.Item>
          </Col>
        </Row>
      </AppForm>
    </Modal>
  );
};
