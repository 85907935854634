import React, { useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Form,
  Row,
  Switch,
} from 'antd';
import { CloseOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { AppForm, Loader } from 'components';
import { codeGenerator } from 'utils/codeGenerator';

type FormValues = {
  prefix: string;
  length: number;
  number: number;
  upper: boolean;
  lower: boolean;
  digit: boolean;
};

interface GainsFormVirtualGainGenerate {
  onGeneratedCodes: (codes: string[]) => void;
  virtualType?: string;
}

const GainsFormVirtualGainGenerate = ({
  onGeneratedCodes,
  virtualType,
}: GainsFormVirtualGainGenerate) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const codes = new Array(values.number)
      .fill(null)
      .map(() => codeGenerator(values));
    onGeneratedCodes(codes);
  };

  const initialValues = {
    prefix: '',
    length: 5,
    number: virtualType === 'unique' ? 1 : 5,
    upper: true,
    lower: true,
    digit: true,
  };

  return (
    <>
      <AppForm
        form={form}
        initialValues={initialValues}
        onFinish={values => onSubmit(values).finally(() => setLoading(false))}
      >
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item
              label={t('gains.form.virtual.generator.prefix')}
              name="prefix"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item
              label={t('gains.form.virtual.generator.length')}
              name="length"
            >
              <InputNumber min={3} max={1000} defaultValue={5} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label={t('gains.form.virtual.generator.number')}
              name="number"
            >
              <InputNumber
                min={1}
                max={1000}
                disabled={virtualType === 'unique'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item
              label={t('gains.form.virtual.generator.upper')}
              name="upper"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label={t('gains.form.virtual.generator.lower')}
              name="lower"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label={t('gains.form.virtual.generator.digit')}
              name="digit"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {loading ? <Loader /> : t('common.confirm')}
          </Button>
        </Row>
      </AppForm>
    </>
  );
};

export { GainsFormVirtualGainGenerate };
