export const getPriceByStep = (
  value: number,
  priceStep: {
    from: number;
    to: number;
    price: number;
  }[],
) => {
  const finalPrice =
    priceStep &&
    priceStep
      .map(({ from, to, price }) => {
        const currentPrice = value > to ? to * (price / to) : 0;
        const rangePrice =
          value > from && value <= to ? (value - from) * price : 0;
        return currentPrice + rangePrice;
      })
      .reduce((a, b) => a + b, 0);
  return Math.round(finalPrice * 100) / 100;
};

export const getDiscount = (days: number) => {
  if (days < 10) return 0;
  const discountPercentage = (5 * Math.sqrt(days - 10)) / 100;
  return discountPercentage >= 0.8 ? 0.8 : discountPercentage;
};
