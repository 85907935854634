import { TFunction } from 'react-i18next';
import { isEqual } from 'lodash';

export const getDirtyValues = <FormValues>(
  values: FormValues,
  initialValues: FormValues,
) =>
  Object.keys(values).reduce(
    (acc, key) => {
      if (
        isEqual(
          initialValues[key as keyof FormValues],
          values[key as keyof FormValues],
        )
      ) {
        delete acc[key as keyof FormValues];
      }
      return acc;
    },
    { ...values },
  );

/* eslint-disable no-template-curly-in-string, @typescript-eslint/no-explicit-any */
export const getValidateMessages = (t: TFunction<'translation'>) => {
  const typeTemplate = ((field: string, type: string) =>
    t('form.errors.types', { field, type })) as any;

  return {
    default: ((field: string) => t('form.errors.default', { field })) as any, // as any cause antd validateMessages are wrongly typed...
    required: ((field: string) => t('form.errors.required', { field })) as any,
    enum: "'${name}' must be one of [${enum}]",
    whitespace: ((field: string) =>
      t('form.errors.whitespace', { field })) as any,
    date: {
      format: ((field: string) =>
        t('form.errors.dateFormat', { field })) as any,
      parse: ((field: string) => t('form.errors.dateParse', { field })) as any,
      invalid: ((field: string) =>
        t('form.errors.dateInvalid', { field })) as any,
    },
    types: {
      string: typeTemplate,
      method: typeTemplate,
      array: typeTemplate,
      object: typeTemplate,
      number: typeTemplate,
      date: typeTemplate,
      boolean: typeTemplate,
      integer: typeTemplate,
      float: typeTemplate,
      regexp: typeTemplate,
      email: typeTemplate,
      url: typeTemplate,
      hex: typeTemplate,
    },
    string: {
      len: ((field: string, len: string) =>
        t('form.errors.string.len', { field, len })) as any,
      min: ((field: string, min: string) =>
        t('form.errors.string.min', { field, min })) as any,
      max: ((field: string, max: string) =>
        t('form.errors.string.max', { field, max })) as any,
      range: ((field: string, min: string, max: string) =>
        t('form.errors.string.range', { field, min, max })) as any,
    },
    number: {
      len: ((field: string, len: string) =>
        t('form.errors.number.len', { field, len })) as any,
      min: ((field: string, min: string) =>
        t('form.errors.number.min', { field, min })) as any,
      max: ((field: string, max: string) =>
        t('form.errors.number.max', { field, max })) as any,
      range: ((field: string, min: string, max: string) =>
        t('form.errors.number.range', { field, min, max })) as any,
    },
    array: {
      len: ((field: string, len: string) =>
        t('form.errors.array.len', { field, len })) as any,
      min: ((field: string, min: string) =>
        t('form.errors.array.min', { field, min })) as any,
      max: ((field: string, max: string) =>
        t('form.errors.array.max', { field, max })) as any,
      range: ((field: string, min: string, max: string) =>
        t('form.errors.array.range', { field, min, max })) as any,
    },
    pattern: {
      mismatch: ((field: string, pattern: string) =>
        t('form.errors.pattern.mismatch', { field, pattern })) as any,
    },
  };
};
/* eslint-enable */
