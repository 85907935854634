import React from 'react';
import { Button, Popconfirm, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  GamesGainsFragment,
  useDeleteGameGainMutation,
  useUpdateGameGainMutation,
} from '@shared/api';

import { RowData } from './types';

export const GameGainsAction = ({
  game_id: gameId,
  gain_id: gainId,
  stock,
  probability,
  newStock,
  newProbability,
}: RowData): JSX.Element => {
  const { t } = useTranslation();
  const [updateGameGainMutation] = useUpdateGameGainMutation();
  const [deleteGameGainMutation] = useDeleteGameGainMutation();

  const isUpdated =
    (newStock && stock !== newStock) ||
    (newProbability && probability !== newProbability);

  const handleDeleteGameGain = async () => {
    await deleteGameGainMutation({
      variables: { gameId, gainId },
      update: (cache, { data }) => {
        if (data && data.delete_games_gains_by_pk) {
          cache.modify({
            id: `games:${gameId}`,
            fields: {
              games_gains(existingGamesGainsRefs, { readField }) {
                return existingGamesGainsRefs.filter(
                  (gameGainRef: GamesGainsFragment) =>
                    gainId !== readField('gain_id', gameGainRef),
                );
              },
            },
          });
        }
      },
    });
  };

  const handleStockGameGain = async () => {
    updateGameGainMutation({
      variables: {
        gameId,
        gainId,
        gameGain: { stock: newStock, probability: newProbability },
      },
      update: (cache, { data: dataGain }) => {
        if (dataGain && dataGain.update_games_gains_by_pk) {
          cache.modify({
            id: `games:${gameId}`,
            fields: {
              games_gains(existingGamesGainsRefs, { readField }) {
                return existingGamesGainsRefs.map(
                  (gameGainRef: GamesGainsFragment) => {
                    if (gainId === readField('gain_id', gameGainRef)) {
                      return {
                        ...gameGainRef,
                        ...dataGain?.update_games_gains_by_pk,
                      };
                    }
                    return gameGainRef;
                  },
                );
              },
            },
          });
        }
      },
    });
  };

  return (
    <Space size="middle">
      <Button
        type={isUpdated ? 'primary' : 'text'}
        shape="circle"
        danger={!!isUpdated}
        onClick={handleStockGameGain}
      >
        <EditOutlined />
      </Button>
      <Popconfirm
        placement="bottomRight"
        title={t('common.confirmDelete')}
        onConfirm={handleDeleteGameGain}
        okText={t('common.yes')}
        cancelText={t('common.no')}
      >
        <DeleteOutlined />
      </Popconfirm>
    </Space>
  );
};
