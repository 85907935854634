import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Upload as AntdUpload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadChangeParam } from 'antd/lib/upload';
import { LoadingOutlined } from '@ant-design/icons';

import { Assets, AssetsTypesEnum } from '@shared/api';

import { authService } from 'features/auth';
import { beforeUpload, FileState, getFileState } from 'utils/fileUtils';
import './Upload.less';

type InsideUploadProps = {
  alone: boolean;
  type: AssetsTypesEnum | 'resource';
  fileUrl?: string;
  value?: Assets | FileState;
};

const InsideUpload = ({ type, alone, fileUrl, value }: InsideUploadProps) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react/destructuring-assignment
  if (alone && value && 'id' in value) {
    const src =
      fileUrl ||
      `${process.env.REACT_APP_STATIC_FILES_ENDPOINT}/${value.client_id}${value.path}`;
    if (type === 'image') return <Image src={src} preview={false} />;
    if (type === 'video') return <video src={src} />;
  }
  return <>{t('common.upload')}</>;
};

interface CommonProps {
  type?: AssetsTypesEnum;
  withCrop?: boolean;
  cropAspect?: number;
  showUploadList?: boolean;
  assetId?: undefined;
  qrcodeInside?: boolean;
}
interface AloneProps extends CommonProps {
  alone: true;
  onChange?: (asset: Assets) => void;
  value?: Assets;
}
interface WithLibraryProps extends CommonProps {
  alone: false;
  onChange?: (info: FileState) => void;
  value?: FileState;
}
interface ResourcesProps extends Omit<AloneProps, 'type' | 'assetId'> {
  type: 'resource';
  assetId: string;
}
type Props = AloneProps | WithLibraryProps | ResourcesProps;

export const Upload = ({
  type = AssetsTypesEnum.Image,
  withCrop = false,
  cropAspect = 1 / 1,
  value,
  showUploadList = false,
  assetId,
  qrcodeInside = false,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [fileState, setFileState] = useState<FileState>({});

  const handleChange = (info: UploadChangeParam) => {
    getFileState(info, t).then(s => {
      setFileState(s);
      if (s.asset && props.onChange) {
        if (props.alone) {
          props.onChange(s.asset);
        } else {
          props.onChange(s);
        }
      }
    });
  };

  const action = `${
    process.env.REACT_APP_AUTH_SERVER_ENDPOINT || 'http://localhost:3002'
  }/files/upload/${type}`;
  const { alone } = props;

  let classname: string | undefined;
  if (type === 'resource') classname = 'resource';
  else if (alone) classname = 'alone';

  const UploadComponent = (
    <AntdUpload.Dragger
      action={action}
      data={() => {
        if (type === 'resource') return { assetId };
        if (type === 'image') return { qrcodeInside };
        return undefined;
      }}
      headers={{
        Authorization: `Bearer ${authService.credentials.value?.token}`,
      }}
      showUploadList={showUploadList}
      beforeUpload={file => beforeUpload(file, type, t)}
      onChange={handleChange}
      className={classname}
    >
      {fileState.loading ? (
        <LoadingOutlined />
      ) : (
        <InsideUpload
          alone={alone}
          type={type}
          fileUrl={fileState.fileUrl}
          value={value}
        />
      )}
    </AntdUpload.Dragger>
  );

  if (withCrop) {
    return (
      <ImgCrop
        aspect={cropAspect}
        minZoom={0}
        maxZoom={5}
        quality={1}
        grid
        rotate
        cropperProps={{ restrictPosition: false }}
      >
        {UploadComponent}
      </ImgCrop>
    );
  }

  return UploadComponent;
};
