import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Reference } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  AnimationFragment,
  AnimationM2MAssetFragmentDoc,
  AnimationsObjRelInsertInput,
  AnimationsSetInput,
  ArM2MAnimationFragmentDoc,
  ArTrackersSetInput,
  Assets,
  GoodieM2MAnimationFragmentDoc,
  useDeleteAnimationsM2mAssetsMutation,
  useInsertAnimationsM2mAssetsMutation,
  useInsertArM2mAnimationsMutation,
  useInsertGoodiesM2mAnimationsMutation,
  useInsertTrackerMutation,
  useUpdateAnimationMutation,
  useUpdateAnimationsM2mAssetsMutation,
  useUpdateAssetMutation,
  useUpdateTrackerMutation,
} from '@shared/api';

import { AppForm, AssetInput, SubmitButton } from 'components';
import { useLoggedClient } from 'features/auth';
import {
  getAnimationName,
  getAnimationType,
  getInsidePortalType,
} from 'utils/arUtils';
import { getDirtyValues } from 'utils/formUtils';

export interface Props extends DrawerProps {
  type: 'ar' | 'goodie';
  gameOrGoodieId?: string;
  animation?: AnimationFragment;
  onClose: () => void;
}

const useInsertAnimationMutation = (type: Props['type']) => {
  const [insertArAnimation] = useInsertArM2mAnimationsMutation({
    update: (cache, { data }) => {
      if (data && data.insert_ar_m2m_animations_one) {
        cache.modify({
          id: `ar:${data.insert_ar_m2m_animations_one.ar_id}`,
          fields: {
            animations: existingRefs => {
              const newRef = cache.writeFragment({
                id: `ar_m2m_animations:${data.insert_ar_m2m_animations_one?.ar_id}-${data.insert_ar_m2m_animations_one?.animation_id}`,
                data: data.insert_ar_m2m_animations_one,
                fragment: ArM2MAnimationFragmentDoc,
                fragmentName: 'ArM2MAnimation',
              });
              return [...existingRefs, newRef];
            },
          },
        });
      }
    },
  });
  const [insertGoodieAnimation] = useInsertGoodiesM2mAnimationsMutation({
    update: (cache, { data }) => {
      if (data && data.insert_goodies_m2m_animations_one) {
        cache.modify({
          id: `goodies:${data.insert_goodies_m2m_animations_one.goodie_id}`,
          fields: {
            animations: existingRefs => {
              const newRef = cache.writeFragment({
                id: `goodies_m2m_animations:${data.insert_goodies_m2m_animations_one?.goodie_id}-${data.insert_goodies_m2m_animations_one?.animation_id}`,
                data: data.insert_goodies_m2m_animations_one,
                fragment: GoodieM2MAnimationFragmentDoc,
                fragmentName: 'GoodieM2MAnimation',
              });
              return [...existingRefs, newRef];
            },
          },
        });
      }
    },
  });

  return [
    (id: string, animation: AnimationsObjRelInsertInput) => {
      if (type === 'ar')
        return insertArAnimation({ variables: { ar_id: id, animation } });
      return insertGoodieAnimation({ variables: { goodie_id: id, animation } });
    },
  ];
};

export const AnimationDrawer = ({
  type,
  gameOrGoodieId,
  animation,
  onClose,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { id: cliendId, role } = useLoggedClient();
  const [form] = Form.useForm<AnimationFragment>();
  const [loading, setLoading] = useState(false);

  const [insertAnimation] = useInsertAnimationMutation(type);
  const [addAssets] = useInsertAnimationsM2mAssetsMutation({
    update: (cache, { data }) => {
      if (data && data.insert_animations_m2m_assets) {
        cache.modify({
          id: `animations:${data.insert_animations_m2m_assets.returning[0].animation_id}`,
          fields: {
            assets: existingRefs => {
              const newRefs = data.insert_animations_m2m_assets?.returning.map(
                r =>
                  cache.writeFragment({
                    id: `animations_m2m_assets:${r.animation_id}-${r.asset_id}`,
                    data: r,
                    fragment: AnimationM2MAssetFragmentDoc,
                    fragmentName: 'AnimationM2MAsset',
                  }),
              );
              return [...existingRefs, newRefs];
            },
          },
        });
      }
    },
  });
  const [updateAssetWhenWon] = useUpdateAnimationsM2mAssetsMutation({
    update: (cache, { data }) => {
      if (data && data.update_animations_m2m_assets_by_pk) {
        cache.modify({
          id: `animations_m2m_assets:${data.update_animations_m2m_assets_by_pk.animation_id}-${data.update_animations_m2m_assets_by_pk.asset_id}`,
          fields: {
            when_won: () => data.update_animations_m2m_assets_by_pk?.when_won,
          },
        });
      }
    },
  });
  const [deleteAsset] = useDeleteAnimationsM2mAssetsMutation({
    update: (cache, { data }) => {
      if (data && data.delete_animations_m2m_assets_by_pk) {
        cache.modify({
          id: `animations:${data.delete_animations_m2m_assets_by_pk.animation_id}`,
          fields: {
            assets: (existingRefs, { readField }) =>
              existingRefs.filter(
                (ref: Reference) =>
                  readField('id', readField('asset', ref)) !==
                  data.delete_animations_m2m_assets_by_pk?.asset_id,
              ),
          },
        });
      }
    },
  });

  const [insertTracker] = useInsertTrackerMutation();
  const [updateTracker] = useUpdateTrackerMutation();
  const [updateAsset] = useUpdateAssetMutation();
  const [updateAnimation] = useUpdateAnimationMutation();

  useEffect(() => {
    if (rest.visible) {
      form.resetFields();
    }
  }, [animation]);

  const onFinish = async (values: AnimationFragment) => {
    setLoading(true);
    if (!animation && gameOrGoodieId) {
      await insertAnimation(gameOrGoodieId, {
        data: {
          name: values.name,
          tracker: {
            data: {
              image_id: values.tracker.image.id,
              width: values.tracker.width || 100,
              height: values.tracker.height,
              qrcode_inside: values.tracker.qrcode_inside,
              client_id: role === 'admin' ? cliendId : undefined,
            },
          },
          // portal_id: values.portal?.id,
          assets: {
            data: values.assets.map(a => ({
              asset_id: a.asset.id,
              when_won: a.when_won,
            })),
          },
          client_id: role === 'admin' ? cliendId : undefined,
        },
      });
    } else if (animation) {
      const updatePromises: Array<Promise<unknown>> = [];
      if (values.tracker.id === animation.tracker.id) {
        const trackerUpdates: ArTrackersSetInput & {
          image?: Assets;
        } = getDirtyValues(values.tracker, animation.tracker);

        if (trackerUpdates.image) {
          if (trackerUpdates.image.id !== animation.tracker.image.id)
            trackerUpdates.image_id = trackerUpdates.image.id;
          delete trackerUpdates.image;
        }

        if (trackerUpdates) {
          updatePromises.push(
            updateTracker({
              variables: { id: values.tracker.id, updates: trackerUpdates },
            }),
          );
        }
      }
      // if (values.portal && values.portal.id === animation.portal?.id) {
      //   const portalUpdates = getDirtyValues(values.portal, animation.portal);
      //   if (portalUpdates) {
      //     updatePromises.push(
      //       updateAsset({
      //         variables: {
      //           id: values.portal.id,
      //           updates: portalUpdates,
      //         },
      //       }),
      //     );
      //   }
      // }

      const animationUpdates: AnimationsSetInput = {};
      if (values.name !== getAnimationName(animation))
        animationUpdates.name = values.name;
      if (values.tracker.id !== animation.tracker.id) {
        if (values.tracker.id) {
          animationUpdates.tracker_id = values.tracker.id;
          // We have to await here instead of adding it to the array otherwise it can lead to wrong cache updates while mutating animation
          await updateTracker({
            variables: {
              id: values.tracker.id,
              updates: {
                name: values.tracker.name,
                width: values.tracker.width || 100,
                height: values.tracker.height,
                qrcode_inside: values.tracker.qrcode_inside,
              },
            },
          });
        } else {
          const { data } = await insertTracker({
            variables: {
              object: {
                name: values.tracker.name,
                width: values.tracker.width || 100,
                height: values.tracker.height,
                image_id: values.tracker.image.id,
                qrcode_inside: values.tracker.qrcode_inside,
                client_id: role === 'admin' ? cliendId : undefined,
              },
            },
          });
          if (data?.insert_ar_trackers_one)
            animationUpdates.tracker_id = data.insert_ar_trackers_one.id;
        }
      }
      // if (values.portal?.id !== animation.portal?.id)
      //   animationUpdates.portal_id = values.portal?.id || null;
      if (values.assets !== animation.assets) {
        const orderedNewAssets = [...values.assets].sort((a1, a2) =>
          a1.asset.id.localeCompare(a2.asset.id),
        );
        const orderedOldAssets = [...animation.assets].sort((a1, a2) =>
          a1.asset.id.localeCompare(a2.asset.id),
        );
        const assetsToAdd: typeof animation.assets = [];
        const assetsToRemove: typeof animation.assets = [];
        let i = 0;
        let j = 0;
        while (i < orderedNewAssets.length || j < orderedOldAssets.length) {
          if (
            j === orderedOldAssets.length ||
            (i < orderedNewAssets.length &&
              orderedNewAssets[i].asset.id < orderedOldAssets[j].asset.id)
          ) {
            assetsToAdd.push(orderedNewAssets[i]);
            ++i;
          } else if (
            i === orderedNewAssets.length ||
            orderedNewAssets[i].asset.id > orderedOldAssets[j].asset.id
          ) {
            assetsToRemove.push(orderedOldAssets[j]);
            ++j;
          } else {
            const assetUpdates = getDirtyValues(
              orderedNewAssets[i].asset,
              orderedOldAssets[j].asset,
            );
            if (assetUpdates) {
              updatePromises.push(
                updateAsset({
                  variables: {
                    id: orderedNewAssets[i].asset.id,
                    updates: assetUpdates,
                  },
                }),
              );
            }
            if (orderedNewAssets[i].when_won !== orderedOldAssets[j].when_won) {
              updatePromises.push(
                updateAssetWhenWon({
                  variables: {
                    animation_id: animation.id,
                    asset_id: orderedNewAssets[i].asset.id,
                    when_won: orderedNewAssets[i].when_won,
                  },
                }),
              );
            }
            ++i;
            ++j;
          }
        }

        if (assetsToRemove.length)
          assetsToRemove.forEach(a =>
            updatePromises.push(
              deleteAsset({
                variables: { animation_id: animation.id, asset_id: a.asset.id },
              }),
            ),
          );
        if (assetsToAdd.length)
          updatePromises.push(
            addAssets({
              variables: {
                objects: assetsToAdd.map(a => ({
                  animation_id: animation.id,
                  asset_id: a.asset.id,
                  when_won: a.when_won,
                })),
              },
            }),
          );
      }

      if (animationUpdates) {
        updatePromises.push(
          updateAnimation({
            variables: {
              id: animation.id,
              updates: animationUpdates,
            },
          }),
        );
      }

      await Promise.all(updatePromises);
    }
    onClose();
  };

  const initialValues = {
    name: getAnimationName(animation),
    type: getAnimationType(animation),
    insidePortalType: getInsidePortalType(animation),
    tracker: animation?.tracker,
    // portal: animation?.portal,
    assets: animation?.assets,
  };

  return (
    <Drawer
      width={500}
      title={
        animation
          ? t('ar.updateAnimation', {
              name: getAnimationName(animation),
            })
          : t('ar.createAnimation')
      }
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={initialValues}
        preserve={false}
        onValuesChange={changed => {
          if ('type' in changed || 'insidePortalType' in changed)
            form.setFieldsValue({ assets: [] });
        }}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        style={{ flex: '1 0', display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="name"
            label={t('common.name')}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('ar.form.namePlaceholder')} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => (
              <Row gutter={16}>
                <Col flex={1}>
                  <Form.Item
                    name="type"
                    label={t('ar.type')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        { label: t('ar.assetsTypes.portal'), value: 'portal' },
                        { label: t('ar.assetsTypes.video'), value: 'video' },
                        // {
                        //   label: t('ar.assetsTypes.object3D'),
                        //   value: 'object3D',
                        // },
                      ]}
                      placeholder={t('ar.form.typePlaceholder')}
                    />
                  </Form.Item>
                </Col>
                {getFieldValue('type') === 'portal' && (
                  <Col span={12}>
                    <Form.Item
                      name="insidePortalType"
                      label={t('ar.form.insidePortalType')}
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={[
                          {
                            label: t('ar.assetsTypes.image360'),
                            value: 'image360',
                          },
                          {
                            label: t('ar.assetsTypes.video360'),
                            value: 'video360',
                          },
                        ]}
                        placeholder={t('ar.form.insidePortalTypePlaceholder')}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )}
          </Form.Item>

          <Form.Item
            name="tracker"
            label={t('ar.tracker')}
            tooltip={t('ar.form.trackerHelp')}
            rules={[{ required: true }]}
          >
            <AssetInput type="tracker" />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type ||
              prevValues.insidePortalType !== currentValues.insidePortalType
            }
          >
            {({ getFieldValue }) => (
              <>
                {/* {getFieldValue('type') === 'portal' && (
                  <Form.Item
                    name="portal"
                    label={t('ar.assetsTypes.portal')}
                    rules={[{ required: true }]}
                  >
                    <AssetInput assetType={AssetsTypesEnum.Portal} />
                  </Form.Item>
                )} */}
                <Form.List
                  name="assets"
                  rules={[
                    {
                      validator: async (_, assets) => {
                        if (!assets || !assets.length)
                          return Promise.reject(
                            new Error(
                              t('ar.form.errors.minAssets', { count: 1 }),
                            ),
                          );

                        const assetsIds = assets.map(
                          (a: AnimationFragment['assets'][0]) => a?.asset.id,
                        );
                        if (new Set(assetsIds).size !== assetsIds.length)
                          return Promise.reject(
                            new Error(t('ar.form.errors.duplicateAsset')),
                          );

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => {
                    const label =
                      getFieldValue('type') === 'portal'
                        ? t('ar.assetInsidePortal')
                        : t(`ar.assetsTypes.${getFieldValue('type')}` as any); // eslint-disable-line @typescript-eslint/no-explicit-any
                    return (
                      <>
                        {fields.map(({ name, ...restField }, index) => (
                          <Row key={restField.key} gutter={12}>
                            <Col flex="1">
                              <Form.Item
                                name={[name, 'asset']}
                                label={index === 0 ? label : undefined}
                                {...restField}
                                rules={[{ required: true }]}
                              >
                                <AssetInput
                                  assetType={
                                    getFieldValue('type') === 'portal'
                                      ? getFieldValue('insidePortalType')
                                      : getFieldValue('type')
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(name)}
                                  style={{ fontSize: 16 }}
                                />
                              ) : null}
                              <Space
                                direction="vertical"
                                align="center"
                                size={4}
                                style={{ flex: 1, justifyContent: 'center' }}
                              >
                                <Typography.Text>
                                  {t('ar.whenWon')}
                                </Typography.Text>
                                <Form.Item
                                  name={[name, 'when_won']}
                                  valuePropName="checked"
                                  {...restField}
                                >
                                  <Checkbox />
                                </Form.Item>
                              </Space>
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            block
                          >
                            {t('ar.form.addAsset')}
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </>
            )}
          </Form.Item>
        </FormContainer>
        <SubmitButton
          size="large"
          loading={loading}
          style={{ marginTop: '0.5rem' }}
        />
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;
