import React, { useState, useEffect } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';

import { Assets, PhotoFragment, useUpdatePhotoMutation } from '@shared/api';

import { AppForm, Loader, AssetInput } from 'components';

interface PhotosProps {
  photo: PhotoFragment;
}

type FormValues = {
  foreground?: Assets;
};

export const PhotoForeground = ({ photo }: PhotosProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [updatePhotoMutation] = useUpdatePhotoMutation();

  const onSubmit = async ({ foreground }: FormValues) => {
    setLoading(true);
    if (foreground) {
      await updatePhotoMutation({
        variables: {
          id: photo.id,
          updates: {
            foreground_id: foreground?.id,
          },
        },
      });
    }
  };

  const initialValues = {
    foreground: photo?.foreground,
  };

  useEffect(() => {
    form.resetFields();
  }, [photo]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item name="foreground" label={t('photo.form.foreground')}>
            <AssetInput />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
