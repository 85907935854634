import React from 'react';
import styled from 'styled-components';
import { Image } from 'antd';

export const AuthLayout = ({ children }: ChildrenProps) => (
  <Container>
    <LeftImage src="/images/boost_freq.png" />
    <RightContainer>
      <FormContainer>
        <Logo src="/images/flashplay-logo.png" width="5rem" preview={false} />
        {children}
      </FormContainer>
    </RightContainer>
  </Container>
);

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

const LeftImage = styled.img`
  height: 100%;
  width: auto;
`;

const RightContainer = styled.div`
  flex: 1;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  width: 25rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled(Image)`
  margin-bottom: 4rem;
`;
