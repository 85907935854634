import React, { useEffect, useState } from 'react';
import { Button, Divider, Drawer, Form, Input, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  LocationFragment,
  LocationFragmentDoc,
  useInsertLocationMutation,
  useUpdateLocationMutation,
} from '@shared/api';

import { AppForm, AppFormMap, Loader } from 'components';
import { FormValues as MapFormValues } from 'components/AppFormMap';
import { useLoggedClient } from 'features/auth';

export type Props = {
  visible: boolean;
  location?: LocationFragment;
  onAdd: (location: LocationFragment) => void;
  onClose: () => void;
};

type FormValues = MapFormValues & {
  name: string;
};

export const LocationDrawer = ({
  visible,
  location,
  onAdd,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const [insertLocation] = useInsertLocationMutation({
    onCompleted: ({ insert_locations_one: newLocation }) => {
      if (newLocation) onAdd(newLocation);
    },
    update: (cache, { data }) => {
      if (data && data.insert_locations_one) {
        cache.modify({
          fields: {
            locations: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_locations_one,
                fragment: LocationFragmentDoc,
                fragmentName: 'Location',
              });
              return [...refs, newRef];
            },
          },
        });
      }
    },
  });
  const [updateLocation] = useUpdateLocationMutation();

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    if (location) {
      await updateLocation({
        variables: {
          locationId: location.id,
          location: values,
        },
      });
    } else {
      insertLocation({
        variables: {
          location: {
            ...values,
            client_id: !location && role === 'admin' ? clientId : undefined,
          },
        },
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [location]);

  return (
    <Drawer
      title={
        location
          ? t('locations.update', { name: location.name })
          : t('locations.create')
      }
      width={720}
      onClose={onClose}
      visible={visible}
      forceRender
    >
      <AppForm
        form={form}
        initialValues={location}
        onFinish={values => onSubmit(values).finally(() => setLoading(false))}
      >
        <Form.Item
          label={t('common.name')}
          name="name"
          rules={[{ required: true }]}
        >
          <Input placeholder={t('common.name')} />
        </Form.Item>
        <AppFormMap form={form} />
        <Divider />
        <Row justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {loading ? <Loader /> : t('common.confirm')}
          </Button>
        </Row>
      </AppForm>
    </Drawer>
  );
};
