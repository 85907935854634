import React, { useState } from 'react';
import { Form, Input, Modal, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { supportedLngs } from 'i18n/config';

const { TabPane } = Tabs;

interface Props {
  name: string;
  label?: React.ReactNode;
  alreadyUsedLngs: string[];
}

export const MultipleLngItem = ({ name, label, alreadyUsedLngs }: Props) => {
  const { t } = useTranslation();
  const [descriptionLngs, setDescriptionLngs] = useState(alreadyUsedLngs);
  const [activeKey, setActiveKey] = useState<string>(alreadyUsedLngs[0] || '');

  const availableLngs = supportedLngs.filter(l => !descriptionLngs.includes(l));
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNewLng, setSelectedNewLng] = useState(availableLngs[0]);

  return (
    <>
      <Form.Item label={label}>
        <Tabs
          type="editable-card"
          size="small"
          hideAdd={availableLngs.length === 0}
          activeKey={activeKey}
          onChange={key => setActiveKey(key)}
          onEdit={(tabkey, action) => {
            if (action === 'remove' && typeof tabkey === 'string') {
              const descLngs = descriptionLngs.filter(l => l !== tabkey);
              setDescriptionLngs(descLngs);
              if (tabkey === activeKey)
                setActiveKey(descLngs.length > 0 ? descLngs[0] : '');
            } else if (action === 'add') {
              setSelectedNewLng(availableLngs[0]);
              setModalVisible(true);
            }
          }}
        >
          {descriptionLngs.map(lng => (
            <TabPane tab={lng} key={lng} closable forceRender>
              <Form.Item name={[name, lng]} initialValue="">
                <Input.TextArea rows={4} />
              </Form.Item>
            </TabPane>
          ))}
        </Tabs>
      </Form.Item>
      <Modal
        title={t('language')}
        visible={modalVisible}
        onOk={() => {
          setDescriptionLngs([...descriptionLngs, selectedNewLng]);
          setActiveKey(selectedNewLng);
          setModalVisible(false);
        }}
        onCancel={() => setModalVisible(false)}
      >
        <Select
          value={selectedNewLng}
          onChange={value => setSelectedNewLng(value)}
        >
          {availableLngs.map(lng => (
            <Select.Option key={lng} value={lng}>
              {
                t(`languages.${lng}` as any) // eslint-disable-line @typescript-eslint/no-explicit-any
              }
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};
