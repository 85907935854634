import React from 'react';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';
import 'antd/es/date-picker/style/index';
import set from 'date-fns/set';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

export const AntdRangePicker = generateRangePicker<Date>(dateFnsGenerateConfig);

type Props = Omit<RangePickerProps<Date>, 'showTime'> & {
  showTime?: boolean;
  disabledBeforeDate?: Date;
  disabledAfterDate?: Date;
};

export const DateRangePicker = ({
  showTime = false,
  disabledBeforeDate,
  disabledAfterDate,
  style,
  ...props
}: Props) => (
  <AntdRangePicker
    format={showTime ? 'Pp' : 'P'}
    showTime={
      showTime && {
        format: 'HH:mm',
        defaultValue: [
          set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
          set(new Date(), {
            hours: 23,
            minutes: 59,
            seconds: 59,
            milliseconds: 999,
          }),
        ],
        minuteStep: 15,
        hideDisabledOptions: true,
      }
    }
    disabledDate={
      disabledBeforeDate || disabledAfterDate
        ? date => {
            if (disabledBeforeDate && date < startOfDay(disabledBeforeDate))
              return true;
            if (disabledAfterDate && date > endOfDay(disabledAfterDate))
              return true;
            return false;
          }
        : undefined
    }
    disabledTime={
      showTime && (disabledBeforeDate || disabledAfterDate)
        ? date => {
            if (!date) return {};
            const disabledHours: number[] = [];
            let hoursBefore: number[] = [];
            let hoursAfter: number[] = [];
            if (disabledBeforeDate && isSameDay(date, disabledBeforeDate)) {
              hoursBefore = Array.from(
                Array(disabledBeforeDate.getHours()).keys(),
              );
              disabledHours.push(...hoursBefore);
            }
            if (disabledAfterDate && isSameDay(date, disabledAfterDate)) {
              hoursAfter = Array.from(
                Array(23 - disabledAfterDate.getHours()).keys(),
              ).map(h => 23 - h);
              disabledHours.push(...hoursAfter);
            }

            return {
              disabledHours: () => disabledHours,
              disabledMinutes: (selectedHour: number) => {
                const disabledMinutes: number[] = [];
                if (
                  disabledBeforeDate &&
                  isSameDay(date, disabledBeforeDate) &&
                  disabledBeforeDate.getHours() === selectedHour
                )
                  disabledMinutes.push(
                    ...Array.from(
                      Array(disabledBeforeDate.getMinutes()).keys(),
                    ),
                  );
                if (
                  disabledAfterDate &&
                  isSameDay(date, disabledAfterDate) &&
                  disabledAfterDate.getHours() === selectedHour
                )
                  disabledMinutes.push(
                    ...Array.from(
                      Array(59 - disabledAfterDate.getMinutes()).keys(),
                    ).map(m => 59 - m),
                  );
                return disabledMinutes;
              },
            };
          }
        : undefined
    }
    style={style}
    {...props}
  />
);
