import React from 'react';
import { useTranslation } from 'react-i18next';

import { EventLocationFragment } from '@shared/api';

interface Props {
  locationsIds: string[];
  eventLocations: EventLocationFragment[];
}

export const LocationsExpansion = ({ locationsIds, eventLocations }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <b>{t('common.location', { count: locationsIds.length })}: </b>
      {eventLocations.reduce((acc, cur) => {
        if (!locationsIds.includes(cur.location_id)) return acc;
        if (acc === '') return cur.location.name;
        return `${acc}, ${cur.location.name}`;
      }, '')}
    </>
  );
};
