import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  Form,
  Popover,
  Radio,
  Space,
  Typography,
  Row,
} from 'antd';
import { SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  useInsertGainsVirtualsMutation,
  useGetGainsVirtualsByGainQuery,
} from '@shared/api';

import { AppForm, Loader, Upload } from 'components';
import { GainData } from '../types';
import { GainsFormVirtualGainGenerate } from './VirtualGainGenerate';

const { Paragraph } = Typography;

type FormValues = {
  // eslint-disable-next-line camelcase
  virtual_format: string;
  // eslint-disable-next-line camelcase
  virtual_type: string;
  codes: string;
};

interface GainsFormVirtualGainProps {
  gain?: GainData;
}

const GainsFormVirtualGain = ({ gain }: GainsFormVirtualGainProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const { data } = useGetGainsVirtualsByGainQuery({
    variables: { gainId: gain?.id || '' },
  });
  const [insertGainsVirtualsMutation] = useInsertGainsVirtualsMutation();
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [virtual, setVirtual] = useState<{
    format?: string;
    type?: string;
    generatedCodes?: string[];
  }>({
    format: 'code',
    type: 'from_list',
    generatedCodes: [],
  });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const { codes } = values;
    if (gain && codes) {
      const virtualsGains = codes.split('\n').map((code: string) => ({
        format: virtual.format,
        value: code,
        gain_id: gain.id,
      }));
      await insertGainsVirtualsMutation({
        variables: {
          gainId: gain.id,
          gainsVirtuals: virtualsGains,
        },
        update: (cache, { data: dataGainVirtual }) => {
          if (dataGainVirtual && dataGainVirtual.insert_gains_virtuals) {
            cache.modify({
              fields: {
                gains_virtuals: () =>
                  dataGainVirtual.insert_gains_virtuals?.returning,
              },
            });
          }
        },
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [virtual]);

  useEffect(() => {
    if (data && data.gains_virtuals.length > 0) {
      setVirtual({
        format: data?.gains_virtuals[0].format,
        type: data?.gains_virtuals?.length > 1 ? 'from_list' : 'unique',
        generatedCodes: data?.gains_virtuals.map(({ value }) => value),
      });
    }
  }, [data]);

  const initialValues = {
    codes: virtual?.generatedCodes?.join('\n'),
    virtual_format: virtual?.format,
    virtual_type: virtual?.type,
  };

  return (
    <>
      <AppForm
        form={form}
        initialValues={initialValues}
        onFinish={values => onSubmit(values).finally(() => setLoading(false))}
      >
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item label={t('gains.form.format')} name="virtual_format">
              <Radio.Group
                onChange={event =>
                  setVirtual({
                    ...virtual,
                    generatedCodes: [],
                    format: event.target.value,
                  })
                }
                defaultValue={virtual.format}
              >
                <Radio value="picture">{t('gains.form.formatImage')}</Radio>
                <Radio value="code">{t('gains.form.formatCode')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t('gains.form.type')} name="virtual_type">
              <Radio.Group
                onChange={event =>
                  setVirtual({
                    ...virtual,
                    generatedCodes: [],
                    type: event.target.value,
                  })
                }
                defaultValue={virtual.type}
                disabled={virtual.format === 'picture'}
              >
                <Radio value="unique">{t('gains.form.typeUnique')}</Radio>
                <Radio value="from_list">{t('gains.form.typeList')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            {virtual.format === 'code' ? (
              <>
                <Form.Item
                  label={
                    <Row style={{ width: '100%' }}>
                      <Col flex={1}>
                        {virtual.type === 'unique'
                          ? t('gains.form.code')
                          : t('gains.form.codeList')}
                      </Col>
                      <Col>
                        <Popover
                          placement="bottomRight"
                          trigger="click"
                          visible={popoverVisible}
                          onVisibleChange={() =>
                            setPopoverVisible(!popoverVisible)
                          }
                          content={
                            <Space direction="vertical" size="small">
                              <Button type="text">Importer</Button>
                              <Button type="text">Exporter</Button>
                              <Button
                                onClick={() => {
                                  setPopoverVisible(false);
                                  setDrawerVisible(true);
                                }}
                                type="text"
                              >
                                {t('common.generate')}
                              </Button>
                            </Space>
                          }
                        >
                          <SettingOutlined />
                        </Popover>
                      </Col>
                    </Row>
                  }
                  name="codes"
                  rules={[{ required: true }]}
                >
                  {virtual.type === 'unique' ? (
                    <Input />
                  ) : (
                    <Input.TextArea rows={8} />
                  )}
                </Form.Item>
                {virtual.type === 'from_list' ? (
                  <Paragraph disabled>{t('gains.form.codeListHelp')}</Paragraph>
                ) : null}
              </>
            ) : (
              <Form.Item name="image" label={t('gains.form.image')}>
                <Upload alone />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {loading ? <Loader /> : t('common.confirm')}
          </Button>
        </Row>
      </AppForm>
      <Drawer
        title="Generate"
        width={420}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <GainsFormVirtualGainGenerate
          onGeneratedCodes={codes =>
            setVirtual({ ...virtual, generatedCodes: codes })
          }
          virtualType={virtual.type}
        />
      </Drawer>
    </>
  );
};

export { GainsFormVirtualGain };
