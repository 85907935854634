import React, { useState } from 'react';
import { Button, Col, Row, Select, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  useUpdateGameMutation,
  useUpdateCommunicationMutation,
  useGetGamesCommunicationsMinimalInfosQuery,
  useInsertPrivateDeckM2mEntityMutation,
  PrivateDeckM2mEntitiesFragment,
  useDeletePrivateDeckM2mEntitiesByPkMutation,
} from '@shared/api';

import { DeleteAction, Loader } from 'components';
import { useLoggedClient } from 'features/auth';
import { PrivateDeckData, RowData } from '../types';

interface GamesFormInstructionsProps {
  privateDeck: PrivateDeckData;
}

export const PrivatesDecksFormEntities = ({
  privateDeck,
}: GamesFormInstructionsProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [updateGameMutation] = useUpdateGameMutation();
  const [updateCommunicationMutation] = useUpdateCommunicationMutation();
  const [
    deletePrivateDeckM2mEntities,
  ] = useDeletePrivateDeckM2mEntitiesByPkMutation();
  const { data, loading } = useGetGamesCommunicationsMinimalInfosQuery({
    variables: { clientId: role === 'admin' ? clientId : undefined },
  });
  const [
    insertPrivateDeckM2mEntityMutation,
  ] = useInsertPrivateDeckM2mEntityMutation({
    update: (cache, { data: dataDeck }) => {
      if (
        privateDeck &&
        dataDeck &&
        dataDeck.insert_privates_decks_m2m_entities_one
      ) {
        cache.modify({
          id: `privates_decks:${privateDeck.id}`,
          fields: {
            privates_decks_m2m_entities: privatesDecksM2mEntitiesRefs => [
              ...privatesDecksM2mEntitiesRefs,
              dataDeck.insert_privates_decks_m2m_entities_one,
            ],
          },
        });
      }
    },
  });

  const tableData: RowData[] = (privateDeck.privates_decks_m2m_entities.map(
    ({ game, communication }: PrivateDeckM2mEntitiesFragment) =>
      game || communication,
  ) || []) as RowData[];

  const sortedData = data
    ? [...data.games, ...data.communications].sort((g1, g2) => {
        if (g1.type.name === g2.type.name) return 0;
        if (g1.type.name < g2.type.name) return -1;
        return 1;
      })
    : [];

  const groupedData = sortedData.reduce((acc, entity) => {
    if (!acc[entity.type.name]) {
      acc[entity.type.name] = [];
    }
    acc[entity.type.name].push({ id: entity.id, name: entity.name });
    return acc;
  }, {} as { [typeName: string]: Array<{ id: string; name: string }> });

  const handleNewEntity = async () => {
    if (privateDeck && selectedEntityId) {
      await insertPrivateDeckM2mEntityMutation({
        variables: {
          privateDeckId: privateDeck.id,
          entityId: selectedEntityId,
        },
      });
    }
  };

  const columns: ColumnsType<RowData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('table.type'),
      key: 'type',
      render: entity => entity.type.name.toUpperCase(),
    },
    {
      title: t('table.private'),
      dataIndex: 'is_private',
      key: 'private',
      render: (isPrivate, entity) => (
        <Switch
          checked={isPrivate}
          size="small"
          onClick={(_, ev) => {
            ev.stopPropagation();
            // eslint-disable-next-line no-underscore-dangle
            if (entity.__typename === 'games') {
              updateGameMutation({
                variables: {
                  gameId: entity.id,
                  game: { is_private: !isPrivate },
                },
              });
            }
            // eslint-disable-next-line no-underscore-dangle
            if (entity.__typename === 'communications') {
              updateCommunicationMutation({
                variables: {
                  communicationId: entity.id,
                  communication: { is_private: !isPrivate },
                },
              });
            }
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: entity => (
        <DeleteAction
          onDelete={() => {
            deletePrivateDeckM2mEntities({
              variables: {
                privateDeckId: privateDeck.id,
                entityId: entity.id,
              },
              update: (cache, { data: dataPrivatesDecksM2mEntities }) => {
                if (
                  dataPrivatesDecksM2mEntities &&
                  dataPrivatesDecksM2mEntities.delete_privates_decks_m2m_entities_by_pk
                ) {
                  cache.modify({
                    id: cache.identify(privateDeck),
                    fields: {
                      privates_decks_m2m_entities: (
                        existingRefs,
                        { readField },
                      ) =>
                        existingRefs.filter(
                          (ref: any) =>
                            readField('entity_id', ref) !== entity.id,
                        ),
                    },
                  });
                }
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                delete_privates_decks_m2m_entities_by_pk: {
                  __typename: 'privates_decks_m2m_entities',
                  private_deck: privateDeck.id,
                  entity_id: entity.id,
                },
              },
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={20}>
          <Select
            loading={loading}
            onChange={(value: string) => setSelectedEntityId(value)}
            style={{ width: '100%' }}
          >
            {Object.entries(groupedData).map(([typeName, entities]) => (
              <Select.OptGroup key={typeName} label={typeName}>
                {entities
                  .filter(
                    (x: { id: string; name: string }) =>
                      tableData &&
                      !tableData.filter((y: RowData) => y.id === x.id).length,
                  )
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id} typeName={typeName}>
                      {name}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            style={{ width: '100%' }}
            onClick={handleNewEntity}
          >
            {loading ? <Loader /> : t('common.add')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table size="small" columns={columns} dataSource={tableData} />
        </Col>
      </Row>
    </>
  );
};
