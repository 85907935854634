import { createContext, useContext, useEffect, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import jwt from 'jsonwebtoken';

import {
  ClientDisplayFragment,
  ClientLoginBody,
  ClientSignupBody,
  ClientsRolesEnum,
  ClientTokenPayload,
  fetchJson,
  ResellerLoginAsClientBody,
  useGetClientByIdLazyQuery,
} from '@shared/api';

import { authApi } from './authApi';

const JWT_STORAGE_KEY = 'HASURA_JWT';
const BLANK_CLIENT: ClientDisplayFragment = {
  id: '',
  email: '',
  name: '',
  role: ClientsRolesEnum.Client,
  is_active: true,
  created_at: '',
  picture: undefined,
};

type Credentials = {
  token: string;
  client: ClientDisplayFragment;
};

const isTokenValid = (token: string) => {
  const payload = jwt.decode(token);
  if (
    !payload ||
    typeof payload === 'string' ||
    Date.now() >= payload.exp * 1000
  ) {
    return false;
  }
  return true;
};

const getTokenFromStorage = () => {
  const token = localStorage.getItem(JWT_STORAGE_KEY);
  if (!token) return null;
  if (!isTokenValid(token)) {
    localStorage.removeItem(JWT_STORAGE_KEY);
    return null;
  }
  return token;
};

const getIdAndRoleFromToken = (token: string | null) => {
  if (!token) return null;
  const tokenPayload = jwt.decode(token) as ClientTokenPayload | null;
  if (!tokenPayload) return null;
  return {
    id: tokenPayload['https://hasura.io/jwt/claims']['x-hasura-user-id'],
    role: tokenPayload['https://hasura.io/jwt/claims']['x-hasura-default-role'],
  };
};

const getCredentialsFromStorage = () => {
  const token = getTokenFromStorage();
  if (!token) return null;
  return {
    token,
    client: { ...BLANK_CLIENT, ...getIdAndRoleFromToken(token) },
  } as Credentials;
};

export const authService = {
  oldCrendentials: undefined as Credentials | undefined,
  credentials: new BehaviorSubject(getCredentialsFromStorage()),

  login(body: ClientLoginBody) {
    return authApi.login(body).then(credentials => {
      this.credentials.next(credentials);
      localStorage.setItem(JWT_STORAGE_KEY, credentials.token);
    });
  },

  loginAsClient(body: ResellerLoginAsClientBody) {
    if (!this.credentials.value) throw new Error('Must be authenticated');
    this.oldCrendentials = this.credentials.value;
    return authApi
      .loginAsClient(body, this.credentials.value.token)
      .then(credentials => {
        this.credentials.next(credentials);
      });
  },

  signup(body: ClientSignupBody) {
    return authApi.signup(body).then(credentials => {
      this.credentials.next(credentials);
      localStorage.setItem(JWT_STORAGE_KEY, credentials.token);
    });
  },

  logout() {
    this.oldCrendentials = undefined;
    this.credentials.next(null);
    localStorage.removeItem(JWT_STORAGE_KEY);
  },

  logoutFromClient() {
    if (this.oldCrendentials) {
      this.credentials.next(this.oldCrendentials);
      this.oldCrendentials = undefined;
    }
  },

  fetchJson<T>(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: { [key: string]: unknown } | FormData,
  ) {
    return fetchJson<T>(url, method, body, this.credentials.value?.token);
  },
};

export const useAuth = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  const [credentials, setCredentials] = useState(authService.credentials.value);
  const [getClientById] = useGetClientByIdLazyQuery({
    client: apolloClient,
    onCompleted: client => {
      if (credentials && client.clients_by_pk) {
        setCredentials({
          token: credentials.token,
          client: client.clients_by_pk,
        });
      }
    },
  });

  useEffect(() => {
    if (credentials?.client.created_at === '') {
      getClientById({ variables: { id: credentials.client.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  useEffect(() => {
    const subscription = authService.credentials.pipe(skip(1)).subscribe({
      next: async value => {
        if (!value) await apolloClient.clearStore();
        else if (credentials) await apolloClient.resetStore();
        setCredentials(value);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return credentials;
};

export const LoggedClientContext = createContext(BLANK_CLIENT);
export const useLoggedClient = () => useContext(LoggedClientContext);
