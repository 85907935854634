import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from '@shared/react-i18n/en/translation.json';
import commonFr from '@shared/react-i18n/fr/translation.json';
import commonEs from '@shared/react-i18n/es/translation.json';
import commonIt from '@shared/react-i18n/it/translation.json';
import commonHu from '@shared/react-i18n/hu/translation.json';
import commonRu from '@shared/react-i18n/ru/translation.json';
import commonDe from '@shared/react-i18n/de/translation.json';
import commonCa from '@shared/react-i18n/ca/translation.json';
import commonDa from '@shared/react-i18n/da/translation.json';
import commonPt from '@shared/react-i18n/pt/translation.json';
import commonEu from '@shared/react-i18n/eu/translation.json';
import adminEn from './en/translation.json';
import adminFr from './fr/translation.json';
import adminEs from './es/translation.json';
import adminIt from './it/translation.json';
import adminHu from './hu/translation.json';
import adminRu from './ru/translation.json';
import adminDe from './de/translation.json';
import adminCa from './ca/translation.json';
import adminDa from './da/translation.json';
import adminPt from './pt/translation.json';
import adminEu from './eu/translation.json';

export const resources = {
  en: {
    translation: {
      ...commonEn,
      ...adminEn,
    },
  },
  fr: {
    translation: {
      ...commonFr,
      ...adminFr,
    },
  },
  es: {
    translation: {
      ...commonEs,
      ...adminEs,
    },
  },
  it: {
    translation: {
      ...commonIt,
      ...adminIt,
    },
  },
  hu: {
    translation: {
      ...commonHu,
      ...adminHu,
    },
  },
  ru: {
    translation: {
      ...commonRu,
      ...adminRu,
    },
  },
  de: {
    translation: {
      ...commonDe,
      ...adminDe,
    },
  },
  ca: {
    translation: {
      ...commonCa,
      ...adminCa,
    },
  },
  da: {
    translation: {
      ...commonDa,
      ...adminDa,
    },
  },
  pt: {
    translation: {
      ...commonPt,
      ...adminPt,
    },
  },
  eu: {
    translation: {
      ...commonEu,
      ...adminEu,
    },
  },
} as const;

export const supportedLngs = Object.keys(resources);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs,
    fallbackLng: 'en',
    resources,
    detection: {
      order: ['path', 'navigator'],
    },
    interpolation: {
      format: (value, format) => {
        if (format === 'lowercase') return value.toLowerCase();
        return value;
      },
    },
  });
