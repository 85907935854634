import {
  fetchJson,
  ClientForgottenPasswordBody,
  ClientLoginBody,
  ClientLoginResponse,
  ClientSignupBody,
  ClientSignupResponse,
  ResetPasswordBody,
  ResellerLoginAsClientBody,
  ResellerLoginAsClientResponse,
} from '@shared/api';

const AUTH_SERVER_URL = `${
  process.env.REACT_APP_AUTH_SERVER_ENDPOINT || 'http://localhost:3002'
}/clients`;

export const authApi = {
  login: (body: ClientLoginBody) =>
    fetchJson<ClientLoginResponse>(`${AUTH_SERVER_URL}/login`, 'POST', body),
  loginAsClient: (body: ResellerLoginAsClientBody, token: string) =>
    fetchJson<ResellerLoginAsClientResponse>(
      `${AUTH_SERVER_URL}/login-as-client`,
      'POST',
      body,
      token,
    ),
  signup: (body: ClientSignupBody) =>
    fetchJson<ClientSignupResponse>(`${AUTH_SERVER_URL}/signup`, 'POST', body),
  forgottenPassword: (body: ClientForgottenPasswordBody) =>
    fetchJson<void>(`${AUTH_SERVER_URL}/forgotten-password`, 'POST', body),
  resetPassword: (body: ResetPasswordBody) =>
    fetchJson<void>(`${AUTH_SERVER_URL}/reset-password`, 'POST', body),
};
