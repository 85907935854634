import React, { useEffect, useState } from 'react';
import { Col, Divider, Form, Input, Typography, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { useUpdateGameMutation } from '@shared/api';

import { AppForm, SubmitButton } from 'components';
import { GameData } from '../types';

const { Paragraph } = Typography;

type FormValues = {
  gtc: string;
  instructions: string;
};

interface Props {
  game: GameData;
}

export const GameFormInfos = ({ game }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const [updateGameMutation] = useUpdateGameMutation();

  useEffect(() => {
    form.resetFields();
  }, [game]);

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    if (game) {
      await updateGameMutation({
        variables: {
          gameId: game.id,
          game: values,
        },
      });
    }
  };

  const initialValues = {
    gtc: game?.gtc || '',
    instructions: game?.instructions || '',
  };

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item label={t('games.form.instructions')} name="instructions">
            <Input.TextArea rows={6} />
          </Form.Item>
          <Paragraph disabled>{t('games.form.instructionsHelp')}</Paragraph>
          <Form.Item label={t('games.form.gtc')} name="gtc">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Paragraph disabled>{t('games.form.gtcHelp')}</Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <SubmitButton loading={loading} />
      </Row>
    </AppForm>
  );
};
