import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Modal, ModalProps, Tabs } from 'antd';

import {
  AssetsTypesEnum,
  useGetAssetsQuery,
  useGetTrackersLazyQuery,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import {
  isTracker,
  LibraryContent,
  LibraryContentType,
} from 'utils/assetUtils';
import { AssetsList } from '../assets';
import { LibrarySider } from './LibrarySider';
import './Library.less';

const { Content } = Layout;
const { TabPane } = Tabs;

interface Props extends ModalProps {
  type?: LibraryContentType;
  assetType?: AssetsTypesEnum;
  onSelect: (content: LibraryContent) => void;
}

export const Library = ({
  type = 'asset',
  assetType,
  onSelect,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { id: clientId } = useLoggedClient();
  const [selectedContent, setSelectedContent] = useState<LibraryContent>();

  const { data, loading, error } = useGetAssetsQuery({
    variables: { type: assetType, clientId },
  });
  const [
    getTrackers,
    { data: trackersData, loading: trackersLoading },
  ] = useGetTrackersLazyQuery({
    variables: { clientId },
  });

  useEffect(() => {
    if (selectedContent && !isTracker(selectedContent))
      setSelectedContent(data?.assets.find(a => a.id === selectedContent.id));
  }, [data]);
  useEffect(() => {
    if (selectedContent && isTracker(selectedContent))
      setSelectedContent(
        trackersData?.ar_trackers.find(a => a.id === selectedContent.id),
      );
  }, [trackersData]);

  useEffect(() => {
    if (type === 'tracker') getTrackers();
  }, [type]);

  return (
    <Modal
      title={t('library.menuTitle')}
      footer={null}
      className="library"
      width="75%"
      {...rest}
    >
      {error ? (
        error.message
      ) : (
        <Layout>
          <Content>
            {type === 'tracker' ? (
              <Tabs defaultActiveKey="1">
                <TabPane tab={t('ar.tracker')} key="1">
                  <AssetsList
                    loading={trackersLoading}
                    contents={trackersData && trackersData.ar_trackers}
                    selectedId={selectedContent?.id}
                    onSelect={content => setSelectedContent(content)}
                  />
                </TabPane>
                <TabPane tab={t('ar.assetsTypes.image')} key="2">
                  <AssetsList
                    loading={loading}
                    contents={data && data.assets}
                    selectedId={selectedContent?.id}
                    onSelect={content => setSelectedContent(content)}
                  />
                </TabPane>
              </Tabs>
            ) : (
              <AssetsList
                loading={loading}
                contents={data && data.assets}
                selectedId={selectedContent?.id}
                onSelect={content => setSelectedContent(content)}
              />
            )}
          </Content>

          <LibrarySider content={selectedContent} onSelect={onSelect} />
        </Layout>
      )}
    </Modal>
  );
};
