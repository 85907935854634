import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import styled from 'styled-components';

import { displayAssetType, isTracker, LibraryContent } from 'utils/assetUtils';
import { Asset } from './Asset';

const { Meta } = Card;

interface Props {
  content: LibraryContent;
  selected: boolean;
}
export const AssetCard = ({ content, selected }: Props) => {
  const { t } = useTranslation();

  const asset = isTracker(content) ? content.image : content;
  const name = isTracker(content)
    ? content.name || content.image.name
    : content.name;
  const type = isTracker(content)
    ? t('ar.tracker')
    : displayAssetType(asset, t);

  return (
    <StyledCard
      cover={<Asset asset={asset} />}
      hoverable={!selected}
      selected={selected}
    >
      <Meta title={name} description={type} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)<{ selected: boolean }>`
  ${props =>
    props.selected && {
      border: '1px solid #e35d3b',
      boxShadow:
        '0 1px 2px -2px rgb(227 93 59 / 100%), 0 3px 6px 0 rgb(227 93 59 / 65%), 0 5px 12px 4px rgb(227 93 59 / 20%)',
    }}
  .ant-card-cover {
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: 75%; /* initial ratio of 4:3*/
    }

    & > .ant-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      & > img,
      video {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0;
  }
`;
