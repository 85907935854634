import React, { useEffect, useState } from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  useGetArSpecificsLazyQuery,
  useGetQuizzSpecificsLazyQuery,
  useGetVoteSpecificsLazyQuery,
  useGetPredictionSpecificsLazyQuery,
} from '@shared/api';

import { AnimationsTab } from 'components/animations';
import { RandomDrawing } from 'features/random-drawing';
import { isAr } from 'utils/arUtils';
import { isQuizz } from 'utils/quizzUtils';
import { isVote } from 'utils/voteUtils';
import { isPrediction } from 'utils/predictionUtils';
import { Prediction } from 'features/predictions/Predictions';
import { GameGains } from '../games-gains/GameGains';
import { Quizz } from '../quizz/Quizz';
import { Vote } from '../votes/Votes';
import { GameData, GameTypeData } from './types';
import { GameFormSetup } from './Form/Setup';
import { GameFormInfos } from './Form/Infos';
import { GameWinners } from './GameWinners';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  game?: GameData;
  gameType: GameTypeData;
  onAdd: (game: GameData) => void;
  onClose: () => void;
};

export const GameDrawer = ({
  visible,
  game,
  gameType,
  onAdd,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [tabActiveKey, setTabActiveKey] = useState('1');
  // AR GAME
  const [
    getArSpecifics,
    { data: arData, loading: arLoading },
  ] = useGetArSpecificsLazyQuery();
  // QUIZZ GAME
  const [
    getQuizzSpecifics,
    { data: quizzQuestionData, loading: quizzLoading },
  ] = useGetQuizzSpecificsLazyQuery();
  // VOTE GAME
  const [
    getVoteSelections,
    { data: voteData, loading: voteLoading },
  ] = useGetVoteSpecificsLazyQuery();
  // PREDICTION GAME
  const [
    getPredictionEvents,
    { data: predictionData, loading: predictionLoading },
  ] = useGetPredictionSpecificsLazyQuery();

  useEffect(() => {
    if (game && isAr(game)) getArSpecifics({ variables: { id: game.id } });
    if (game && isQuizz(game))
      getQuizzSpecifics({ variables: { id: game.id } });
    if (game && isVote(game)) getVoteSelections({ variables: { id: game.id } });
    if (game && isPrediction(game))
      getPredictionEvents({ variables: { id: game.id } });
  }, [game]);

  // Reset active key on close drawer
  useEffect(() => {
    setTabActiveKey('1');
  }, [visible]);

  return (
    <>
      <Drawer
        title={
          game
            ? t('games.updateGame', {
                name: game?.name,
              })
            : t('games.createGame')
        }
        width={720}
        onClose={onClose}
        visible={visible}
        destroyOnClose
      >
        <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey}>
          <TabPane tab={t('games.setup')} key="1">
            <GameFormSetup game={game} gameType={gameType} onAdd={onAdd} />
          </TabPane>
          {game ? (
            <>
              <TabPane tab={t('games.infos')} key="3">
                <GameFormInfos game={game} />
              </TabPane>
              {game.with_instant_gains ? (
                <TabPane tab={t('games.gains')} key="4">
                  <GameGains game={game} />
                </TabPane>
              ) : null}
              {isAr(game) && (
                <TabPane tab={t('games.trackers')} key="5">
                  <AnimationsTab
                    type="ar"
                    data={{
                      ...game,
                      animations: arData?.ar_by_pk?.animations || [],
                    }}
                    loading={arLoading}
                  />
                </TabPane>
              )}
              {isQuizz(game) && quizzQuestionData?.quizz_by_pk && (
                <TabPane tab={t('games.questions')} key="5">
                  <Quizz
                    quizz={{
                      ...game,
                      winning_percentage:
                        quizzQuestionData?.quizz_by_pk?.winning_percentage,
                      ordered_questions:
                        quizzQuestionData?.quizz_by_pk?.ordered_questions,
                      quizz_questions:
                        quizzQuestionData?.quizz_by_pk?.quizz_questions || [],
                      __typename: 'quizz',
                    }}
                    loading={quizzLoading}
                  />
                </TabPane>
              )}
              {isVote(game) && (
                <TabPane tab={t('games.selections')} key="5">
                  <Vote
                    vote={{
                      ...game,
                      __typename: 'votes',
                      votes_selections:
                        voteData?.votes_by_pk?.votes_selections || [],
                    }}
                    loading={voteLoading}
                  />
                </TabPane>
              )}
              {isPrediction(game) && (
                <TabPane tab={t('games.events')} key="5">
                  <Prediction
                    prediction={{
                      ...game,
                      __typename: 'predictions',
                      predictions_events:
                        predictionData?.predictions_by_pk?.predictions_events ||
                        [],
                    }}
                    loading={predictionLoading}
                  />
                </TabPane>
              )}
              {game.with_random_drawings ? (
                <TabPane tab={t('randomDrawing.menuTitle')} key="6">
                  <RandomDrawing game={game} />
                </TabPane>
              ) : null}
              {game.with_instant_gains ? (
                <TabPane tab={t('games.winners')} key="7">
                  <GameWinners gameId={game.id} />
                </TabPane>
              ) : null}
            </>
          ) : null}
        </Tabs>
      </Drawer>
    </>
  );
};
