import React from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { EstimateFormSetup } from './Form/Setup';
import { EstimateFormEntities } from './Form/Entities';
import { EstimateData } from './types';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  onClose: () => void;
  onCreate: (estimate: EstimateData) => void;
  estimate?: EstimateData;
};

const EstimateDrawer = ({ visible, onClose, onCreate, estimate }: Props) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={
        estimate
          ? t('estimates.updateEstimate', {
              name: estimate?.name,
            })
          : t('estimates.createEstimate')
      }
      width={920}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Tabs defaultActiveKey={estimate ? '2' : '1'}>
        <TabPane tab={t('estimates.setup')} key="1">
          <EstimateFormSetup estimate={estimate} onCreate={onCreate} />
        </TabPane>
        {estimate ? (
          <TabPane tab={t('estimates.simulation')} key="2">
            <EstimateFormEntities estimate={estimate} />
          </TabPane>
        ) : null}
      </Tabs>
    </Drawer>
  );
};

export { EstimateDrawer };
