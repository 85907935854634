import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { RangeValue } from 'rc-picker/lib/interface';

import {
  EventFragment,
  EventGameFragment,
  EventGameFragmentDoc,
  GameFragment,
  useDeleteEventGameMutation,
  useGetGamesQuery,
  useInsertEventGameMutation,
} from '@shared/api';

import { DateRangePicker, DeleteAction, SubmitButton } from 'components';
import { formatDate } from 'utils/dateUtils';
import { onApolloError } from 'utils/errorUtils';
import { stringifyArrayForHasura } from 'utils/hasuraUtils';
import { LocationsExpansion } from './LocationsExpansion';

const { OptGroup, Option } = Select;

interface Props {
  event: EventFragment;
  updateDisabled: boolean;
  updateDisabledReason: string;
}

export const EventGames = ({
  event,
  updateDisabled,
  updateDisabledReason,
}: Props) => {
  const { i18n, t } = useTranslation();
  const [selectedGameId, setSelectedGameId] = useState<string | undefined>(
    undefined,
  );
  const eventDates: [Date, Date] = [
    new Date(event.date_start),
    new Date(event.date_end),
  ];
  const [dateRange, setDateRange] = useState<RangeValue<Date>>(eventDates);
  const [locationsIds, setLocationsIds] = useState<string[]>([]);

  const { data: gamesData, loading: gamesLoading } = useGetGamesQuery({
    variables: {
      where: {
        _and: [{ is_archive: { _eq: false }, is_active: { _eq: true } }],
      },
    },
  });

  const [
    insertEventGame,
    { loading: insertLoading },
  ] = useInsertEventGameMutation({
    update: (cache, { data }) => {
      if (data && data.insert_events_m2m_games_one) {
        const { game_id: gameId } = data.insert_events_m2m_games_one;
        cache.modify({
          id: cache.identify(event),
          fields: {
            games: existingRefs => {
              const newRef = cache.writeFragment({
                id: `events_m2m_games:${event.id}-${gameId}`,
                fragment: EventGameFragmentDoc,
                fragmentName: 'EventGame',
                data: data.insert_events_m2m_games_one,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: () => {
      setSelectedGameId(undefined);
      setDateRange(eventDates);
      setLocationsIds([]);
    },
    onError: onApolloError,
  });
  const [deleteEventGame] = useDeleteEventGameMutation({
    update: (cache, { data }) => {
      if (data && data.delete_events_m2m_games_by_pk) {
        const { game_id: gameId } = data.delete_events_m2m_games_by_pk;
        cache.modify({
          id: cache.identify(event),
          fields: {
            games: (existingRefs, { readField }) =>
              existingRefs.filter(
                (ref: any) =>
                  readField('id', readField('game', ref)) !== gameId,
              ),
          },
        });
      }
    },
    onError: onApolloError,
  });

  const gamesIds = event.games.map(g => g.game!.id); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const gamesGroupedByType = useMemo(
    () =>
      gamesData?.games.reduce((acc, cur) => {
        if (gamesIds.includes(cur.id)) return acc;
        const key = cur.type.name;
        if (!acc[key]) acc[key] = [cur];
        else acc[key].push(cur);
        return acc;
      }, {} as { [typeName: string]: GameFragment[] }),
    [gamesData, event],
  );

  const onEventGameDelete = (game: NonNullable<EventGameFragment['game']>) => {
    deleteEventGame({
      variables: { eventId: event.id, gameId: game.id },
      optimisticResponse: {
        __typename: 'mutation_root',
        delete_events_m2m_games_by_pk: {
          __typename: 'events_m2m_games',
          event_id: event.id,
          game_id: game.id,
        },
      },
    });
  };

  const columns: ColumnsType<EventGameFragment> = [
    {
      title: t('table.type'),
      dataIndex: ['game', 'type', 'name'],
      render: typeName => t(`${typeName}.menuTitle` as any), // eslint-disable-line @typescript-eslint/no-explicit-any
    },
    {
      title: t('table.name'),
      dataIndex: ['game', 'name'],
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      render: date =>
        date
          ? formatDate(new Date(date), {
              lang: i18n.language,
              withTime: true,
            })
          : t('events.startOfEvent'),
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      render: date =>
        date
          ? formatDate(new Date(date), {
              lang: i18n.language,
              withTime: true,
            })
          : t('events.endOfEvent'),
    },
  ];

  if (!updateDisabled)
    columns.push({
      title: '',
      key: 'action',
      width: 50,
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: eventGame => (
        <DeleteAction onDelete={() => onEventGameDelete(eventGame.game)} />
      ),
    });

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <Select
            placeholder={t('events.addGame')}
            value={selectedGameId}
            onSelect={id => setSelectedGameId(id)}
            loading={gamesLoading}
            style={{ flex: 1 }}
            allowClear
            onClear={() => setSelectedGameId(undefined)}
          >
            {gamesGroupedByType &&
              Object.keys(gamesGroupedByType).map(typeName => (
                <OptGroup
                  key={typeName}
                  label={t(`${typeName}.menuTitle` as any)} // eslint-disable-line @typescript-eslint/no-explicit-any
                >
                  {gamesGroupedByType[typeName].map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
          </Select>
        </Col>
        <Col span={4}>
          <SubmitButton
            text={t('common.add')}
            icon={<PlusOutlined />}
            loading={insertLoading}
            onClick={() => {
              if (selectedGameId && !insertLoading) {
                const dateStart = dateRange && dateRange[0]?.toISOString();
                const dateEnd = dateRange && dateRange[1]?.toISOString();
                insertEventGame({
                  variables: {
                    object: {
                      event_id: event.id,
                      game_id: selectedGameId,
                      date_start:
                        dateStart !== eventDates[0].toISOString()
                          ? dateStart
                          : undefined,
                      date_end:
                        dateEnd !== eventDates[1].toISOString()
                          ? dateEnd
                          : undefined,
                      locations_ids:
                        locationsIds.length > 0
                          ? stringifyArrayForHasura(locationsIds)
                          : undefined,
                    },
                  },
                });
              }
            }}
            disabled={updateDisabled}
            disabledReason={updateDisabledReason}
          />
        </Col>
      </Row>
      {selectedGameId && (
        <>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={20} style={{ display: 'flex' }}>
              <DateRangePicker
                showTime
                disabledBeforeDate={new Date(event.date_start)}
                disabledAfterDate={new Date(event.date_end)}
                allowEmpty={[true, true]}
                value={dateRange}
                onChange={values => setDateRange(values)}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={20} style={{ display: 'flex' }}>
              <Select
                mode="multiple"
                placeholder={t('common.location_plural')}
                value={locationsIds}
                onSelect={id => setLocationsIds([...locationsIds, id])}
                onDeselect={id =>
                  setLocationsIds(locationsIds.filter(i => i !== id))
                }
                style={{ flex: 1 }}
              >
                {event.locations.map(({ location: { id, name } }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      )}
      <Table
        columns={columns}
        dataSource={event.games}
        rowKey={eventGame => `${eventGame.event_id}-${eventGame.game_id}`}
        rowClassName={eventGame =>
          eventGame.locations_ids ? 'cursor-pointer' : ''
        }
        expandable={{
          rowExpandable: eventGame => !!eventGame.locations_ids,
          expandedRowRender: eventGame => (
            <LocationsExpansion
              locationsIds={eventGame.locations_ids || []}
              eventLocations={event.locations}
            />
          ),
          expandRowByClick: true,
        }}
        tableLayout="auto"
      />
    </>
  );
};
