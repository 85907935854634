import { TFunction } from 'react-i18next';
import { message } from 'antd';

type Operators = '+' | '-' | '*' | '/';

export const getNumberPrecision = (n: number) => {
  const stringValue = n.toString();
  const indexOfDecimal = stringValue.indexOf('.');
  if (indexOfDecimal === -1) return 0;
  return stringValue.length - indexOfDecimal - 1;
};

export const evalMathExpression = (n1: number, n2: number, op: Operators) => {
  const n1Factor = 10 ** getNumberPrecision(n1);
  const n2Factor = 10 ** getNumberPrecision(n2);

  if (op === '+' || op === '-') {
    const factor = n1Factor > n2Factor ? n1Factor : n2Factor;
    const n1Int = Math.round(n1 * factor);
    const n2Int = Math.round(n2 * factor);
    return (op === '+' ? n1Int + n2Int : n1Int - n2Int) / factor;
  }

  const n1Int = Math.round(n1 * n1Factor);
  const n2Int = Math.round(n2 * n2Factor);

  if (op === '*') return (n1Int * n2Int) / (n1Factor * n2Factor);

  return (n1Int * n2Factor) / (n2Int * n1Factor);
};

export const stringToNumber = (value: string, t: TFunction<'translation'>) => {
  const number = +value;
  if (Number.isNaN(number)) {
    message.error(t('form.errors.numberInput'));
    return undefined;
  }
  return number;
};
