import React from 'react';
import styled from 'styled-components';

import { StatisticProvider } from 'context/StatisticContext';
import { IntroduceRow } from './IntroduceRow';
import { ParticipationsCard } from './ParticipationsCard';

export const Dashboard = () => (
  <StatisticProvider>
    <PageContent>
      <IntroduceRow />
      <ParticipationsCard />
    </PageContent>
  </StatisticProvider>
);

const PageContent = styled.div`
  padding: 24px 0 0;
  overflow: hidden scroll;
`;
