import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface NavLinkProps extends ChildrenProps {
  to: string;
}

export const NavLink = ({ children, to }: NavLinkProps) => {
  const { i18n } = useTranslation();
  return <RouterLink to={`/${i18n.language}${to}`}>{children}</RouterLink>;
};
