import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import {
  QuizzFragment,
  QuizzQuestionsFragment,
  useDeleteQuizzQuestionMutation,
  useUpdateQuizzMutation,
} from '@shared/api';

import { AppForm, Loader, DeleteAction } from 'components';
import { QuizzDrawer, QuizzDrawerProps } from './QuizzDrawer';

type FormValues = {
  winning_percentage?: number; // eslint-disable-line camelcase
  ordered_questions?: boolean; // eslint-disable-line camelcase
};

interface QuizzProps {
  quizz?: QuizzFragment;
  loading: boolean;
}

const Quizz = ({ quizz, loading = false }: QuizzProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [updateQuizzMutation] = useUpdateQuizzMutation();
  const [deleteQuizzQuestionMutation] = useDeleteQuizzQuestionMutation({
    update: (cache, { data: deleteData }) => {
      if (quizz && deleteData && deleteData.delete_quizz_questions_by_pk) {
        cache.modify({
          id: cache.identify(quizz),
          fields: {
            quizz_questions: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  deleteData.delete_quizz_questions_by_pk?.id !==
                  readField('id', ref),
              ),
          },
        });
      }
    },
  });
  const [drawerProps, setDrawerProps] = useState<
    Pick<QuizzDrawerProps, 'visible' | 'quizz' | 'quizzQuestions'>
  >({
    visible: false,
  });

  const columns: ColumnsType<QuizzQuestionsFragment> = [
    {
      title: t('table.questions'),
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: t('table.totalTime'),
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: t('table.totalPoints'),
      dataIndex: 'total_points',
      key: 'total_points',
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: quizzQuestions => (
        <DeleteAction
          onDelete={() => {
            deleteQuizzQuestionMutation({
              variables: { id: quizzQuestions.id },
            });
          }}
        />
      ),
    },
  ];

  const initialValues = {
    winning_percentage: quizz?.winning_percentage,
    ordered_questions: quizz?.ordered_questions,
  };

  const onFinish = async (values: FormValues) => {
    setLoadingForm(true);
    if (quizz) {
      await updateQuizzMutation({
        variables: {
          quizzId: quizz.id,
          quizz: {
            ...values,
          },
        },
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [quizz]);

  return (
    <>
      <QuizzDrawer
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <Row justify="end" gutter={16}>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            onClick={() => setDrawerProps({ visible: true, quizz })}
          >
            {loading ? <Loader /> : t('common.add')}
          </Button>
        </Col>
      </Row>
      <AppForm
        form={form}
        initialValues={initialValues}
        onFinish={values =>
          onFinish(values).finally(() => setLoadingForm(false))
        }
      >
        <Row gutter={16}>
          <Col span={24}>
            <Table
              size="small"
              columns={columns}
              dataSource={quizz?.quizz_questions || []}
              loading={loading}
              onRow={(quizzQuestions: QuizzQuestionsFragment) => ({
                onClick: () =>
                  setDrawerProps({ visible: true, quizz, quizzQuestions }),
              })}
              rowKey="id"
              rowClassName="cursor-pointer"
              pagination={false}
              summary={() => (
                <Table.Summary.Row
                  style={{ backgroundColor: 'rgb(250, 250, 250)' }}
                >
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {quizz?.quizz_questions.reduce(
                      (acc: number, { time }: QuizzQuestionsFragment): number =>
                        acc + time,
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {quizz?.quizz_questions.reduce(
                      (
                        acc: number,
                        { total_points: totalPoints }: QuizzQuestionsFragment,
                      ): number => acc + totalPoints,
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} />
                </Table.Summary.Row>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="winning_percentage"
              label={t('quizz.form.winningPercentage')}
              rules={[{ required: true }]}
              initialValue={100}
            >
              <InputNumber min={0} max={100} step={10} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="ordered_questions"
              label={t('quizz.form.orderedQuestions')}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {loadingForm ? <Loader /> : t('common.confirm')}
          </Button>
        </Row>
      </AppForm>
    </>
  );
};

export { Quizz };
