import * as React from 'react';

import { useGetGamesPlayedStatisticByClientQuery } from '@shared/api';

import { useLoggedClient } from 'features/auth';

type State = {
  loading: boolean;
  participations: any;
  participationsLast24hours: any;
  gains: any;
  gainsLast24hours: any;
};

const StatisticContext = React.createContext<State | undefined>(undefined);

type StatisticProviderProps = { children: React.ReactNode };

function StatisticProvider({ children }: StatisticProviderProps) {
  const { id } = useLoggedClient();
  const [state, setState] = React.useState<State>({
    loading: true,
    participations: [],
    participationsLast24hours: [],
    gains: [],
    gainsLast24hours: [],
  });

  const { data } = useGetGamesPlayedStatisticByClientQuery({
    variables: {
      clientId: id,
    },
  });

  React.useEffect(() => {
    setState({
      loading: false,
      participations: data?.games_played_participations_by_day || [],
      participationsLast24hours:
        data?.games_played_participations_by_current_day_hours || [],
      gains: data?.games_played_gains_by_day || [],
      gainsLast24hours: data?.games_played_gains_by_current_day_hours || [],
    });
  }, [data]);

  return (
    <StatisticContext.Provider value={state}>
      {children}
    </StatisticContext.Provider>
  );
}

function useStatistic() {
  const context = React.useContext(StatisticContext);
  if (context === undefined) {
    throw new Error('useStatistic must be used within a StatisticProvider');
  }
  return context;
}

export { StatisticProvider, useStatistic };
