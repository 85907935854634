import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Table } from 'antd';

import {
  EventFragment,
  EventLocationFragmentDoc,
  LocationFragment,
  useDeleteEventLocationMutation,
  useGetLocationsQuery,
  useInsertEventLocationMutation,
} from '@shared/api';

import { SubmitButton } from 'components';
import { getLocationsTableColumns } from 'features/locations';
import { onApolloError } from 'utils/errorUtils';

interface Props {
  event: EventFragment;
  updateDisabled: boolean;
  updateDisabledReason: string;
}

export const EventLocations = ({
  event,
  updateDisabled,
  updateDisabledReason,
}: Props) => {
  const { t } = useTranslation();
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >(undefined);

  const {
    data: locationsData,
    loading: locationsLoading,
  } = useGetLocationsQuery();

  const [
    insertEventLocation,
    { loading: insertLoading },
  ] = useInsertEventLocationMutation({
    update: (cache, { data }) => {
      if (data && data.insert_events_m2m_locations_one) {
        const {
          location_id: locationId,
        } = data.insert_events_m2m_locations_one;
        cache.modify({
          id: cache.identify(event),
          fields: {
            locations: existingRefs => {
              const newRef = cache.writeFragment({
                id: `events_m2m_locations:${event.id}-${locationId}`,
                fragment: EventLocationFragmentDoc,
                fragmentName: 'EventLocation',
                data: data.insert_events_m2m_locations_one,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: () => setSelectedLocationId(undefined),
    onError: onApolloError,
  });
  const [deleteEventLocation] = useDeleteEventLocationMutation({
    update: (cache, { data }) => {
      if (data && data.delete_events_m2m_locations_by_pk) {
        const {
          location_id: locationId,
        } = data.delete_events_m2m_locations_by_pk;
        cache.modify({
          id: cache.identify(event),
          fields: {
            locations: (existingRefs, { readField }) =>
              existingRefs.filter(
                (ref: any) =>
                  readField('id', readField('location', ref)) !== locationId,
              ),
          },
        });
      }
    },
    onError: onApolloError,
  });

  const locations = event.locations.map(l => l.location);
  const locationsIds = locations.map(l => l.id);

  const onEventLocationDelete = (location: LocationFragment) => {
    deleteEventLocation({
      variables: { eventId: event.id, locationId: location.id },
      optimisticResponse: {
        __typename: 'mutation_root',
        delete_events_m2m_locations_by_pk: {
          __typename: 'events_m2m_locations',
          event_id: event.id,
          location_id: location.id,
        },
      },
    });
  };

  const columns = getLocationsTableColumns(
    t,
    !updateDisabled ? onEventLocationDelete : undefined,
  );

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <Select
            placeholder={t('events.addLocation')}
            value={selectedLocationId}
            onSelect={(id: string) => setSelectedLocationId(id)}
            loading={locationsLoading}
            style={{ flex: 1 }}
          >
            {locationsData &&
              locationsData.locations
                .filter(({ id }) => !locationsIds.includes(id))
                .map(({ id, name, address }) => (
                  <Select.Option key={id} value={id}>
                    <b>{name}</b>
                    {address ? ` (${address})` : null}
                  </Select.Option>
                ))}
          </Select>
        </Col>
        <Col span={4}>
          <SubmitButton
            loading={insertLoading}
            onClick={() => {
              if (selectedLocationId)
                insertEventLocation({
                  variables: {
                    eventId: event.id,
                    locationId: selectedLocationId,
                  },
                });
            }}
            disabled={updateDisabled}
            disabledReason={updateDisabledReason}
          />
        </Col>
      </Row>
      <Table columns={columns} dataSource={locations} rowKey="id" />
    </>
  );
};
