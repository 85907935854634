import { setContext } from '@apollo/client/link/context';
import { authService } from './authService';

export const getAuthorizationHeader = () => {
  const credentials = authService.credentials.value;
  return credentials ? `Bearer ${credentials.token}` : '';
};

export const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAuthorizationHeader(),
  },
}));
