import format from 'date-fns/format';
import { ca, da, de, enUS, es, eu, fr, hu, it, pt, ru } from 'date-fns/locale';

const locales: { [key: string]: Locale } = {
  ca,
  da,
  de,
  en: enUS,
  es,
  eu,
  fr,
  hu,
  it,
  pt,
  ru,
};

interface FormatOptions {
  withTime?: boolean;
  lang?: string;
}
export const formatDate = (
  date: Date | number,
  { withTime = false, lang }: FormatOptions,
) =>
  format(date, withTime ? 'Pp' : 'P', {
    locale: lang ? locales[lang] : undefined,
  });
