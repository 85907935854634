/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';
import caES from 'antd/lib/locale/ca_ES';
import daDK from 'antd/lib/locale/da_DK';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import huHU from 'antd/lib/locale/hu_HU';
import itIT from 'antd/lib/locale/it_IT';
import ptPT from 'antd/lib/locale/pt_PT';
import ruRU from 'antd/lib/locale/ru_RU';

import { getValidateMessages } from 'utils/formUtils';

/**
 * The following is necessary because the mapping between
 * antd locales and date-fns locales is wrong
 */
caES.DatePicker!.lang.locale = 'ca';
daDK.DatePicker!.lang.locale = 'da';
deDE.DatePicker!.lang.locale = 'de';
esES.DatePicker!.lang.locale = 'es';
frFR.DatePicker!.lang.locale = 'fr';
huHU.DatePicker!.lang.locale = 'hu';
itIT.DatePicker!.lang.locale = 'it';
ptPT.DatePicker!.lang.locale = 'pt';
ruRU.DatePicker!.lang.locale = 'ru';

const fallback = enUS;

const MAP: { [key: string]: Locale } = {
  ca: caES,
  da: daDK,
  de: deDE,
  en: enUS,
  es: esES,
  fr: frFR,
  hu: huHU,
  it: itIT,
  pt: ptPT,
  ru: ruRU,
};

export const ConfigProvider = ({ children }: ChildrenProps) => {
  const { i18n, t } = useTranslation();

  return (
    <AntdConfigProvider
      form={{ validateMessages: getValidateMessages(t) }}
      locale={MAP[i18n.language] || fallback}
    >
      {children}
    </AntdConfigProvider>
  );
};
