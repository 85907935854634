import { TFunction } from 'react-i18next';
import { message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';

import { AssetFragment, Assets, AssetsTypesEnum } from '@shared/api';

import { authService } from 'features/auth';

const IMAGE_FORMATS = ['jpg', 'jpeg', 'png', 'gif'];
const VIDEO_FORMATS = ['m4v', 'mov', 'mp4', 'mpeg', 'mpg', 'webm'];
const OBJECT_FORMATS = ['vrx', 'obj']; // TODO: add support for fbx files
const MAX_SIZE = 100; // 100MB

export const FILE_API_ENDPOINT = `${
  process.env.REACT_APP_AUTH_SERVER_ENDPOINT || 'http://localhost:3002'
}/files`;

export type FileState = {
  loading?: boolean;
  fileUrl?: string;
  asset?: Assets;
};

const getValidFormats = (type: AssetsTypesEnum | 'resource') => {
  if (type === 'video' || type === 'video360') {
    return VIDEO_FORMATS;
  }
  if (type === 'object3D' || type === 'portal') {
    return OBJECT_FORMATS;
  }
  return IMAGE_FORMATS;
};

export function beforeUpload(
  file: File,
  type: AssetsTypesEnum | AssetsTypesEnum[] | 'resource',
  t: TFunction<'translation'>,
) {
  const validFormats = Array.isArray(type)
    ? type.reduce(
        (acc, cur) => acc.concat(getValidFormats(cur)),
        [] as string[],
      )
    : getValidFormats(type);

  const isValidFormat = validFormats.includes(
    file.type
      ? file.type.split('/')[1]
      : (file.name.split('.').pop() as string), // necessary cause file.type doesn't exist for object formats
  );
  if (!isValidFormat) {
    message.error(
      t('files.uploadErrors.invalidFormat', {
        formats: validFormats.join(', '),
      }),
    );
    return false;
  }

  const isValidSize = file.size < MAX_SIZE * 1024 * 1024;
  if (!isValidSize) {
    message.error(t('files.uploadErrors.invalidSize', { maxSize: MAX_SIZE }));
    return false;
  }
  return true;
}

export const getBase64 = (img: Blob) =>
  new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getFileState = (
  info: UploadChangeParam,
  t: TFunction<'translation'>,
): Promise<FileState> => {
  if (info.file.status === 'uploading')
    return Promise.resolve({ loading: true });

  if (info.file.status === 'done' && info.file.response)
    return getBase64(info.file.originFileObj as Blob).then(fileUrl => ({
      loading: false,
      fileUrl,
      asset: info.file.response,
    }));

  if (info.file.status === 'error') {
    if (!info.file) message.error('Internal Error');
    else if (typeof info.file.response === 'string')
      message.error(info.file.response);
    else if (info.file.response.error === 'alreadyExists') {
      message.info(t('files.uploadErrors.alreadyExists'));
      return Promise.resolve({
        loading: false,
        asset: info.file.response.asset,
      });
    } else
      info.file.response.errors.forEach((e: Error) => message.error(e.message));
  }
  return Promise.resolve({ loading: false });
};

export const deleteFile = (
  name: string,
  assetId?: string,
  isResource?: boolean,
) =>
  authService.fetchJson<AssetFragment | string>(
    `${FILE_API_ENDPOINT}/delete`,
    'POST',
    {
      name,
      assetId,
      isResource,
    },
  );

export const removeExtension = (path: string) => {
  const splitPath = path.split('.');
  splitPath.pop();
  return splitPath.join('.');
};

export const addSuffix = (path: string, suffix: string) => {
  const splitPath = path.split('.');
  splitPath[splitPath.length - 2] += suffix;
  return splitPath.join('.');
};
