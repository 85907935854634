import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'antd';
import styled from 'styled-components';

import { ClientForgottenPasswordBody } from '@shared/api';

import { AppForm, Loader, NavLink } from 'components';
import { authApi } from '../authApi';
import { EmailPasswordItems } from './EmailPasswordItems';

export const ForgottenPassword = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const onFinish = (values: ClientForgottenPasswordBody) => {
    setResetPasswordLoading(true);
    return authApi
      .forgottenPassword(values)
      .then(() => {
        Modal.success({
          title: t('common.emailSent'),
          content: t('auth.resetPasswordEmailSent'),
          closable: false,
          onOk: () => history.push(`/${i18n.language}/login`),
        });
      })
      .finally(() => {
        setResetPasswordLoading(false);
      });
  };

  return (
    <AppForm onFinish={onFinish}>
      <Text>{t('auth.forgottenPasswordEnterMail')}</Text>
      <EmailPasswordItems password={false} />

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {resetPasswordLoading ? <Loader /> : t('auth.resetPassword')}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="link" block>
          <NavLink to="/login">{t('auth.login')}</NavLink>
        </Button>
      </Form.Item>
    </AppForm>
  );
};

const Text = styled.p`
  margin: 0 -4rem 2rem;
  text-align: center;
  font-weight: 600;
`;
