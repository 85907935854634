import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';

export interface Props {
  designHtml: any;
  selectedLng: string;
  saveDesignHtml: any;
}

export const TemplateBuilder = ({
  designHtml,
  selectedLng,
  saveDesignHtml,
}: Props) => {
  const emailEditorRef = useRef<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      emailEditorRef.current.editor.loadDesign(
        designHtml.design && designHtml.design[selectedLng]
          ? designHtml.design[selectedLng]
          : '',
      );
      emailEditorRef.current.editor.addEventListener('design:updated', () => {
        emailEditorRef.current.editor.exportHtml((data: any) => {
          const { design, html } = data;
          saveDesignHtml({
            design: {
              ...designHtml.design,
              [selectedLng]: design,
            },
            html: {
              ...designHtml.html,
              [selectedLng]: html,
            },
          });
        });
      });
    }
  }, [isLoaded]);

  return (
    <EmailEditor
      ref={emailEditorRef}
      onLoad={() => {
        setIsLoaded(true);
      }}
      style={{ height: 'calc(100vh - 110px)' }}
      options={{ locale: 'fr-FR' }}
    />
  );
};
