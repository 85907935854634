import React, { useState } from 'react';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import {
  useGetPrivatesDecksByClientQuery,
  useUpdatePrivateDeckMutation,
  useArchivePrivateDeckMutation,
  PrivateDeckFragment,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import {
  PrivateDeckDrawer,
  Props as PrivateDeckDrawerProps,
} from './PrivateDeckDrawer';

import { PrivateDeckData } from './types';

const PrivatesDecks = () => {
  const { id } = useLoggedClient();
  const { t } = useTranslation();
  const [drawerProps, setDrawerProps] = useState<
    Pick<PrivateDeckDrawerProps, 'visible' | 'privateDeck'>
  >({
    visible: false,
  });
  const { data, loading } = useGetPrivatesDecksByClientQuery({
    variables: { clientId: id },
  });
  const [updatePrivateDeckMutation] = useUpdatePrivateDeckMutation();
  const [archivePrivateDeckMutation] = useArchivePrivateDeckMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_privates_decks_by_pk) {
        cache.modify({
          fields: {
            privates_decks(existingPrivatesDecksRefs, { readField }) {
              return existingPrivatesDecksRefs.filter(
                (privateDeckRef: PrivateDeckFragment) =>
                  archiveData.update_privates_decks_by_pk?.id !==
                  readField('id', privateDeckRef),
              );
            },
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (data && drawerProps) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        privateDeck: data.privates_decks.filter(
          privateDeck => privateDeck.id === drawerProps?.privateDeck?.id,
        )[0],
      });
    }
  }, [data]);

  const columns: ColumnsType<PrivateDeckData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '70%',
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      width: '20%',
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      width: '20%',
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      key: 'is_active',
      render: (isActive, privateDeck) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updatePrivateDeckMutation({
              variables: {
                privateDeckId: privateDeck.id,
                privateDeck: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_privates_decks_by_pk: {
                  ...privateDeck,
                  is_active: !isActive,
                  __typename: 'privates_decks',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: privateDeck => (
        <DeleteAction
          onDelete={() => {
            archivePrivateDeckMutation({
              variables: { privateDeckId: privateDeck.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_privates_decks_by_pk: {
                  ...privateDeck,
                  is_archive: true,
                  __typename: 'privates_decks',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<PrivateDeckData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t('privatesDecks.menuTitle')}
        onAdd={() => setDrawerProps({ visible: true })}
        actions={actions}
        columns={columns}
        dataSource={data?.privates_decks}
        loading={loading}
        onRow={privateDeck => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              privateDeck,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      <PrivateDeckDrawer
        visible={drawerProps.visible}
        privateDeck={drawerProps.privateDeck}
        onCreate={privateDeck => setDrawerProps({ visible: true, privateDeck })}
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};

export { PrivatesDecks };
