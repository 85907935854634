import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface Props {
  fontSize?: number;
  style?: React.CSSProperties;
  className?: string;
}
export const Loader = ({ fontSize = 24, style, className }: Props) => (
  <StyledSpin
    indicator={
      <LoadingOutlined
        style={{ ...style, fontSize }}
        spin
        className={className}
      />
    }
  />
);

const StyledSpin = styled(Spin)`
  color: inherit;
`;
