import React from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { useUpdateGainMutation } from '@shared/api';

import { GainWinners } from './GainWinners';
import { GainsFormDetails } from './Form/Details';
import { GainsFormInstructions } from './Form/Instructions';
import { GainsFormVirtualGain } from './Form/VirtualGain';
import { GainData } from './types';
import { TemplateSelection } from '../templates-selection/TemplateSelection';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  onClose: () => void;
  onCreate: (gain: GainData) => void;
  gain?: GainData;
};

const GainDrawer = ({ visible, onClose, onCreate, gain }: Props) => {
  const { t } = useTranslation();
  const [updateGainMutation] = useUpdateGainMutation();

  const onSaveTemplate = async (templateId: string) => {
    if (gain) {
      await updateGainMutation({
        variables: {
          gainId: gain.id,
          gain: {
            template_id: templateId,
          },
        },
      });
    }
  };

  return (
    <Drawer
      title={
        gain
          ? t('gains.updateGain', {
              name: gain?.name,
            })
          : t('gains.createGain')
      }
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('gains.details')} key="1">
          <GainsFormDetails gain={gain} onCreate={onCreate} />
        </TabPane>
        <TabPane tab={t('gains.instructions')} key="2" disabled={!gain}>
          <GainsFormInstructions gain={gain} />
        </TabPane>
        {gain?.is_virtual && (
          <TabPane tab={t('gains.virtualGain')} key="3" disabled={!gain}>
            <GainsFormVirtualGain gain={gain} />
          </TabPane>
        )}
        <TabPane tab={t('common.template')} key="4">
          <TemplateSelection
            templateId={gain?.template_id || undefined}
            onSave={onSaveTemplate}
          />
        </TabPane>
        <TabPane tab={t('gains.winner')} key="5" disabled={!gain}>
          <GainWinners />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export { GainDrawer };
