import React from 'react';

import { LangSwitcher } from './LangSwitcher';

export const Settings = () => (
  <>
    <h3>Paramétrages</h3>
    <LangSwitcher />
  </>
);
