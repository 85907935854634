import React from 'react';
import styled from 'styled-components';

import { AccountMenu } from './Menu';

export const AccountLayout = ({ children }: ChildrenProps) => (
  <PageContent>
    <AccountMenu />
    <AccountContent>{children}</AccountContent>
  </PageContent>
);

const PageContent = styled.div`
  display: flex;
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  overflow: hidden;
`;

const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 36px;
  flex: 1;
  background-color: white;
  overflow: scroll;
`;
