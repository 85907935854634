import React from 'react';
import styled from 'styled-components';

export type FieldProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  style?: React.CSSProperties;
};

export const Field = ({ label, value, ...rest }: FieldProps) => (
  <Container {...rest}>
    <Label>{label}</Label>
    <Number>{value}</Number>
  </Container>
);

const Container = styled.div`
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Label = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

const Number = styled.span`
  font-size: 16px;
  line-height: 22px;
  margin-left: 8px;
  color: black;
`;
