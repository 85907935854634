import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'antd';
import { Asserts } from 'yup';

import { newPasswordSchema, resetTokenSchema } from '@shared/api';
import { AppForm, Loader, ErrorAlert, NavLink } from 'components';
import { authApi } from '../authApi';
import { EmailPasswordItems } from './EmailPasswordItems';

export const ResetPassword = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const redirect = () => history.replace(`/${i18n.language}/login`);

  try {
    const resetTokenId = resetTokenSchema.validateSync(
      new URLSearchParams(history.location.search).get('token'),
    );

    const onFinish = (values: Asserts<typeof newPasswordSchema>) => {
      setResetPasswordLoading(true);
      return authApi
        .resetPassword({ ...values, resetTokenId })
        .then(() => {
          Modal.success({
            title: t('auth.resetPassword'),
            content: t('auth.passwordReset'),
            closable: false,
            onOk: redirect,
          });
        })
        .finally(() => setResetPasswordLoading(false));
    };

    return (
      <AppForm onFinish={onFinish}>
        <EmailPasswordItems email={false} confirmPassword />

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {resetPasswordLoading ? <Loader /> : t('auth.resetPassword')}
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="link" block>
            <NavLink to="/login">{t('auth.login')}</NavLink>
          </Button>
        </Form.Item>
      </AppForm>
    );
  } catch (e) {
    setTimeout(redirect, 5000);
    return (
      <ErrorAlert
        error={{
          errors: [
            'auth.errors.wrongResetToken',
            { key: 'auth.redirectingTo', options: { destination: 'Login' } },
          ],
        }}
        onClose={redirect}
      />
    );
  }
};
