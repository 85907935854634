interface codeGeneratorProps {
  upper: boolean;
  lower: boolean;
  digit: boolean;
  length: number;
  prefix: string;
}

export const codeGenerator = ({
  upper,
  lower,
  digit,
  length,
  prefix,
}: codeGeneratorProps) => {
  let result = '';

  const upperChar = upper ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' : '';
  const lowerChar = lower ? 'abcdefghijklmnopqrstuvwxyz' : '';
  const digitChar = digit ? '0123456789' : '';

  const characters = upperChar + lowerChar + digitChar;
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return prefix ? prefix + result : result;
};
