import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, Row, Switch } from 'antd';
import { CloseOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  EstimateFragmentDoc,
  useInsertEstimateMutation,
  useUpdateEstimateMutation,
} from '@shared/api';

import { AppForm, Loader, DateRangePicker } from 'components';
import { useLoggedClient } from 'features/auth';
import { EstimateData } from '../types';

type FormValues = {
  name: string;
  rangeDate: string[];
};

interface EstimatesFormDetailsProps {
  estimate?: EstimateData;
  onCreate: (estimate: EstimateData) => void;
}

export const EstimateFormSetup = ({
  estimate,
  onCreate,
}: EstimatesFormDetailsProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const [insertEstimateMutation] = useInsertEstimateMutation({
    onCompleted: data => {
      if (data && data.insert_estimates_one) {
        onCreate(data.insert_estimates_one);
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_estimates_one) {
        cache.modify({
          fields: {
            estimates: estimatesRefs => {
              const newEstimateRef = cache.writeFragment({
                data: data.insert_estimates_one,
                fragment: EstimateFragmentDoc,
                fragmentName: 'Estimate',
              });
              return [...estimatesRefs, newEstimateRef];
            },
          },
        });
      }
    },
  });
  const [updateEstimateMutation] = useUpdateEstimateMutation();

  const onSubmit = async ({ ...values }: FormValues) => {
    setLoading(true);
    const { rangeDate, ...formatedValues } = values;
    if (estimate) {
      await updateEstimateMutation({
        variables: {
          estimateId: estimate.id,
          estimate: {
            ...formatedValues,
            date_start: rangeDate[0],
            date_end: rangeDate[1],
          },
        },
      });
    } else {
      await insertEstimateMutation({
        variables: {
          estimate: {
            ...formatedValues,
            date_start: rangeDate[0],
            date_end: rangeDate[1],
            client_id: !estimate && role === 'admin' ? clientId : undefined,
          },
        },
      });
    }
  };

  const initialValues = {
    name: estimate?.name || '',
    rangeDate: estimate
      ? [new Date(estimate.date_start), new Date(estimate.date_end)]
      : undefined,
  };

  useEffect(() => {
    form.resetFields();
  }, [estimate]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('estimates.form.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('estimates.form.name')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('estimates.form.dateStart')}
            name="rangeDate"
            rules={[{ required: true }]}
          >
            <DateRangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
