import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerProps, Form, Input, InputNumber, Row, Col } from 'antd';
import styled from 'styled-components';

import {
  useInsertPredictionEventMutation,
  useUpdatePredictionEventMutation,
  PredictionFragment,
  PredictionEventFragment,
  PredictionEventOutcomeFragment,
  PredictionEventFragmentDoc,
} from '@shared/api';

import { AppForm, SubmitButton, DatePicker } from 'components';
import { PredictionEventOutcomes } from './PredictionEventOutcomes';

const { TextArea } = Input;

export interface PredictionDrawerProps extends DrawerProps {
  prediction?: PredictionFragment;
  predictionEvents?: PredictionEventFragment;
  onClose: () => void;
}

export const PredictionDrawer = ({
  onClose,
  prediction,
  predictionEvents,
  ...rest
}: PredictionDrawerProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [insertPredictionEventMutation] = useInsertPredictionEventMutation({
    onCompleted: () => onClose(),
    update: (cache, { data }) => {
      if (prediction && data && data.insert_predictions_events_one) {
        cache.modify({
          id: cache.identify(prediction),
          fields: {
            predictions_events: refs => {
              const newPredictionEventRef = cache.writeFragment({
                data: data.insert_predictions_events_one,
                fragment: PredictionEventFragmentDoc,
                fragmentName: 'PredictionEvent',
              });
              return [...refs, newPredictionEventRef];
            },
          },
        });
      }
    },
  });
  const [updatePredictionEventMutation] = useUpdatePredictionEventMutation({
    onCompleted: () => onClose(),
  });

  const handleSetIsWinnerChange = (index: number) => {
    const predictionsEventsOutcomes = form.getFieldValue(
      'predictions_events_outcomes',
    );
    form.setFieldsValue({
      predictions_events_outcomes: predictionsEventsOutcomes.map(
        (
          predictionsEventsOutcome: any,
          predictionsEventsOutcomeIndex: number,
        ) => ({
          ...predictionsEventsOutcome,
          is_winner: predictionsEventsOutcomeIndex === index,
        }),
      ),
    });
  };

  const onFinish = async ({ ...values }: PredictionEventFragment) => {
    setLoading(true);
    if (predictionEvents) {
      const {
        predictions_events_outcomes: predictionEventOutcomes,
        ...updates
      } = values;
      updatePredictionEventMutation({
        variables: {
          id: predictionEvents.id,
          updates: {
            ...updates,
          },
          predictionEventsOutcomes: predictionEventOutcomes.map(
            (outcome: PredictionEventOutcomeFragment) => {
              const { asset, ...outcomeData } = outcome;
              return {
                ...outcomeData,
                asset_id: asset.id,
                prediction_event_id: predictionEvents.id,
                __typename: undefined,
              };
            },
          ),
        },
      });
    } else {
      insertPredictionEventMutation({
        variables: {
          object: {
            ...values,
            prediction_id: prediction?.id,
            predictions_events_outcomes: {
              data: values.predictions_events_outcomes.map(outcome => {
                const { asset, ...outcomeData } = outcome;
                return {
                  ...outcomeData,
                  asset_id: asset.id,
                };
              }),
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (rest.visible) {
      form.resetFields();
    }
  }, [prediction]);

  return (
    <Drawer
      width={600}
      title={t('prediction.predictionTitle')}
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={{
          ...predictionEvents,
          timestamp_start: predictionEvents?.timestamp_start
            ? new Date(predictionEvents?.timestamp_start)
            : '',
        }}
        preserve={false}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="event"
            label={t('prediction.event')}
            rules={[{ required: true }]}
          >
            <TextArea
              rows={2}
              showCount
              maxLength={120}
              style={{ color: 'white' }}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="timestamp_start"
                  label={t('prediction.form.timestampStart')}
                  rules={[{ required: true }]}
                >
                  <DatePicker format="YYYY-MM-DD HH:mm" showTime />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="required_outcomes"
                  label={t('prediction.form.requiredOutcomes')}
                  rules={[{ required: true }]}
                  initialValue={1}
                >
                  <InputNumber style={{ width: '100%' }} min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <PredictionEventOutcomes
            handleSetIsWinnerChange={handleSetIsWinnerChange}
          />
        </FormContainer>
        <SubmitButton
          size="large"
          loading={loading}
          style={{ marginTop: '0.5rem' }}
        />
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;
