import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { AssetsTypesEnum } from '@shared/api';

import { LibraryContent, LibraryContentType } from 'utils/assetUtils';
import { Library } from './Library';

interface Props {
  type?: LibraryContentType;
  assetType?: AssetsTypesEnum;
  onSelect: (content: LibraryContent) => void;
}

export const LibraryButton = ({ type, assetType, onSelect }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        type="dashed"
        block
        style={{ height: '66px' }}
        onClick={() => setVisible(true)}
      >
        {t('library.menuTitle')}
      </Button>
      <Library
        type={type}
        assetType={assetType}
        onSelect={onSelect}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};
