import { useEffect, useState } from 'react';

interface BillingPricesProps {
  [key: string]: number;
}

export const useBillingPriceCalculator = (
  prices: BillingPricesProps,
): [totalPrice: number, setPrice: (newPrices: BillingPricesProps) => void] => {
  const [storePrices, setStorePrices] = useState<BillingPricesProps>(prices);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    const total = Object.keys(storePrices).reduce(
      (sum, key) => sum + storePrices[key],
      0,
    );
    setTotalPrice(Math.round(total * 100) / 100);
  }, [storePrices]);

  const setPrice = (newPrices: BillingPricesProps) => {
    const currentKeys = Object.keys(newPrices).map((key: any) => ({
      [key]: newPrices[key],
    }));

    let updateStore = storePrices;
    currentKeys.forEach(key => (updateStore = { ...updateStore, ...key }));

    setStorePrices(updateStore);
  };

  return [totalPrice, setPrice];
};
