import React, { useEffect, useState } from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetGoodieAnimationsLazyQuery } from '@shared/api';

import { AnimationsTab } from 'components/animations';
import { GoodieData } from './types';
import { GoodieFormSetup } from './forms/Setup';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  goodie?: GoodieData;
  onAdd: (goodie: GoodieData) => void;
  onClose: () => void;
};

export const GoodieDrawer = ({ visible, goodie, onAdd, onClose }: Props) => {
  const { t } = useTranslation();
  const [tabActiveKey, setTabActiveKey] = useState('1');

  const [
    getAnimations,
    { data: animationsData, loading: animationsLoading },
  ] = useGetGoodieAnimationsLazyQuery();

  useEffect(() => {
    if (goodie) getAnimations({ variables: { id: goodie.id } });
  }, [goodie]);

  // Reset active key on close drawer
  useEffect(() => {
    setTabActiveKey('1');
  }, [visible]);

  return (
    <>
      <Drawer
        title={
          goodie
            ? t('goodies.update', { name: goodie.name })
            : t('goodies.create')
        }
        width={720}
        onClose={onClose}
        visible={visible}
        destroyOnClose
      >
        <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey}>
          <TabPane tab={t('games.setup')} key="1">
            <GoodieFormSetup goodie={goodie} onAdd={onAdd} />
          </TabPane>
          {goodie && (
            <TabPane tab={t('games.trackers')} key="2">
              <AnimationsTab
                type="goodie"
                data={{
                  ...goodie,
                  animations: animationsData?.goodies_by_pk?.animations || [],
                }}
                loading={animationsLoading}
              />
            </TabPane>
          )}
        </Tabs>
      </Drawer>
    </>
  );
};
