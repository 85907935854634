import React, { useEffect, useState } from 'react';
import { Button, Col, InputNumber, Modal, Row, Table, Typography } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';
import styled from 'styled-components';

import {
  useUpdateEstimateMutation,
  useUpdateEstimateM2mEntityMutation,
  useArchiveEstimatesM2mEntitiesByPkMutation,
  EstimateM2mEntityFragment,
} from '@shared/api';

import { DeleteAction } from 'components';
import { SliderWithInputNumber } from 'components/slider';
import { getPriceByStep, getDiscount } from 'utils/getPriceByStep';
import {
  EstimateFormEntityDrawer,
  EstimateFormEntityDrawerProps,
} from './EntityDrawer';
import { EstimateData } from '../types';

const { Text } = Typography;

interface EstimateFormEntitiesProps {
  estimate: EstimateData;
}

export const EstimateFormEntities = ({
  estimate,
}: EstimateFormEntitiesProps) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any>([]);
  const [particpationsCredits, setParticpationsCredits] = useState(
    estimate.participations_credits,
  );
  const [updatedForm, setUpdatedForm] = useState(false);
  const [updateEstimateMutation] = useUpdateEstimateMutation();
  const [updateEstimateM2mEntity] = useUpdateEstimateM2mEntityMutation();
  const [
    archiveEstimateM2mEntity,
  ] = useArchiveEstimatesM2mEntitiesByPkMutation();

  const totalDays =
    differenceInDays(
      new Date(estimate?.date_end),
      new Date(estimate?.date_start),
    ) + 1;

  const [drawerProps, setDrawerProps] = useState<
    Pick<
      EstimateFormEntityDrawerProps,
      'visible' | 'estimate' | 'estimateM2mEntity'
    >
  >({
    visible: false,
  });

  useEffect(() => {
    setTableData(estimate.estimates_m2m_entities);
  }, [estimate]);

  const totalPrice =
    tableData.reduce((acc: number, item: EstimateM2mEntityFragment) => {
      const trackers = item?.trackers
        ? getPriceByStep(item.trackers, item.games_types?.trackers_price_range)
        : 0;
      const radiusPriceRange = item.games_types
        ? item.games_types?.radius_price_range
        : 0;
      const itemPrice = item.games_types
        ? item.games_types?.price
        : item.communications_types?.price;
      return (
        acc +
        (getPriceByStep(item.radius, radiusPriceRange) +
          (itemPrice || 0) +
          trackers) *
          totalDays
      );
    }, 0) *
    (1 - getDiscount(totalDays));

  const columns: any = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '23%',
    },
    {
      title: t('table.type'),
      key: 'type',
      width: '17%',
      render: ({
        games_types: gamesTypes,
        communications_types: communicationsTypes,
      }: EstimateM2mEntityFragment) => {
        const typeName = gamesTypes
          ? gamesTypes?.name
          : communicationsTypes?.name;

        return (
          <Text>
            {t(
              `${typeName}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            )}
          </Text>
        );
      },
    },
    {
      title: t('table.priceHT'),
      key: 'type',
      width: '12%',
      render: ({
        games_types: gamesTypes,
        communications_types: communicationsTypes,
      }: EstimateM2mEntityFragment) => {
        const typePrice = gamesTypes
          ? gamesTypes?.price
          : communicationsTypes?.price;

        return <Text>{typePrice} €</Text>;
      },
    },
    {
      title: t('table.radius'),
      dataIndex: 'radius',
      key: 'radius',
      onCell: () => ({ onClick: (ev: any) => ev.stopPropagation() }),
      width: '14%',
      render: (
        radius: number,
        {
          id,
          communications_types: communicationsTypes,
        }: EstimateM2mEntityFragment,
      ) => (
        <Row gutter={16}>
          <InputNumber
            size="small"
            min={200}
            max={
              communicationsTypes && communicationsTypes.acronym === 'IF'
                ? 100000
                : 20000
            }
            step={50}
            value={radius}
            onChange={value => {
              setUpdatedForm(true);
              setTableData(
                tableData.map((data: EstimateM2mEntityFragment) => {
                  if (data.id === id) {
                    return { ...data, radius: value };
                  }
                  return data;
                }),
              );
            }}
          />
        </Row>
      ),
    },
    {
      title: t('table.trackers'),
      dataIndex: 'trackers',
      key: 'trackers',
      onCell: () => ({ onClick: (ev: any) => ev.stopPropagation() }),
      width: '14%',
      render: (trackers: number, { id }: EstimateM2mEntityFragment) => (
        <Row gutter={16}>
          {trackers ? (
            <InputNumber
              size="small"
              min={2}
              max={50}
              value={trackers}
              onChange={value => {
                setUpdatedForm(true);
                setTableData(
                  tableData.map((data: EstimateM2mEntityFragment) => {
                    if (data.id === id) {
                      return { ...data, trackers: value };
                    }
                    return data;
                  }),
                );
              }}
            />
          ) : null}
        </Row>
      ),
    },
    {
      title: t('table.totalHT'),
      width: '10%',
      align: 'right',
      render: ({
        radius,
        trackers,
        games_types: gamesTypes,
        communications_types: communicationsTypes,
      }: EstimateM2mEntityFragment) => {
        const trackersPrice = trackers
          ? getPriceByStep(trackers, gamesTypes?.trackers_price_range)
          : 0;
        const radiusPriceRange = gamesTypes
          ? gamesTypes?.radius_price_range
          : 0;
        const itemPrice = gamesTypes
          ? gamesTypes?.price
          : communicationsTypes?.price;

        return (
          <Text strong>
            {Math.round(
              (getPriceByStep(radius, radiusPriceRange) +
                (itemPrice || 0) +
                trackersPrice) *
                (1 - getDiscount(totalDays)) *
                totalDays,
            )}{' '}
            €
          </Text>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      align: 'center',
      onCell: () => ({ onClick: (ev: any) => ev.stopPropagation() }),
      className: 'cursor-default',
      render: (estimateM2mEntity: EstimateM2mEntityFragment) => (
        <DeleteAction
          onDelete={() => {
            archiveEstimateM2mEntity({
              variables: {
                estimateM2mEntityId: estimateM2mEntity.id,
              },
              update: (cache, { data: deleteData }) => {
                if (
                  estimate &&
                  deleteData &&
                  deleteData.delete_estimates_m2m_entities_by_pk
                ) {
                  cache.modify({
                    id: cache.identify(estimate),
                    fields: {
                      estimates_m2m_entities: (refs, { readField }) =>
                        refs.filter(
                          (ref: EstimateM2mEntityFragment) =>
                            deleteData.delete_estimates_m2m_entities_by_pk
                              ?.id !== readField('id', ref),
                        ),
                    },
                  });
                }
              },
            });
          }}
        />
      ),
    },
  ];

  const handleChange = () => {
    if (estimate.participations_credits !== particpationsCredits) {
      updateEstimateMutation({
        variables: {
          estimateId: estimate.id,
          estimate: { participations_credits: particpationsCredits },
        },
      });
    }
    if (tableData.length > 0) {
      tableData.map(({ id, radius, trackers }: EstimateM2mEntityFragment) => {
        updateEstimateM2mEntity({
          variables: {
            estimateM2mEntityId: id,
            estimateM2mEntity: {
              radius,
              trackers,
            },
          },
        });
        return null;
      });
    }
    setUpdatedForm(false);
  };

  const confirm = () => {
    Modal.confirm({
      title: t('estimates.confirmQuotation'),
      icon: <ExclamationCircleOutlined />,
      content:
        'Attention, une fois le devis généré vous ne pouvez plus modifier votre simulation. Une fois celui-ci validé vous pourrez générer l‘opération directement à partir de votre simulation',
      okText: 'Générer',
      cancelText: 'Annnuler',
    });
  };

  const confirmSave = () => {
    Modal.confirm({
      title: t('estimates.confirmSaveData'),
      icon: <ExclamationCircleOutlined />,
      content:
        'Attention vous avez des modifications qui n‘ont pas été enregistrées vous devez les sauver avant de générer votre devis',
      okText: 'Enregistrer',
      onOk: () => {
        handleChange();
        confirm();
      },
      cancelText: 'Annnuler',
    });
  };

  return (
    <>
      <EstimateFormEntityDrawer
        {...drawerProps}
        onClose={() => setDrawerProps({ visible: false })}
        destroyOnClose
      />
      <Row justify="end" gutter={16}>
        <Col span={24}>
          <SliderWithInputNumber
            min={0}
            max={100000}
            step={100}
            defaultValue={particpationsCredits}
            onChange={(value: number) => {
              setUpdatedForm(true);
              setParticpationsCredits(value);
            }}
            title={t('estimates.form.particpationsCredits')}
          />
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 10,
          border: '1px solid rgb(230,230,230)',
          padding: '10px 0px',
        }}
      >
        <Col span={19}>Total HT</Col>
        <Col span={5}>
          <Text strong>{Math.round(particpationsCredits * 0.07)} €</Text>
        </Col>
      </Row>
      <Row
        justify="end"
        gutter={16}
        style={{
          marginTop: 20,
        }}
      >
        <Col span={15}>{t('estimates.form.estimatesEntities')}</Col>
        <Col span={5}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            disabled={!updatedForm}
            onClick={() => handleChange()}
          >
            {t('common.save')}
          </Button>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            onClick={() => setDrawerProps({ visible: true, estimate })}
          >
            {t('common.add')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            dataSource={tableData || []}
            rowClassName="cursor-pointer"
            onRow={estimateM2mEntity => ({
              onClick: () =>
                setDrawerProps({
                  visible: true,
                  estimate,
                  estimateM2mEntity,
                }),
            })}
          />
        </Col>
      </Row>
      <Total>
        <TotalTiltle>{t('gameBilling.totalPrice')}</TotalTiltle>
        <TotalPrice>
          {Math.round(totalPrice + particpationsCredits * 0.07)}
          <TotalPriceSup>
            {t('gameBilling.totalPriceSupDays', { days: totalDays })}
            <TotalPriceDay>
              {t('gameBilling.orPriceByDay')}{' '}
              {Math.round(
                (totalPrice + particpationsCredits * 0.07) / totalDays,
              )}
              <sup>{t('gameBilling.totalPriceSupDay')}</sup>
            </TotalPriceDay>
          </TotalPriceSup>
        </TotalPrice>
        <TotalAction>
          <Button
            type="default"
            htmlType="submit"
            icon={<PlusOutlined />}
            onClick={updatedForm ? confirmSave : confirm}
          >
            {t('gameBilling.generateQuote')}
          </Button>
        </TotalAction>
      </Total>
    </>
  );
};

const Total = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  background-color: #d73716;
  height: 25%;
  padding: 24px;
  align-items: center;
`;

const TotalTiltle = styled.div`
  flex: 1;
  color: white;
  font-size: 18px;
`;

const TotalPrice = styled.div`
  display: flex;
  color: white;
  font-size: 52px;
  position: relative;
`;

const TotalPriceSup = styled.div`
  font-size: 27px;
  margin-left: 10px;
`;

const TotalPriceDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 16px;
`;

const TotalAction = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 0;
`;
