import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Tooltip } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { Loader } from '../Loader';

interface Props extends ButtonProps {
  text?: string;
  loading?: boolean;
  disabledReason?: string;
}

export const SubmitButton = ({
  text,
  loading,
  disabledReason,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={rest.disabled ? disabledReason : undefined}>
      <Button
        type="primary"
        htmlType="submit"
        icon={<SaveOutlined />}
        {...rest}
      >
        {loading ? <Loader /> : text || t('common.confirm')}
      </Button>
    </Tooltip>
  );
};
