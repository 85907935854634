import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';

import {
  EventFragment,
  EventGoodieFragment,
  EventGoodieFragmentDoc,
  useDeleteEventGoodieMutation,
  useGetGoodiesQuery,
  useInsertEventGoodieMutation,
} from '@shared/api';

import { DeleteAction, SubmitButton } from 'components';
import { formatDate } from 'utils/dateUtils';
import { onApolloError } from 'utils/errorUtils';

const { Option } = Select;

interface Props {
  event: EventFragment;
  updateDisabled: boolean;
  updateDisabledReason: string;
}

export const EventGoodies = ({
  event,
  updateDisabled,
  updateDisabledReason,
}: Props) => {
  const { i18n, t } = useTranslation();
  const [selectedGoodieId, setSelectedGoodieId] = useState<string | undefined>(
    undefined,
  );

  const { data: goodiesData, loading: goodiesLoading } = useGetGoodiesQuery({
    variables: {
      where: {
        _and: [{ is_archive: { _eq: false }, is_active: { _eq: true } }],
      },
    },
  });

  const goodiesIds = event.goodies.map(g => g.goodie.id);
  const goodies = goodiesData?.goodies.filter(g => !goodiesIds.includes(g.id));

  const [
    insertEventGoodie,
    { loading: insertLoading },
  ] = useInsertEventGoodieMutation({
    update: (cache, { data }) => {
      if (data && data.insert_events_m2m_goodies_one) {
        const { goodie_id: goodieId } = data.insert_events_m2m_goodies_one;
        cache.modify({
          id: cache.identify(event),
          fields: {
            goodies: existingRefs => {
              const newRef = cache.writeFragment({
                id: `events_m2m_goodies:${event.id}-${goodieId}`,
                fragment: EventGoodieFragmentDoc,
                fragmentName: 'EventGoodie',
                data: data.insert_events_m2m_goodies_one,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: () => {
      setSelectedGoodieId(undefined);
    },
    onError: onApolloError,
  });
  const [deleteEventGoodie] = useDeleteEventGoodieMutation({
    update: (cache, { data }) => {
      if (data && data.delete_events_m2m_goodies_by_pk) {
        const { goodie_id: goodieId } = data.delete_events_m2m_goodies_by_pk;
        cache.modify({
          id: cache.identify(event),
          fields: {
            goodies: (existingRefs, { readField }) =>
              existingRefs.filter(
                (ref: any) =>
                  readField('id', readField('goodie', ref)) !== goodieId,
              ),
          },
        });
      }
    },
    onError: onApolloError,
  });

  const onEventGoodieDelete = (goodie: EventGoodieFragment['goodie']) => {
    deleteEventGoodie({
      variables: { eventId: event.id, goodieId: goodie.id },
      optimisticResponse: {
        __typename: 'mutation_root',
        delete_events_m2m_goodies_by_pk: {
          __typename: 'events_m2m_goodies',
          event_id: event.id,
          goodie_id: goodie.id,
        },
      },
    });
  };

  const columns: ColumnsType<EventGoodieFragment['goodie']> = [
    {
      title: t('table.name'),
      dataIndex: ['name'],
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      render: date =>
        date
          ? formatDate(new Date(date), {
              lang: i18n.language,
              withTime: true,
            })
          : t('events.startOfEvent'),
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      render: date =>
        date
          ? formatDate(new Date(date), {
              lang: i18n.language,
              withTime: true,
            })
          : t('events.endOfEvent'),
    },
  ];

  if (!updateDisabled)
    columns.push({
      title: '',
      key: 'action',
      width: 50,
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: goodie => (
        <DeleteAction onDelete={() => onEventGoodieDelete(goodie)} />
      ),
    });

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <Select
            placeholder={t('events.addGoodie')}
            value={selectedGoodieId}
            onSelect={id => setSelectedGoodieId(id)}
            loading={goodiesLoading}
            style={{ flex: 1 }}
            allowClear
            onClear={() => setSelectedGoodieId(undefined)}
          >
            {goodies?.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <SubmitButton
            text={t('common.add')}
            icon={<PlusOutlined />}
            loading={insertLoading}
            onClick={() => {
              if (selectedGoodieId && !insertLoading) {
                insertEventGoodie({
                  variables: {
                    object: {
                      event_id: event.id,
                      goodie_id: selectedGoodieId,
                    },
                  },
                });
              }
            }}
            disabled={updateDisabled}
            disabledReason={updateDisabledReason}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={event.goodies.map(({ goodie }) => goodie)}
        rowKey="id"
        tableLayout="auto"
      />
    </>
  );
};
