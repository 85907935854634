import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { BrowserRouter as Router } from 'react-router-dom';

import './i18n/config';

import './App.less';
import { AppLayout } from 'components';
import { ConfigProvider } from 'components/providers';
import {
  authLink,
  getAuthorizationHeader,
  useAuth,
  LoggedClientContext,
  AuthLayout,
  AuthRoutes,
} from 'features/auth';
import { AppRoutes } from 'features/routing';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,

  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'same-origin',
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WSENDPOINT!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      headers: {
        authorization: getAuthorizationHeader(),
      },
    }),
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return !(
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  authLink.concat(httpLink),
  wsLink,
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export const App = () => {
  const credentials = useAuth(client);

  return (
    <ApolloProvider client={client}>
      <ConfigProvider>
        <Router>
          {credentials ? (
            <LoggedClientContext.Provider value={credentials.client}>
              <AppLayout>
                <AppRoutes />
              </AppLayout>
            </LoggedClientContext.Provider>
          ) : (
            <AuthLayout>
              <AuthRoutes />
            </AuthLayout>
          )}
        </Router>
      </ConfigProvider>
    </ApolloProvider>
  );
};
