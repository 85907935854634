import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useGetClientByIdQuery } from '@shared/api';

import { Loader } from 'components';
import { useLoggedClient } from 'features/auth';
import { ClientForm } from 'features/clients-managment/ClientForm';
import { AccountLayout } from './Layout';

export const Account = () => {
  const { t } = useTranslation();
  const { id } = useLoggedClient();
  const { data, loading } = useGetClientByIdQuery({ variables: { id } });

  return (
    <AccountLayout>
      <AccountTitle>{t('userAdmin.account')}</AccountTitle>
      {loading ? <Loader /> : null}
      {data?.clients_by_pk ? <ClientForm client={data.clients_by_pk} /> : null}
    </AccountLayout>
  );
};

const AccountTitle = styled.div`
  flex: 1;
  font-size: 1.4rem;
  font-weight: bold;
`;
