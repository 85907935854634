import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { AutoComplete, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';

import { Geography } from '@shared/api';

import { tzFromLatLng } from 'utils/tzUtils';

const { Option } = AutoComplete;

export type FormValues = {
  address?: string | null;
  city?: string | null;
  geography?: Geography;
  tz?: string;
};

interface Props {
  form: FormInstance<FormValues>;
  mapApiReady: boolean;
  handlePlace: (geography: Geography) => void;
}

export const AppFormAutocomplete = ({
  form,
  mapApiReady,
  handlePlace,
}: Props) => {
  const { t } = useTranslation();
  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
    initOnMount: false,
  });

  useEffect(() => {
    if (mapApiReady) init();
  }, [mapApiReady]);

  const handleSelect = (description: string) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(([result]) => {
        const geography: Geography = {
          type: 'Point',
          coordinates: [
            result.geometry.location.lng(),
            result.geometry.location.lat(),
          ],
        };
        const [lng, lat] = geography.coordinates;
        tzFromLatLng(lat, lng).then(tz =>
          form.setFieldsValue({
            tz: tz.timeZoneId,
          }),
        );
        form.setFieldsValue({
          geography,
        });
        // Extract City
        result.address_components.forEach(component => {
          if (component.types.indexOf('locality') > -1) {
            form.setFieldsValue({
              city: component.long_name,
            });
          }
        });
        handlePlace(geography);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Geocode error: ', error);
      });
  };

  return (
    <>
      <Form.Item
        label={t('address.address')}
        name="address"
        rules={[{ required: true }]}
      >
        <AutoComplete
          onSearch={inputValue => setValue(inputValue)}
          onSelect={handleSelect}
          placeholder={t('locations.searchAddress')}
        >
          {data.map(suggestion => {
            const {
              place_id: placeId,
              description,
              structured_formatting: {
                main_text: mainText,
                secondary_text: secondaryText,
              },
            } = suggestion;
            return (
              <Option key={placeId} value={description}>
                {mainText} {secondaryText}
              </Option>
            );
          })}
        </AutoComplete>
      </Form.Item>
      <Form.Item name="city" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="geography" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="tz" hidden>
        <Input />
      </Form.Item>
    </>
  );
};
