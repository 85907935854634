import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { RangeValue } from 'rc-picker/lib/interface';

import {
  EventFragment,
  EventCommunicationFragment,
  EventCommunicationFragmentDoc,
  CommunicationFragment,
  useDeleteEventCommunicationMutation,
  useGetCommunicationsQuery,
  useInsertEventCommunicationMutation,
} from '@shared/api';

import { DateRangePicker, DeleteAction, SubmitButton } from 'components';
import { formatDate } from 'utils/dateUtils';
import { onApolloError } from 'utils/errorUtils';
import { stringifyArrayForHasura } from 'utils/hasuraUtils';
import { LocationsExpansion } from './LocationsExpansion';

const { OptGroup, Option } = Select;

interface Props {
  event: EventFragment;
  updateDisabled: boolean;
  updateDisabledReason: string;
}

export const EventCommunications = ({
  event,
  updateDisabled,
  updateDisabledReason,
}: Props) => {
  const { i18n, t } = useTranslation();
  const [selectedCommunicationId, setSelectedCommunicationId] = useState<
    string | undefined
  >(undefined);
  const eventDates: [Date, Date] = [
    new Date(event.date_start),
    new Date(event.date_end),
  ];
  const [dateRange, setDateRange] = useState<RangeValue<Date>>(eventDates);
  const [locationsIds, setLocationsIds] = useState<string[]>([]);

  const {
    data: communicationsData,
    loading: communicationsLoading,
  } = useGetCommunicationsQuery({
    variables: {
      where: {
        _and: [{ is_archive: { _eq: false }, is_active: { _eq: true } }],
      },
    },
  });

  const [
    insertEventcommunication,
    { loading: insertLoading },
  ] = useInsertEventCommunicationMutation({
    update: (cache, { data }) => {
      if (data && data.insert_events_m2m_communications_one) {
        const {
          communication_id: communicationId,
        } = data.insert_events_m2m_communications_one;
        cache.modify({
          id: cache.identify(event),
          fields: {
            communications: existingRefs => {
              const newRef = cache.writeFragment({
                id: `events_m2m_communications:${event.id}-${communicationId}`,
                fragment: EventCommunicationFragmentDoc,
                fragmentName: 'EventCommunication',
                data: data.insert_events_m2m_communications_one,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: () => {
      setSelectedCommunicationId(undefined);
      setDateRange(eventDates);
      setLocationsIds([]);
    },
    onError: onApolloError,
  });
  const [deleteEventCommunication] = useDeleteEventCommunicationMutation({
    update: (cache, { data }) => {
      if (data && data.delete_events_m2m_communications_by_pk) {
        const {
          communication_id: communicationId,
        } = data.delete_events_m2m_communications_by_pk;
        cache.modify({
          id: cache.identify(event),
          fields: {
            communications: (existingRefs, { readField }) =>
              existingRefs.filter(
                (ref: any) =>
                  readField('id', readField('communication', ref)) !==
                  communicationId,
              ),
          },
        });
      }
    },
    onError: onApolloError,
  });

  const communicationsIds = event.communications.map(g => g.communication?.id);
  const communicationsGroupedByType = useMemo(
    () =>
      communicationsData?.communications.reduce((acc, cur) => {
        if (communicationsIds.includes(cur.id)) return acc;
        const key = cur.type.name;
        if (!acc[key]) acc[key] = [cur];
        else acc[key].push(cur);
        return acc;
      }, {} as { [typeName: string]: CommunicationFragment[] }),
    [communicationsData, event],
  );

  const onEventCommunicationDelete = (
    communication: NonNullable<EventCommunicationFragment['communication']>,
  ) => {
    deleteEventCommunication({
      variables: { eventId: event.id, communicationId: communication.id },
      optimisticResponse: {
        __typename: 'mutation_root',
        delete_events_m2m_communications_by_pk: {
          __typename: 'events_m2m_communications',
          event_id: event.id,
          communication_id: communication.id,
        },
      },
    });
  };

  const columns: ColumnsType<EventCommunicationFragment> = [
    {
      title: t('table.type'),
      dataIndex: ['communication', 'type', 'name'],
      render: typeName => t(`${typeName}.menuTitle` as any), // eslint-disable-line @typescript-eslint/no-explicit-any
    },
    {
      title: t('table.name'),
      dataIndex: ['communication', 'name'],
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      render: date =>
        date
          ? formatDate(new Date(date), {
              lang: i18n.language,
              withTime: true,
            })
          : t('events.startOfEvent'),
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      render: date =>
        date
          ? formatDate(new Date(date), {
              lang: i18n.language,
              withTime: true,
            })
          : t('events.endOfEvent'),
    },
  ];

  if (!updateDisabled)
    columns.push({
      title: '',
      key: 'action',
      width: 50,
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: eventcommunication => (
        <DeleteAction
          onDelete={() =>
            onEventCommunicationDelete(eventcommunication.communication)
          }
        />
      ),
    });

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <Select
            placeholder={t('events.addCommunication')}
            value={selectedCommunicationId}
            onSelect={id => setSelectedCommunicationId(id)}
            loading={communicationsLoading}
            style={{ flex: 1 }}
            allowClear
            onClear={() => setSelectedCommunicationId(undefined)}
          >
            {communicationsGroupedByType &&
              Object.keys(communicationsGroupedByType).map(typeName => (
                <OptGroup
                  key={typeName}
                  label={t(`${typeName}.menuTitle` as any)} // eslint-disable-line @typescript-eslint/no-explicit-any
                >
                  {communicationsGroupedByType[typeName].map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
          </Select>
        </Col>
        <Col span={4}>
          <SubmitButton
            text={t('common.add')}
            icon={<PlusOutlined />}
            loading={insertLoading}
            onClick={() => {
              if (selectedCommunicationId && !insertLoading) {
                const dateStart = dateRange && dateRange[0]?.toISOString();
                const dateEnd = dateRange && dateRange[1]?.toISOString();
                insertEventcommunication({
                  variables: {
                    object: {
                      event_id: event.id,
                      communication_id: selectedCommunicationId,
                      date_start:
                        dateStart !== eventDates[0].toISOString()
                          ? dateStart
                          : undefined,
                      date_end:
                        dateEnd !== eventDates[1].toISOString()
                          ? dateEnd
                          : undefined,
                      locations_ids:
                        locationsIds.length > 0
                          ? stringifyArrayForHasura(locationsIds)
                          : undefined,
                    },
                  },
                });
              }
            }}
            disabled={updateDisabled}
            disabledReason={updateDisabledReason}
          />
        </Col>
      </Row>
      {selectedCommunicationId && (
        <>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={20} style={{ display: 'flex' }}>
              <DateRangePicker
                showTime
                disabledBeforeDate={new Date(event.date_start)}
                disabledAfterDate={new Date(event.date_end)}
                allowEmpty={[true, true]}
                value={dateRange}
                onChange={values => setDateRange(values)}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={20} style={{ display: 'flex' }}>
              <Select
                mode="multiple"
                placeholder={t('common.location_plural')}
                value={locationsIds}
                onSelect={id => setLocationsIds([...locationsIds, id])}
                onDeselect={id =>
                  setLocationsIds(locationsIds.filter(i => i !== id))
                }
                style={{ flex: 1 }}
              >
                {event.locations.map(({ location: { id, name } }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      )}
      <Table
        columns={columns}
        dataSource={event.communications}
        rowKey={eventcommunication =>
          `${eventcommunication.event_id}-${eventcommunication.communication_id}`
        }
        rowClassName={eventCommunication =>
          eventCommunication.locations_ids ? 'cursor-pointer' : ''
        }
        expandable={{
          rowExpandable: eventcommunication =>
            !!eventcommunication.locations_ids,
          expandedRowRender: eventcommunication => (
            <LocationsExpansion
              locationsIds={eventcommunication.locations_ids || []}
              eventLocations={event.locations}
            />
          ),
          expandRowByClick: true,
        }}
        tableLayout="auto"
      />
    </>
  );
};
