import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useGetGainsByClientQuery } from '@shared/api';

import { AppForm } from 'components';
import { useLoggedClient } from 'features/auth';
import { magicApi } from 'api/magicApi';
import { GameData } from '../games/types';

const { Option } = Select;

type FormValues = {
  gainId: string;
};

interface GamesFormInfosProps {
  game: GameData;
}

export const FormRandomDrawingMagic = ({ game }: GamesFormInfosProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();

  const { id: clientId } = useLoggedClient();

  const { data } = useGetGainsByClientQuery({
    variables: { clientId },
  });

  const lauchMagicRandomDrawing = (values: FormValues) => {
    const { gainId } = values;
    magicApi.launchMagicRandom({
      room: 'MagicRandomRoom',
      gameId: game.id,
      gainId,
    });
  };

  return (
    <AppForm
      form={form}
      initialValues={{ gainId: null }}
      onFinish={values => lauchMagicRandomDrawing(values)}
    >
      <Form.Item
        label={t('randomDrawing.form.gains')}
        name="gainId"
        rules={[{ required: true }]}
      >
        <Select placeholder={t('gains.selectGain')} style={{ flex: 1 }}>
          {data &&
            data.gains.map(({ id, name, stock }) => (
              <Option
                key={id}
                value={id}
                disabled={
                  !!game?.games_gains.find(gameGain => gameGain.gain_id === id)
                }
              >
                <b>{name}</b> ({stock === -9999 ? '∞' : stock})
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
        {t('randomDrawing.magicLaunch')}
      </Button>
    </AppForm>
  );
};
