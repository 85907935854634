import React from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { PrivatesDecksFormSetup } from './Form/Setup';
import { PrivatesDecksFormEntities } from './Form/Entities';
import { PrivateDeckData } from './types';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  onClose: () => void;
  onCreate: (privateDeck: PrivateDeckData) => void;
  privateDeck?: PrivateDeckData;
};

const PrivateDeckDrawer = ({
  visible,
  onClose,
  onCreate,
  privateDeck,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={
        privateDeck
          ? t('privatesDecks.updatePrivateDeck', {
              name: privateDeck?.name,
            })
          : t('privatesDecks.createPrivateDeck')
      }
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('privatesDecks.setup')} key="1">
          <PrivatesDecksFormSetup
            privateDeck={privateDeck}
            onCreate={onCreate}
          />
        </TabPane>
        {privateDeck ? (
          <TabPane tab={t('privatesDecks.selection')} key="2">
            <PrivatesDecksFormEntities privateDeck={privateDeck} />
          </TabPane>
        ) : null}
      </Tabs>
    </Drawer>
  );
};

export { PrivateDeckDrawer };
