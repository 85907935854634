import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Row, Typography } from 'antd';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

import { AssetResourcesFragmentDoc } from '@shared/api';

import { Loader } from 'components/Loader';
import { deleteFile } from 'utils/fileUtils';

const { Text } = Typography;

interface Props {
  assetId?: string;
  fileName: string;
  isResource?: boolean;
  onDelete: () => void;
}
export const UploadListItem = ({
  assetId,
  fileName,
  isResource,
  onDelete,
}: Props) => {
  const { cache } = useApolloClient();
  const [loading, setLoading] = useState(false);
  return (
    <Row wrap={false} align="middle">
      <Text>
        <PaperClipOutlined style={{ marginRight: '0.25rem' }} />
      </Text>
      <Text ellipsis>{fileName}</Text>
      <Text>
        {loading ? (
          <Loader style={{ marginLeft: '0.25rem' }} />
        ) : (
          <DeleteOutlined
            style={{ marginLeft: '0.25rem' }}
            onClick={() => {
              setLoading(true);
              deleteFile(fileName, assetId, isResource)
                .then(response => {
                  if (typeof response !== 'string') {
                    cache.writeFragment({
                      id: cache.identify(response),
                      data: response,
                      fragment: AssetResourcesFragmentDoc,
                    });
                    onDelete();
                  }
                })
                .catch(() => setLoading(false));
            }}
          />
        )}
      </Text>
    </Row>
  );
};
