import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Row, Col, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Text } = Typography;

export const SurveyAnswer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* HEADER */}
      <StyledRow align="middle" gutter={16}>
        <Col span={22}>
          <Text>{t('survey.answer.answerTitle')}</Text>
        </Col>
        <Col span={2} />
      </StyledRow>
      {/* ANSWERS */}
      <Form.List
        name="surveys_questions_answers"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error(t('survey.form.moreAnswers')));
              }
              return null;
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(field => (
              <StyledRow key={field.key} align="middle" gutter={16}>
                <Col span={22}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'answer']}
                    fieldKey={[field.fieldKey, 'answer']}
                    rules={[
                      {
                        required: true,
                        message: t('survey.form.missingAnswer'),
                      },
                    ]}
                  >
                    <Input placeholder={t('survey.form.answerPlaceholder')} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    type="text"
                    onClick={() => remove(field.name)}
                    style={{ marginBottom: '10px' }}
                    icon={<MinusCircleOutlined />}
                  />
                </Col>
              </StyledRow>
            ))}
            {/* FOOTER */}
            <StyledRow gutter={16}>
              <Col span={22}>
                {fields.length < 4 && (
                  <Button
                    type="dashed"
                    onClick={() => add({ answer: '' })}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                  >
                    {t('survey.form.addAnswer')}
                  </Button>
                )}
                <Form.Item>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </Col>
              <Col span={2} />
            </StyledRow>
          </>
        )}
      </Form.List>
    </>
  );
};

const StyledRow = styled(Row)`
  margin: 12px 0;
`;
