import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

interface Props {
  title: string;
  value?: string | null;
  last?: boolean;
}
export const TitleValue = ({ title, value, last }: Props) => (
  <Container last={last}>
    <Text>
      {`${title}: `}
      <Text strong>{value}</Text>
    </Text>
  </Container>
);

const Container = styled.div<{ last?: boolean }>`
  ${props => !props.last && { marginBottom: '0.5rem' }}
`;
