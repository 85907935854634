/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import styled from 'styled-components';

import { translateError } from 'utils/errorUtils';

interface Props extends Partial<AlertProps> {
  error: any;
}

export const ErrorAlert = ({ error, ...props }: Props) => {
  const { t } = useTranslation();

  const errorContent = useMemo(() => {
    if (typeof error === 'string') return t(error as any);
    if (typeof error === 'object') {
      if (error && 'message' in error) return translateError(error.message, t);
      if ('errors' in error && Array.isArray(error.errors))
        return (
          <List>
            {error.errors.map((err: any) => (
              <li key={err}>{translateError(err, t)}</li>
            ))}
          </List>
        );
    }
    return t('common.errorMsg');
  }, [error, t]);

  return (
    <StyledAlert
      message={t('common.error')}
      description={errorContent}
      type="error"
      showIcon
      closable
      {...props}
    />
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

const List = styled.ul`
  margin-bottom: 0;
`;
