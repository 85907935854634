import {
  RandomDrawingArBody,
  RandomDrawingQuizzBody,
  RandomDrawingResponse,
  RandomDrawingVoteBody,
} from '@shared/api';

import { authService } from 'features/auth';

const GAMES_ENDPOINT = `${
  process.env.REACT_APP_AUTH_SERVER_ENDPOINT || 'http://localhost:3002'
}/games`;

export const gamesApi = {
  randomDrawing: (
    type: string,
    body: RandomDrawingArBody | RandomDrawingVoteBody | RandomDrawingQuizzBody,
  ) =>
    authService.fetchJson<RandomDrawingResponse>(
      `${GAMES_ENDPOINT}/${type}/random-drawing`,
      'POST',
      body,
    ),
};
