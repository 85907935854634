import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, Typography, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useUpdateGainMutation } from '@shared/api';

import { AppForm, Loader } from 'components';
import { GainData } from '../types';

const { Paragraph } = Typography;

type FormValues = {
  gtc: string;
  instructions: string;
};

interface GainsFormInstructionsProps {
  gain?: GainData;
}

const GainsFormInstructions = ({ gain }: GainsFormInstructionsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const [updateGainMutation] = useUpdateGainMutation();

  useEffect(() => {
    form.resetFields();
  }, [gain]);

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    if (gain) {
      await updateGainMutation({
        variables: {
          gainId: gain.id,
          gain: {
            ...values,
          },
        },
      });
    }
  };

  const initialValues = {
    gtc: gain?.gtc || '',
    instructions: gain?.instructions || '',
  };

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item label={t('gains.form.instructions')} name="instructions">
            <Input.TextArea rows={6} />
          </Form.Item>
          <Paragraph disabled>{t('gains.form.instructionsHelp')}</Paragraph>
          <Form.Item label={t('gains.form.gtc')} name="gtc">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Paragraph disabled>{t('gains.form.gtcHelp')}</Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};

export { GainsFormInstructions };
