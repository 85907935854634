import React, { useState } from 'react';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import {
  useGetEstimatesByClientQuery,
  useArchiveEstimateMutation,
  EstimateFragment,
  EstimatesStatusEnum,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { EstimateDrawer, Props as EstimateDrawerProps } from './EstimateDrawer';

import { EstimateData } from './types';

const Estimates = () => {
  const { id } = useLoggedClient();
  const { t } = useTranslation();
  const [drawerProps, setDrawerProps] = useState<
    Pick<EstimateDrawerProps, 'visible' | 'estimate'>
  >({
    visible: false,
  });
  const { data, loading } = useGetEstimatesByClientQuery({
    variables: { clientId: id },
  });
  const [archiveEstimateMutation] = useArchiveEstimateMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_estimates_by_pk) {
        cache.modify({
          fields: {
            estimates(existingEstimatesRefs, { readField }) {
              return existingEstimatesRefs.filter(
                (estimateRef: EstimateFragment) =>
                  archiveData.update_estimates_by_pk?.id !==
                  readField('id', estimateRef),
              );
            },
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (data && drawerProps) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        estimate: data.estimates.filter(
          (estimate: EstimateFragment) =>
            estimate.id === drawerProps?.estimate?.id,
        )[0],
      });
    }
  }, [data]);

  const columns: ColumnsType<EstimateData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: t('table.status'),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: status => {
        const color: { [x: string]: string } = {
          [EstimatesStatusEnum.InProgress]: 'blue',
          [EstimatesStatusEnum.PendingQuote]: 'orange',
          [EstimatesStatusEnum.CanceledQuote]: 'red',
          [EstimatesStatusEnum.Validated]: 'green',
        };
        const label: { [x: string]: string } = {
          [EstimatesStatusEnum.InProgress]: t('estimates.statusInProgress'),
          [EstimatesStatusEnum.PendingQuote]: t('estimates.statusPendingQuote'),
          [EstimatesStatusEnum.CanceledQuote]: t(
            'estimates.statusCanceledQuote',
          ),
          [EstimatesStatusEnum.Validated]: t('estimates.statusValidated'),
        };
        return (
          <Tag color={color[status]} key={status}>
            {label[status]}
          </Tag>
        );
      },
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      width: '15%',
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      width: '15%',
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: estimate => (
        <DeleteAction
          onDelete={() => {
            archiveEstimateMutation({
              variables: { estimateId: estimate.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_estimates_by_pk: {
                  ...estimate,
                  is_archive: true,
                  __typename: 'estimates',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<EstimateData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t('estimates.menuTitle')}
        onAdd={() => setDrawerProps({ visible: true })}
        actions={actions}
        columns={columns}
        dataSource={data?.estimates}
        loading={loading}
        onRow={estimate => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              estimate,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      <EstimateDrawer
        visible={drawerProps.visible}
        estimate={drawerProps.estimate}
        onCreate={estimate => setDrawerProps({ visible: true, estimate })}
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};

export { Estimates };
