import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { ArTrackers, AssetResourcesFragmentDoc, Assets } from '@shared/api';

import { Loader } from 'components/Loader';
import { displayAssetType } from 'utils/assetUtils';
import { stringToNumber } from 'utils/mathUtils';
import { Asset } from './Asset';
import { Upload, UploadList } from '../upload';

const { Text } = Typography;

interface Props {
  loading?: boolean;
  fileUrl?: string;
  file: Assets | ArTrackers;
  onChange?: (file: Assets | ArTrackers) => void;
  onUpdateClick: () => void;
}

export const AssetDisplay = ({
  loading = false,
  fileUrl,
  file,
  onChange,
  onUpdateClick,
}: Props) => {
  const { t } = useTranslation();
  const { cache } = useApolloClient();
  const asset = 'image' in file ? file.image : file;

  return (
    <>
      <Row gutter={16} wrap={false}>
        <ImageCol>
          <Asset
            asset={asset}
            fileUrl={fileUrl}
            qrcodeInside={
              !loading && 'qrcode_inside' in file && file.qrcode_inside
            }
            maxHeight="25vh"
            maxWidth="100%"
          />
          {loading && (
            <Overlay>
              <Loader fontSize={64} />
            </Overlay>
          )}
        </ImageCol>
        <DescriptionCol flex="1">
          <FileName
            strong
            ellipsis
            editable={
              onChange && { onChange: name => onChange({ ...file, name }) }
            }
          >
            {file.name || ('image' in file ? file.image.name : undefined)}
          </FileName>
          {'image' in file ? (
            <>
              <Text ellipsis>
                {`${t('ar.form.realWidth')}: `}
                <Text
                  strong
                  editable={
                    onChange && {
                      onChange: widthString => {
                        const width = stringToNumber(widthString, t);
                        if (width) onChange({ ...file, width });
                      },
                    }
                  }
                >
                  {file.width?.toString()}
                </Text>
              </Text>
              <FileType ellipsis>
                {`${t('ar.form.realHeight')}: `}
                <Text
                  strong
                  editable={
                    onChange && {
                      onChange: heightString => {
                        const height = stringToNumber(heightString, t);
                        if (height) onChange({ ...file, height });
                      },
                    }
                  }
                >
                  {file.height?.toString()}
                </Text>
              </FileType>
            </>
          ) : (
            <>
              <FileType ellipsis>
                {`${t('ar.type')}: ${displayAssetType(asset, t)}`}
              </FileType>
              {(asset.type === 'portal' || asset.type === 'object3D') && (
                <>
                  <Text>
                    {t('ar.form.scales')}{' '}
                    <Tooltip title={t('ar.form.scalesHelp')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                    {' :'}
                  </Text>
                  <ul>
                    <li>
                      <Text>
                        {`${t('ar.form.scaleX')}: `}
                        <Text
                          strong
                          editable={
                            onChange && {
                              onChange: scaleXString => {
                                const scaleX = stringToNumber(scaleXString, t);
                                if (scaleX) onChange({ ...file, scaleX });
                              },
                            }
                          }
                        >
                          {asset.scaleX?.toString() || '1'}
                        </Text>
                      </Text>
                    </li>
                    <li>
                      <Text>
                        {`${t('ar.form.scaleY')}: `}
                        <Text
                          strong
                          editable={
                            onChange && {
                              onChange: scaleYString => {
                                const scaleY = stringToNumber(scaleYString, t);
                                if (scaleY) onChange({ ...file, scaleY });
                              },
                            }
                          }
                        >
                          {asset.scaleY?.toString() || '1'}
                        </Text>
                      </Text>
                    </li>
                    <li>
                      <Text>
                        {`${t('ar.form.scaleZ')}: `}
                        <Text
                          strong
                          editable={
                            onChange && {
                              onChange: scaleZString => {
                                const scaleZ = stringToNumber(scaleZString, t);
                                if (scaleZ) onChange({ ...file, scaleZ });
                              },
                            }
                          }
                        >
                          {asset.scaleZ?.toString() || '1'}
                        </Text>
                      </Text>
                    </li>
                  </ul>
                </>
              )}
            </>
          )}
          <UpdateFileButton type="link" onClick={onUpdateClick}>
            {t('common.update')}
          </UpdateFileButton>
        </DescriptionCol>
      </Row>
      {'path' in file && ['object3D', 'portal'].includes(file.type) && (
        <>
          <Row gutter={16}>
            <Col>
              <Text>{t('ar.resources')}</Text>
            </Col>
            <Col flex={1}>
              <Upload
                alone
                type="resource"
                assetId={file.id}
                onChange={a => {
                  cache.writeFragment({
                    id: cache.identify(a),
                    data: a,
                    fragment: AssetResourcesFragmentDoc,
                  });
                  if (onChange) onChange({ ...file, resources: a.resources });
                }}
              />
            </Col>
          </Row>
          <UploadList
            fileNames={asset.resources}
            assetId={file.id}
            isResource
            onDelete={fileName => {
              if (onChange)
                onChange({
                  ...file,
                  resources: file.resources.filter(
                    (r: string) => r !== fileName,
                  ),
                });
            }}
          />
        </>
      )}
    </>
  );
};

const ImageCol = styled(Col)`
  text-align: center;
  align-self: center;
`;
const DescriptionCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;
const FileName = styled(Text)`
  margin-bottom: 0.5rem;
`;
const FileType = styled(Text)`
  flex: 1;
`;
const UpdateFileButton = styled(Button)`
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
