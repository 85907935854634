import React from 'react';
import { List } from 'antd';

import { LibraryContent } from 'utils/assetUtils';
import { AssetCard } from './AssetCard';

interface Props {
  loading: boolean;
  contents: LibraryContent[] | undefined;
  selectedId: string | undefined;
  onSelect: (content: LibraryContent) => void;
}

export const AssetsList = ({
  contents,
  loading,
  selectedId,
  onSelect,
}: Props) => (
  <List<LibraryContent>
    loading={loading}
    grid={{
      gutter: 32,
      xs: 1,
      md: 2,
      lg: 3,
      xl: 3,
      xxl: 4,
    }}
    dataSource={contents}
    renderItem={content => (
      <List.Item onClick={() => onSelect(content)}>
        <AssetCard content={content} selected={selectedId === content.id} />
      </List.Item>
    )}
  />
);
