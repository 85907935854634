import React from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { NavLink } from 'components/routing';

export const AccountMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const selectedMenuKey = pathname.split('/')[3] || 'account';

  return (
    <Menu style={{ width: 256 }} selectedKeys={[selectedMenuKey]} mode="inline">
      <Menu.Item key="account">
        <NavLink to="/account">{t('userAdmin.account')}</NavLink>
      </Menu.Item>
      <Menu.Item key="payment">
        <NavLink to="/account/payment">{t('userAdmin.payment')}</NavLink>
      </Menu.Item>
      <Menu.Item key="invoices">
        <NavLink to="/account/invoices">{t('userAdmin.invoices')}</NavLink>
      </Menu.Item>
      <Menu.Item key="contacts">
        <NavLink to="/account/contacts">{t('userAdmin.contact')}</NavLink>
      </Menu.Item>
    </Menu>
  );
};
