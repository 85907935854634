import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AccountLayout } from './Layout';

export const AccountInvoices = () => {
  const { t } = useTranslation();

  return (
    <AccountLayout>
      <AccountTitle>{t('userAdmin.invoices')}</AccountTitle>
    </AccountLayout>
  );
};

const AccountTitle = styled.div`
  flex: 1;
  font-size: 1.4rem;
  font-weight: bold;
`;
