import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  RandomDrawingFragment,
  useGetGameRandomDrawingsQuery,
} from '@shared/api';

import { displayFullNameOrId } from 'utils/userUtils';
import { GameData } from '../games/types';
import { NewRandomDrawingDrawer } from './NewRandomDrawingDrawer';
import { FormRandomDrawingMagic } from './FormRandomDrawingMagic';

interface Props {
  game: GameData;
}

export const RandomDrawing = ({ game }: Props) => {
  const { i18n, t } = useTranslation();
  const { data, error, loading } = useGetGameRandomDrawingsQuery({
    variables: { gameId: game.id },
  });

  const [drawerVisible, setDrawerVisible] = useState(false);

  if (error) return <div>{error.message}</div>;

  const columns: ColumnsType<RandomDrawingFragment> = [
    {
      title: t('common.winner'),
      dataIndex: 'winner',
      key: 'winner',
      render: winner => displayFullNameOrId(winner),
    },
    {
      title: t('games.gain'),
      dataIndex: ['gain', 'name'],
      key: 'gain',
    },
    {
      title: t('table.date'),
      dataIndex: 'created_at',
      key: 'date',
      render: createdAt => {
        const date = new Date(createdAt);
        return date.toLocaleDateString(i18n.language);
      },
    },
  ];

  return (
    <>
      <NewRandomDrawingDrawer
        visible={drawerVisible}
        gameId={game.id}
        gameType={game.type.name}
        onClose={() => setDrawerVisible(false)}
      />
      <AddButtonContainer>
        {game.with_magic_random_drawings ? (
          <FormRandomDrawingMagic game={game} />
        ) : (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setDrawerVisible(true)}
          >
            {t('common.add')}
          </Button>
        )}
      </AddButtonContainer>
      <Table
        columns={columns}
        dataSource={data?.random_drawings}
        loading={loading}
        rowKey="id"
      />
    </>
  );
};

const AddButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;
