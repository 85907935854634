import React from 'react';
import {
  Redirect,
  RedirectProps,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const IncludedLangSwitch = ({ children }: ChildrenProps) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const lng = pathname.split('/')[1];

  React.useEffect(() => {
    if (lng !== i18n.language) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <Switch>
      {React.Children.map(children, child =>
        React.isValidElement<RouteProps | RedirectProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: child.props.path ? `/:lang${child.props.path}` : undefined,
              to:
                'to' in child.props
                  ? `/${i18n.language}${child.props.to}`
                  : undefined,
            })
          : child,
      )}
      <Redirect to={`/${i18n.language}`} />
    </Switch>
  );
};
