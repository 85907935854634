import { TFunction } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { message } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const translateError = (error: any, t: TFunction<'translation'>) => {
  if (!error) return undefined;
  if (typeof error === 'object' && 'key' in error && 'options' in error)
    return t(error.key, error.options);
  return t(error);
};

export const onApolloError = (error: ApolloError) => {
  message.error(error.message);
};
