import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Form, Input, Row } from 'antd';

import {
  Assets,
  EventFragment,
  EventFragmentDoc,
  useInsertEventMutation,
  useUpdateEventMutation,
} from '@shared/api';

import {
  AppForm,
  AssetInput,
  DateRangePicker,
  MultipleLngItem,
  SubmitButton,
} from 'components';
import { useLoggedClient } from 'features/auth';
import { onApolloError } from 'utils/errorUtils';

type FormValues = {
  name: string;
  rangeDate: string[];
  image?: Assets;
  description?: { [lng: string]: string };
};

interface Props {
  event?: EventFragment;
  updateDisabled: boolean;
  updateDisabledReason: string;
  onAdd: (event: EventFragment) => void;
}

export const EventSetup = ({
  event,
  updateDisabled,
  updateDisabledReason,
  onAdd,
}: Props) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const [insertEvent] = useInsertEventMutation({
    update: (cache, { data }) => {
      if (data && data.insert_events_one) {
        const { insert_events_one: newEvent } = data;
        cache.modify({
          fields: {
            events: existingRefs => {
              const newRef = cache.writeFragment({
                id: `events:${newEvent.id}`,
                fragment: EventFragmentDoc,
                fragmentName: 'Event',
                data: newEvent,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: data => {
      if (data.insert_events_one) {
        onAdd(data.insert_events_one);
      }
    },
    onError: onApolloError,
  });
  const [updateEvent] = useUpdateEventMutation({ onError: onApolloError });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const { rangeDate, image, ...formatedValues } = values;

    const body = {
      ...formatedValues,
      date_start: rangeDate[0],
      date_end: rangeDate[1],
      image_id: image ? image.id : null,
    };

    if (event) {
      await updateEvent({
        variables: {
          eventId: event.id,
          event: body,
        },
      });
    } else {
      await insertEvent({
        variables: {
          event: {
            ...body,
            client_id: role === 'admin' ? clientId : undefined,
          },
        },
      });
    }
  };

  const initialValues = {
    name: event?.name,
    rangeDate: event
      ? [new Date(event.date_start), new Date(event.date_end)]
      : undefined,
    image: event?.image,
    description: event?.description,
  };

  useEffect(() => {
    form.resetFields();
  }, [event]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('common.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('common.name')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('common.date_plural')}
            name="rangeDate"
            rules={[{ required: true }]}
          >
            <DateRangePicker showTime disabledBeforeDate={new Date()} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="image" label={t('common.coverImage')}>
        <AssetInput withCrop cropAspect={3 / 4} />
      </Form.Item>

      <MultipleLngItem
        name="description"
        label={t('games.form.description')}
        alreadyUsedLngs={
          event?.description ? Object.keys(event.description) : []
        }
      />

      <Divider />
      <Row justify="end">
        <SubmitButton
          loading={loading}
          disabled={updateDisabled}
          disabledReason={updateDisabledReason}
        />
      </Row>
    </AppForm>
  );
};
