import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Layout, Dropdown, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, SwapOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getAssetUrl } from 'utils/assetUtils';
import { authService, useLoggedClient } from 'features/auth';
import { NavLink } from '../routing';

const { Header } = Layout;

export const HeaderLayout = (): JSX.Element => {
  const { t } = useTranslation();
  const { name, first_name: firstName, picture } = useLoggedClient();

  const overlay = (
    <Menu>
      <Menu.Item onClick={() => ({})} key="account">
        <NavLink to="/account">
          <UserOutlined /> {t('userAdmin.account')}
        </NavLink>
      </Menu.Item>
      {authService.oldCrendentials && (
        <Menu.Item
          onClick={() => authService.logoutFromClient()}
          key="logoutFromClient"
        >
          <SwapOutlined /> {t('auth.logoutFromClient')}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => authService.logout()} key="logout">
        <LogoutOutlined /> {t('auth.logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <FlexHeader className="site-layout-background">
      <Dropdown trigger={['hover']} overlay={overlay}>
        <UserLinks>
          {name && firstName ? `${name} ${firstName}` : null}
          {picture ? (
            <UserAvatar src={getAssetUrl(picture)} />
          ) : (
            <UserAvatar icon={<UserOutlined />} />
          )}
        </UserLinks>
      </Dropdown>
    </FlexHeader>
  );
};

const FlexHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`;

const UserAvatar = styled(Avatar)`
  background-color: #d73716;
  margin: 0 5px;
`;

const UserLinks = styled('div')`
  cursor: pointer;
`;
