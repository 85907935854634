import React from 'react';
import { Card, CardProps } from 'antd';
import styled from 'styled-components';

interface ChartCardsProps extends CardProps {
  loading: boolean;
  title: React.ReactNode;
  action?: React.ReactNode;
  total?: React.ReactNode | number | (() => React.ReactNode | number);
  footer?: React.ReactNode;
  contentHeight?: number;
  avatar?: React.ReactNode;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const ChartCard = (props: ChartCardsProps) => {
  const {
    loading = false,
    title,
    avatar,
    action,
    total,
    footer,
    children,
    ...rest
  } = props;

  return (
    <Card
      loading={loading}
      bodyStyle={{ padding: '8px 24px 8px 24px' }}
      {...rest}
    >
      <ChartContainer>
        <ChartTop>
          <Avatar>{avatar}</Avatar>
          <MetaWrap>
            <Meta>
              <Title>{title}</Title>
              <Action>{action}</Action>
            </Meta>
            {total}
          </MetaWrap>
        </ChartTop>
        {children && (
          <Content>
            <ContentFixed>{children}</ContentFixed>
          </Content>
        )}
        {footer && <Footer>{footer}</Footer>}
      </ChartContainer>
    </Card>
  );
};

const ChartContainer = styled.div`
  position: relative;
`;

const ChartTop = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
`;

const Avatar = styled.div`
  position: relative;
  top: 4px;
  float: left;
  margin-right: 20px;
  & > img {
    border-radius: 100%;
  }
`;

const MetaWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const Meta = styled.div`
  height: 22px;
  color: black;
  font-size: 24px;
  line-height: 22px;
`;

const Title = styled.span`
  height: 22px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
`;

const Action = styled.span`
  position: absolute;
  top: 4px;
  right: 0;
  line-height: 1;
  cursor: pointer;
  height: 22px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  height: 50px;
`;

const ContentFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Footer = styled.div`
  margin-top: 8px;
  padding-top: 9px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  & > * {
    position: relative;
  }
`;
